<template>

    <figure class="px-10 pt-10 pb-10">
        <h1 class="text-left">Home</h1>
    </figure>
    <el-card class="box-card w-80">
        <figure class="pt-2">
            <h3 class="text-left">lets get started</h3>
        </figure>
        <div class="card-actions justify-end pt-4">
            <button @click="dialogFormVisible = true" class="btn btn-primary">Start</button>
        </div>
    </el-card>

    <el-dialog v-model="dialogFormVisible" title="Add User">
        <el-steps :active="active" finish-status="success">
            <el-step title="Step 1" />
            <el-step title="Step 2" />
            <el-step title="Step 3" />
        </el-steps>
        <intro-users-vue @users_generated="grabUsers" v-if="active == 0" />
        <intro-problem-vue :users="users" v-if="active == 1 && users != null" />
        <intro-objects-vue :users="users" v-if="active == 2 && users != null" />
                   
        <template #footer>
            <span class="dialog-footer">
                 <el-button style="margin-top: 12px" @click="next">Next step</el-button>
                <el-button type="primary">Save</el-button>
            </span>
        </template>
    </el-dialog>




</template>

<script>
import IntroUsersVue from '@/components/IntroUsers.vue'
import IntroProblemVue from '@/components/IntroProblem.vue'
import IntroObjectsVue from '@/components/IntroObjects.vue'
export default {
    components: {
        IntroUsersVue,
        IntroProblemVue,
        IntroObjectsVue
    },
    async mounted() {
        
    },
    data: () => ({
        form: {},
        dialogFormVisible: false,
        active: 0,
        users: null
    }),
    methods: {
        next() {
            if (this.active++ > 2) this.active = 0
        },
        grabUsers(val) {
        
        this.users = val
        
      }
    }
}
</script>