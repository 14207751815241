<template>
  <el-row justify="center" style="height: 100vh" class="home">
    <el-col class="grid h-screen place-items-center" justify="center" :span="12">
    <login />
    </el-col>
    <el-col :span="12">
    <div style="height: 100vh;background-repeat: no-repeat; background-size: cover; background-image: url('https://cdn.glitch.global/40d5f2e1-d6f1-4b4e-b603-44a0049b2e40/DALL%C2%B7E%202023-01-15%2000.21.49.png?v=1673763772494');">
    </div>
    </el-col>
  </el-row>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Login from '@/components/Login.vue'

export default {
  name: 'HomeView',
  components: {
    Login
  }
}
</script>

<style>
.home {
  background: #121220;
}
</style>

