<template>
    <div>
        <el-row style="margin-top: 1em; width: 100vh;">
            <h2 class="content-header">
                General Context of the codebase as whole:
            </h2>
        </el-row>
        <el-row style="margin-top: 1em; width: 100vh;">
            <h4 class="content-header">
                This codebase is dedicated to fetching all of models from Mongodb. This service is usally consumed by
                the
                react
                frontend application.
            </h4>
        </el-row>
    </div>
    <div v-if="contextBlocks.length > 0">
        <context-block-vue class="elevated-content" style="margin-top: 10em;" v-for="(block, index) of contextBlocks" v-bind:key="index" :file="block.file" :tags="block.tags" :id="block.file" :content="block.content" />
    </div>
</template>

<script>
import ContextBlockVue from './ContextBlock.vue'
export default {
    props: {
        repo: String
    },
    components: {
        ContextBlockVue
    },
    data: () => ({
        contextBlocks: []
    }),
    async mounted() {
        
        var myHeaders = new Headers();
        var token = await this.$authInstance.getToken()
        
        myHeaders.append("Content-Type", "application/json");
        if(token) {
            myHeaders.append("Authorization", `Bearer ${token}`)
        }
        var url = await this.$authInstance.getBaseUrl()
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        var responseData = await fetch(`${url}/docs?searchKey=filter&searchValue=${this.repo}-context`, requestOptions)
        
        var resJson = await responseData.json()
        
        this.contextBlocks = resJson.data
        // this.$subMenu.contexts = resJson.data.map(function(item) { return item.file })
        var contextBlocks = await this.$subMenu.sortContext(this.contextBlocks)
        
        const event = new Event('build');
        
        window.dispatchEvent(event);
        
    }
}

</script>

<style>

</style>