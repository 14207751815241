<template>
    <figure class="px-10 pt-10 pb-10">
        <h1 class="text-left">Your Codebases</h1>
    </figure>

    <div class="docs-holder" v-if="codebases">
        <el-row v-for="(array, index) of codebases" :gutter="20" v-bind:key="index">
            <el-col style="margin-top: 2em;" v-for="(codebase, index) of array" :span="6" v-bind:key="index">
                <el-card class="box-card">
                    <figure class="pt-2">
                        <h2 class="text-left">{{codebase.friendly_name}}</h2>
                    </figure>
                    <figure>
                        <h3 class="text-left">Language: {{codebase.language}}</h3>
                    </figure>
                    <div class="pt-10">
                        <figure>
                            <p class="text-left">Description: Blah blah</p>
                        </figure>
                        <figure>
                            <p class="text-left">Owner: team X</p>
                        </figure>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>

// for every foruth element

var array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

var cutItemsBy4 = function (bigarray) {
    var size = 3; var arrayOfArrays = [];
    for (var i = 0; i < bigarray.length; i += size) {
        arrayOfArrays.push(bigarray.slice(i, i + size));
    }
    
    return arrayOfArrays
}


export default {
    data: () => ({
        codebases: null,
    }),
    async mounted() {
        var idtoken = await this.$authInstance.getToken()
        
        await this.getCodebases()
    },
    methods: {
        async getCodebases() {
            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var baseUrl = await this.$authInstance.getBaseUrl()
            myHeaders.append("Authorization", `Bearer ${idtoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            var codebaseRes = await fetch(`${baseUrl}/codebases`, requestOptions)
            var codebasesJson = await codebaseRes.json()
            
            this.codebases = cutItemsBy4(codebasesJson.data)
        }
    }
}
</script>