<template>
    <div>
        <div v-if="auditInfo">
            <ScrollPanel style="width: 100%; height: 100vh">
                <div>
                    <SelectButton v-model="value" :options="options" aria-labelledby="basic" />
                    <DataTable :value="items" v-model:selection="selectedItems" :paginator="true" :rows="10"
                        currentPageReportTemplate="Displaying {first} to {last} of {totalRecords}">
                        <template #paginatorstart>
                            <div style="display: flex;align-items: center;flex-direction: row; min-width: 500px">
                                <span style="max-width: 350px">Select page(s) to modify. These pages will be copied with
                                    Dev-Docs’ suggested
                                    changes and accessible in Drafts.</span><Button @click="handleGeneration"
                                    style="width: 80%;">Copy Pages to [Drafts]</Button>
                            </div>

                        </template>
                        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                        <Column field="path" header="Path"></Column>
                        <Column field="reason" header="Reason"></Column>
                    </DataTable>

                    <Button v-if="value == 'Generate Issues'" label="Generate Issues" @click="handleGeneration"
                        :disabled="!selectedItems.length" class="mt-3" />
                    <Button v-if="value == 'Fix and Modify'" label="Fix and Modify" @click="handleGeneration"
                        :disabled="!selectedItems.length" class="mt-3" />
                </div>
            </ScrollPanel>
        </div>
        <div v-else class="flex flex-col p-fluid gap-[1.25rem] mt-[1.25rem]">
            <div class="">
                <label for="folders">Existing Docs to audit</label>
                <ContextSelectTree v-if="folderOptions?.length > 0 && updatedContextSource" :data="folderObject"
                    :additional-items="repoContextItems" v-model="selectedFolders"
                    @selection-change="handleSelectionItemChange" class="mt-[0.62rem] !bg-[#1C1C1C]" />
            </div>

            <div class="">
                <div class="flex items-center p-[0.62rem] gap-[0.62rem]">
                    <Checkbox v-model="checkCode" :binary="true" inputId="binary"
                        label="Verify documentation matches current code" />
                    <label for="size_normal">Verify documentation matches current code</label>
                </div>
            </div>

            <div class="flex flex-col gap-[0.62rem]">
                <div>
                    Describe what you want to audit
                </div>
                <div style="display: flex; position: relative;">
                    <Textarea
                        placeholder="Your audit prompt. Add multiple prompts delineated with a comma or period. For example, “Check for spelling/grammatical errors, ensure a professional tone throughout these docs. Check that these docs"
                        class="w-full px-[0.63rem] py-[0.44rem] rounded-[0.5rem] bg-[#1C1C1C] min-h-[9.375rem] !border-none"
                        autoResize v-model="newAuditValue" variant="filled" autofocus fluid rows="2" />
                </div>
            </div>

            <div class="flex flex-col gap-[0.62rem]">
                <div class="">
                    <PopularPrompts
                        :items="['Find and fix spelling and grammatical errors', 'Find all headings with Title case', 'Identify any inconsistent content', 'Ensure that the tone is professional throughout these docs']"
                        v-model="newAuditValue" :appendMode="true" />
                </div>
            </div>

            <div class="flex self-end justify-end w-fit">
                <Button label="Submit" @click="auditYourDocs" class="w-fit" />
            </div>
        </div>

        <Skeleton height="10rem" v-if="loading" fluid />
    </div>
</template>

<script>

import ContextSelectTree from './ContextSelectTree.vue';
import PopularPrompts from './PopularPrompts.vue';

function findParentPaths(items) {
    const paths = Array.isArray(items) ? items : [];
    return paths.filter((path, index) => {
        return !paths.some((otherPath, otherIndex) => {
            if (index === otherIndex) return false;
            return path.startsWith(otherPath + '/');
        });
    });
}



import { getFileContent, getCustomFiles, getOwnerAndRepo } from '../plugins/devdocsBackendService.js';

export default {
    props: {
        branch: {
            type: String,
        }
    },
    data() {
        return {
            audits: [],
            newAuditValue: '',
            selectedFolders: [],
            checkCode: false,
            folderOptions: [],
            folderObject: {},
            loading: false,
            auditInfo: null,
            selectedItems: [],
            documentationRepo: null,
            repos: [],
            codeFiles: [],
            updatedContextSource: true,
            repoContextItems: [],
            value: 'Generate Issues',
            options: ['Generate Issues', 'Fix and Modify'],
        };
    },
    components: {
        ContextSelectTree, PopularPrompts
    },
    async mounted() {
        //await this.getRepos()
        this.documentationRepo = await getOwnerAndRepo()
        await this.getFolders();
    },
    watch: {
        checkCode: {
            async handler(newData) {
                try {
                    if (newData) {
                        this.updatedContextSource = false
                        let repos = await this.getRepos()
                        let newRepoContextItems = []
                        console.log("what is repos", repos)
                        for (let repo of repos) {
                            console.log("what is repo here", repo)
                            console.log("what is this documentation repo", this.documentationRepo)
                            if(this.documentationRepo.repo == repo.name && this.documentationRepo.owner == repo.owner){
                                continue
                            }
                            let files = await getCustomFiles({ branch: repo.default_branch, owner: repo.owner, repo: repo.name })
                            let repoContextItem = { label: repo.full_name, files: files?.files || [], category: repo.full_name }
                            newRepoContextItems.push(repoContextItem)
                        }
                        this.repoContextItems = newRepoContextItems
                        this.updatedContextSource = true
                    }

                } catch (e) {
                    this.updatedContextSource = true
                }

            }
        }
    },
    methods: {
        addAudit() {
            let newAuditValue = this.newAuditValue.trim();
            this.audits.push(newAuditValue);
            this.newAuditValue = '';
        },
        async getFolders() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var response = await fetch(`${url}/folders`, requestOptions)
                var jsonResponse = await response.json()
                this.folderOptions = jsonResponse
                this.folderObject = { category: "Documentation", files: jsonResponse }
            } catch (e) {
                this.folderOptions = []
            }
        }, handleSelectionItemChange(value) {
            // Handle the selections here
            console.log('Selected folders:', value);
            this.selectedFolders = value.selections;
            let byCategory = value.byCategory
            this.codeFiles = byCategory
            // You can store the selections in your component's data
            // or emit them to a parent component if needed

        },
        async getRepos() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                let url = await this.$authInstance.getBaseUrl()
                let response = await fetch(`${url}/github_app_repos`, requestOptions)
                let jsonResponse = await response.json()
                //this.repos = jsonResponse?.repos
                return jsonResponse?.repos
            } catch (e) {
                //this.folderOptions = []
            }
        },
        removeAudit(index) {
            console.log(index);
            this.audits.splice(index, 1);
        },
        async handleGeneration() {
            if (this.value === 'Generate Issues') {
                for (let selected of this.selectedItems) {
                    let content = `Need to Modify ${selected.path} because of ${selected.reason}`
                    let title = `Docs Audit: ${selected.reason}`
                    await this.generateIssues({ content, title })
                }
            } else {
                for (let selected of this.selectedItems) {
                    let data = { filePath: selected.path, reason: selected.reason }
                    let web = {fileName: selected.path, reason: selected.reason, fileName: selected.path, ownerName: this.documentationRepo.owner, repoName: this.documentationRepo.repo, branchName: this.branch }
                    data.web = web
                    await this.updateYourDoc(data)
                }
            }
            this.$emit('draft')
        },
        async generateIssues(data) {
            this.loading = true
            var myHeaders = new Headers();
            try {
                let { content, title } = data
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }

                const raw = JSON.stringify({ content, title });
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()

                const saveResponseTwo = await fetch(`${url}/issues`, requestOptions);
                this.loading = false
                let result = await saveResponseTwo.json();
                console.log(result)
                let tableInfo = JSON.parse(result.content)
                console.log(tableInfo)
                this.auditInfo = tableInfo
                this.items = tableInfo.files
            } catch (e) {
                console.log(e)
                return {}
            }

        },
        async updateYourDoc(data) {
            this.loading = true
            var myHeaders = new Headers();
            try {
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }
                const raw = JSON.stringify(data);
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()

                const saveResponseTwo = await fetch(`${url}/update`, requestOptions);
                this.loading = false
                let result = await saveResponseTwo.json();
                console.log(result)
                let tableInfo = JSON.parse(result.content)
                console.log(tableInfo)
                this.auditInfo = tableInfo
                this.items = tableInfo.files
            } catch (e) {
                console.log(e)
                return {}
            }

        },
        processSelectedFolders(folderObj) {
            try {
                let checkedFolders = Object.entries(folderObj)
                    .filter(([key, value]) => value.checked)
                    .map(([key]) => key);

                checkedFolders = checkedFolders.filter(folder => folder !== "0");
                checkedFolders.sort((a, b) => a.split('/').length - b.split('/').length);

                const result = [];

                for (let folder of checkedFolders) {
                    const isParent = result.some(existingFolder =>
                        existingFolder.startsWith(folder + '/'));
                    const isChild = result.some(existingFolder =>
                        folder.startsWith(existingFolder + '/'));

                    if (!isParent && !isChild) {
                        let parsedPath = folder.split('::parent::')[1]
                        result.push(parsedPath);
                    }
                }
                
                return result;
            } catch (e) {
                console.log(e)
                return [];
            }
        },
        async auditYourDocs() {
            this.loading = true
            var myHeaders = new Headers();
            try {
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }
                this.audits = this.newAuditValue.split(',')
                console.log("this is the selected folders", this.selectedFolders)
                //let folders = this.processSelectedFolders(this.selectedFolders)
                let folders = this.codeFiles["Documentation"]
                folders = findParentPaths(folders)
                let codeObjects = this.codeFiles
                delete codeObjects["Documentation"]
                const raw = JSON.stringify({ folders, codeObjects, audits: this.audits });
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()

                const saveResponseTwo = await fetch(`${url}/audit`, requestOptions);
                this.loading = false
                let result = await saveResponseTwo.json();
                console.log(result)
                this.auditInfo = result
                this.items = result.files
                if(result.codeFilesDocs)  {
                    this.items = this.items.concat(result.codeFilesDocs.files)
                }
            } catch (e) {
                console.log(e)
                return {}
            }
        }
    }
};
</script>
<style>
.p-paginator {
    flex-direction: row;
}



.p-paginator-content-start {
    max-width: 350px;
}
</style>