<template>

    <editor-content autocorrect="off" autocapitalize="off" spellcheck="false" @drop="dropHandler"
        @dragstart="dragOverHandler" class="style-editor" :editor="editor" />

</template>

<script>
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import CodeBlockComponent from './CodeBlock.vue'
import Focus from '@tiptap/extension-focus'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Image from '@tiptap/extension-image'
import ResizableImage from './custom_extensions/ImageUpload'
import MermaidBlock from './custom_extensions/MermaidBlock'
import Dropcursor from '@tiptap/extension-dropcursor'
import FormulaComponent from './custom_extensions/FormulaExtension.js'
import FormulaMark from './custom_extensions/FormulaMark.js'
import ReferenceMark from './custom_extensions/ReferenceWord.js'
import Commands from './custom_extensions/commands'
import suggestion from './custom_extensions/suggestion'


// load specific languages only
import { lowlight } from 'lowlight'
import ReferenceDrawer from './ReferenceDrawer.vue'




const reader = new FileReader();





export default {
    components: {
        EditorContent,
        FloatingMenu,
        ReferenceDrawer
    },
    data: () => ({
        editor: null,
        title: "",
        openReference: false,
        existingDocument: null,
        saveFormVisible: false,
        sectionOptions: [{ "label": "Guides", "value": "guides" }, { "label": "Sample Apps", "value": "sample apps" }, { "label": "Concepts", "value": "concepts" }, { "label": "Blog", "value": "blogs" }, { "label": "Rest API Reference", "value": "rest api" }, { "label": "Community Content", "value": "community content" }],
        section: "guides"
    }),
    methods: {
    },

    async mounted() {
        var component = this
        this.editor = new Editor({
            async onUpdate({ editor }) {
                const text = await editor.getText()
                
                component.$emit("style", text)
            },
            editorProps: {
                handleClickOn({ view }) {
                    
                    
                    component.editor.commands.insertContent('Example Text')
                }
            },
            spellcheck: false,
            extensions: [
                Document,
                Text,
                StarterKit,
                Dropcursor,
                Focus.configure({
                    className: 'has-focus',
                    mode: 'all',
                }),
                CodeBlockLowlight
                    .extend({
                        addNodeView() {
                            return VueNodeViewRenderer(CodeBlockComponent)
                        },
                    })
                    .configure({ lowlight, exitOnArrowDown: false }),
            ],
            content: "/* Your Css down here */"
        })
    }
}
</script>
<!-- 
<style lang="scss">
.style-editor {
    min-height: 30vh !important;
    overflow-x: scroll;
    .ProseMirror {
        min-height: 30vh !important;
        min-width: 100%;
        background-color: black !important;
        color: white;
    }
    >*+* {
        margin-top: 0.75em;
    }

    pre {
        background: #0D0D0D;
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        width: 100% !important;
        height: 100vh;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }

        .hljs-comment,
        .hljs-quote {
            color: #616161;
        }

        .hljs-variable,
        .hljs-template-variable,
        .hljs-attribute,
        .hljs-tag,
        .hljs-name,
        .hljs-regexp,
        .hljs-link,
        .hljs-name,
        .hljs-selector-id,
        .hljs-selector-class {
            color: #F98181;
        }

        .hljs-number,
        .hljs-meta,
        .hljs-built_in,
        .hljs-builtin-name,
        .hljs-literal,
        .hljs-type,
        .hljs-params {
            color: #FBBC88;
        }

        .hljs-string,
        .hljs-symbol,
        .hljs-bullet {
            color: #B9F18D;
        }

        .hljs-title,
        .hljs-section {
            color: #FAF594;
        }

        .hljs-keyword,
        .hljs-selector-tag {
            color: #70CFF8;
        }

        .hljs-emphasis {
            font-style: italic;
        }

        .hljs-strong {
            font-weight: 700;
        }
    }

}
</style> -->
