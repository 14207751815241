<template>
    <figure class="px-10 pt-10 pb-10">
        <h1 class="text-left">Global Objects</h1>
    </figure>

    <el-card class="box-card w-80">
        <figure class="pt-2">
            <h3 class="text-left">Add new Object</h3>
        </figure>
        <div class="card-actions justify-end pt-4">
            <button @click="handleNew()" class="btn btn-primary">Add Object</button>
        </div>
    </el-card>

    <el-card class="box-card mt-10">
        <el-table :data="objects" style="width: 100%">
            <el-table-column label="Name" prop="name" />
            <el-table-column label="Email" prop="email" />
            <el-table-column label="codebases" prop="codebases" />
            <el-table-column align="right">
                <template #header>
                    <el-input v-model="search" size="small" placeholder="Type to search" />
                </template>
                <template #default="scope">
                    <el-button size="small" @click="handleEdit(scope.$index, scope.row)">Edit</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">Delete
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>

    <el-dialog :width="'80%'" style="min-width: 1000px !important" v-model="dialogFormVisible" :title="title">
        <div class="object-form-card">
            <el-row>
                <el-col style="padding-left: 2em !important;padding-right: 2em !important;" :span="12">
                    <h3>Add Global Doc Object</h3>
                    <el-form label-position="top" ref="form" :model="form">
                        <el-form-item label="Name of Obect">
                            <el-input v-model="form.name" type="text" placeholder="object name" class="small-input" />
                        </el-form-item>

                        <el-form-item label="Origin Information" :label-width="formLabelWidth">
                            <el-col :span="12">
                                <el-select-v2 v-model="form.orginType"
                                    :options="[{ label: 'our codebase/service', value: 'internal' }, { label: 'external vendor or service', value: 'external' }]"
                                    placeholder="Origin Type" />
                            </el-col>
                            <el-col :span="12">
                                <el-select-v2 v-if="form.originType == 'external'" v-model="form.originSource"
                                    :options="[]" placeholder="Origin Source" filterable allow-create clearable />
                                <el-select-v2 v-else v-model="form.originSource" :options="codebases"
                                    placeholder="Origin Source" filterable allow-create clearable />
                            </el-col>

                        </el-form-item>
                        <el-form-item label="Add HTTP Info">
                            <el-switch v-model="httpOn" class="ml-2" inline-prompt
                                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                        </el-form-item>
                        <el-form-item v-if="httpOn" label="HTTP Production Base Url" :label-width="formLabelWidth">
                            <el-input v-model="form.http_info.prod_url" type="text" placeholder="object name"
                                class="small-input" />
                        </el-form-item>
                        <el-form-item v-if="httpOn" label="HTTP Dev Base Url" :label-width="formLabelWidth">
                            <el-input v-model="form.http_info.dev_url" type="text" placeholder="object name"
                                class="small-input" />
                        </el-form-item>
                        <el-form-item v-if="httpOn" label="HTTP Routes" :label-width="formLabelWidth">
                            <el-button @click="addRoute()">Add HTTP Route</el-button>
                        </el-form-item>
                        <el-form-item v-if="httpOn" v-for="(route, index) of form.http_info.routes"
                            :label-width="formLabelWidth">
                            <el-col :span="8">

                                <el-select-v2 v-model="route.method"
                                    :options="[{ label: 'get', value: 'get' }, { label: 'post', value: 'post' }, { label: 'delete', value: 'delete' }, { label: 'put', value: 'put' }, { label: 'patch', value: 'patch' }]"
                                    placeholder="Origin Type" />
                            </el-col>
                            <el-col :span="16">
                                <el-input v-model="route.route" type="text" placeholder="Route" class="small-input" />
                            </el-col>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="12">
                    <h3>Global Doc Body</h3>
                    <el-button @click="redactStringsInJson()">Redact String Values</el-button>
                    <pre id="json-display" ref="docjson" contenteditable="true">{{ form.objectJson || "{}" }}</pre>
                </el-col>
            </el-row>
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Cancel</el-button>
                <el-button @click="save()" type="primary">Save</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>

// for every foruth element
import { ref, computed } from 'vue'
import {
    ElButton,
    ElTag,
    TableV2FixedDir,
    TableV2SortOrder,
    RowEventHandler
} from 'element-plus'
import { async } from '@firebase/util'
import hljs from 'highlight.js'
import { thisExpression } from '@babel/types'


//var hls = highlight()

export default {
    data: () => ({
        objects: null,
        httpOn: false,
        redactStrings: false,
        formLabelWidth: '80%',
        title: "New Object",
        codebases: [],
        form: {
            name: '',
            orginType: '',
            originSource: '',
            objectJson: null,
            http_info: {
                dev_url: '',
                prod_url: '',
                routes: [],
            }
        }
    }),
    watch: {
    },
    setup() {
        const dialogFormVisible = ref(false)
        const search = ref('')
        const filterTableData = computed(() =>
            tableData.filter(
                (data) =>
                    !search.value ||
                    data.name.toLowerCase().includes(search.value.toLowerCase())
            )
        )

        const tableData = [
            {
                name: 'Tom',
                teams: ["yeah"],
                codebases: ["cool api", "rails"]
            }]

        return {
            tableData,
            dialogFormVisible,
            filterTableData,
            search
        }
    },
    async mounted() {
        var idtoken = await this.$authInstance.getToken()
        
        await this.getObjects()
        await this.getCodebases()
        // this.form.objectJson = JSON.stringify({"Some Key": "Some Value"}, undefined, 2)
    },
    methods: {
        redactStringsInJson() {
            var preElement = this.$refs.docjson
            
            var jsonForm = JSON.parse(preElement.innerText)
            
            var newObject = this.transformBody(jsonForm)
            
            this.form.objectJson = JSON.stringify(newObject, null, " ")

        },
        addRoute() {
            this.form.http_info.routes.push({ "method": "", "route": "" })
        },
        onJsonChange(e) {
            try {
                var preElement = this.$refs.docjson
                
                var jsonForm = JSON.parse(preElement.innerText)
                
                this.form.objectJson = JSON.stringify(jsonForm)
            } catch (e) {
                
            }

        },
        transformBody(currentObject) {
            var component = this
            function handleArrayRedaction(value) {
                var newArray = []
                for (const item of value) {
                    var redactedItem = redactValue(item)
                    newArray.push(redactedItem)
                }
                return newArray
            }
            function redactValue(value) {
                if (typeof value != "string") 
                if (typeof value == "string") return "Redacted string value"
                if (Array.isArray(value)) return handleArrayRedaction(value)
                if (typeof value == "object" && value != null && value != undefined) return component.transformBody(value)
                else return value
            }
            var newObject = Object.keys(currentObject).reduce((n, k) => (n[k] = redactValue(currentObject[k]), n), {});
            //i need to iterate through every value of the object
            return newObject
        },
        async save() {
            try {
                var preElement = this.$refs.docjson
                
                var jsonForm = JSON.parse(preElement.innerText)
                
                var newObject = this.transformBody(jsonForm)
                
                this.form.objectJson = JSON.stringify(jsonForm)
                this.form.http_info = JSON.stringify(this.form.http_info)
                var postBody = { ...this.form }
                if (!this.httpOn) delete postBody["http_info"]
                var myHeaders = new Headers();
                var idtoken = await this.$authInstance.getToken()
                myHeaders.append("Authorization", `Bearer ${idtoken}`);
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify(postBody);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                fetch(`${url}/objects`, requestOptions)

            } catch (e) {
                
            }

        },
        getItem() {
            try {
                
            } catch (e) {
                
            }
        },
        async addUser() {

            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var orgs = await this.$authInstance.getOrgs()
            var org = orgs[0]
            myHeaders.append("Authorization", `Bearer ${idtoken}`);
            myHeaders.append("Content-Type", "application/json");
            this.form.login = this.form.login.trim()
            var raw = JSON.stringify(this.form);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            fetch(`${url}/users`, requestOptions)

        },
        async getCodebases() {
            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var baseUrl = await this.$authInstance.getBaseUrl()
            myHeaders.append("Authorization", `Bearer ${idtoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            var codebaseRes = await fetch(`${baseUrl}/codebases`, requestOptions)
            var codebasesJson = await codebaseRes.json()
            
            const { data } = codebasesJson
            this.codebases = data.map(function (item) { return { label: item.friendly_name, value: item.friendly_name } })
        },
        async handleDelete(index, row) {
            
            await this.removeUser(row.id)
        },
        async handleEdit(index, row) {
            this.form = row
            this.title = "Edit Object"
            this.form.objectJson = row.objectBody
            if (row.http_info) this.httpOn = true
            
            if (row.http_info) this.form.http_info = JSON.parse(row.http_info)
            this.dialogFormVisible = true
        },
        async handleNew() {
            this.title = "New Object"
            this.form = {
                name: '',
                orginType: '',
                originSource: '',
                objectJson: null,
                http_info: {
                    dev_url: '',
                    prod_url: '',
                    routes: [],
                }
            }
            this.dialogFormVisible = true
        },
        async getObjects() {
            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var baseUrl = await this.$authInstance.getBaseUrl()
            myHeaders.append("Authorization", `Bearer ${idtoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            var objectsRes = await fetch(`${baseUrl}/objects`, requestOptions)
            var objectsJson = await objectsRes.json()
            
            this.objects = objectsJson.data
        },
        async removeUser(id) {
            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var baseUrl = await this.$authInstance.getBaseUrl()
            myHeaders.append("Authorization", `Bearer ${idtoken}`);

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };

            var userRes = await fetch(`${baseUrl}/users?model_id=${id}`, requestOptions)
        }
    }
}
</script>

<style>
.object-form-card {
    width: 100%;
    height: 100%;
    padding: 2em;
    background: #FFFFFF;
    box-shadow: 0px 32px 44px rgba(44, 39, 56, 0.02), 0px 32px 64px rgba(44, 39, 56, 0.04);
    border-radius: 24px;
}

.small-input {
    /* max-width: 80%; */
}

#json-display {
    border: 1px solid #000;
    margin: 0;
    padding: 8px 15px;
    min-height: 300px;
    background: #1c2833;
    color: #fff;
    overflow-x: scroll;
}
</style>