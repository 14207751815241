<!-- components/FolderTreeSelect.vue -->
<template>
    <TreeSelect v-model="selectedFolders" :options="treeNodes" selectionMode="checkbox" placeholder="Select item(s)"
        class="w-full !border-none bg-[#1c1c1c] text-white" fluid filter filterMode="lenient"
        @change="handleSelectionChange" />
</template>

<script>


export default {
    name: 'FolderTreeSelect',
    props: {
        data: {
            type: Object,
            required: false,
            // Expected format: { category: string, files: string[] }
        },
        additionalItems: {
            type: Array,
            required: false,
            default: () => [],
            // Expected format: [{ label: string, path: string }]
        }
    },
    data() {
        return {
            selectedFolders: [],
            treeNodes: [],
            currentParent: ''
        }
    },
    watch: {
        data: {
            immediate: true,
            handler(newData) {
                this.treeNodes = this.turnIntoNodes(newData)
            }
        },
        additionalItems: {
            immediate: true,
            handler(newData) {
                let treeNodes = this.treeNodes
                let nodeKey = treeNodes?.length
                for (let item of newData) {

                    let node = this.turnIntoNodes({ ...item, index: nodeKey })
                    console.log("what is the node", node)
                    treeNodes.push(node)
                    nodeKey += 1
                }
                console.log("what are the nodes", treeNodes)
                this.treeNodes = treeNodes
            }
        },


    },
    methods: {
        turnIntoNodes(data) {
            let parent = data.category
            const createNode = (key, label, isFile = false) => ({
                key,
                label,
                data: key,
                icon: isFile ? 'pi pi-fw pi-file' : 'pi pi-fw pi-folder',
                children: isFile ? undefined : [],
                parent
            });

            const root = createNode(`${data?.index}` || '0', data.category);

            for (let path of data.files) {
                const parts = path.split('/');
                let currentLevel = root.children;
                let currentPath = '';

                parts.forEach((part, index) => {
                    currentPath = currentPath ? `${currentPath}/${part}` : part;
                    const isFile = index === parts.length - 1 && part.includes('.');

                    let existingNode = currentLevel.find(node => node.label === part);

                    if (!existingNode) {
                        existingNode = createNode(`${parent}::parent::${currentPath}`, part, isFile);
                        currentLevel.push(existingNode);
                    }

                    if (!isFile && existingNode.children) {
                        currentLevel = existingNode.children;
                    }
                });
            }
            if (data.index) {
                return root
            }
            return [root];
        },
        manageParent(event) {
            console.log("this is the parent event", event)
        },
        async processSelectedFolders(folderObj) {
            try {
                let checkedFolders = Object.entries(folderObj)
                    .filter(([key, value]) => value.checked)
                    .map(([key]) => key);

                checkedFolders = checkedFolders.filter(folder => folder !== "0");
                checkedFolders.sort((a, b) => a.split('/').length - b.split('/').length);

                const result = [];

                for (let folder of checkedFolders) {
                    const isParent = result.some(existingFolder =>
                        existingFolder.startsWith(folder + '/'));
                    const isChild = result.some(existingFolder =>
                        folder.startsWith(existingFolder + '/'));

                    if (!isParent && !isChild) {
                        result.push(folder);
                    }
                }

                return result;
            } catch (e) {
                console.log(e)
                return [];
            }
        },

        // async handleSelectionChange(event) {
        //     try {
        //        //const processedFolders = await this.processSelectedFolders(this.selectedFolders);
        //        console.log("this is the selected folders", this.selectedFolders)    
        //         this.$emit('selection-change', this.selectedFolders);
        //         const selectedNodes = this.treeNodes.flatMap(node => {
        //             const findNodes = (n) => {
        //                 if (this.selectedFolders.includes(n.key)) {
        //                     return [n];
        //                 }
        //                 return n.children ? n.children.flatMap(findNodes) : [];
        //             };
        //             return findNodes(node);
        //         });
        //         this.$emit('selected-nodes', selectedNodes);
        //     } catch (e) {
        //         console.log(e);
        //         //this.selectedFolders = []
        //     }

        // }

        async handleSelectionChange(event) {
    try {
        // Create lookup Map for faster access
        const treeNodeMap = new Map();
        const buildTreeNodeMap = (nodes) => {
            nodes.forEach(node => {
                treeNodeMap.set(node.key, node);
                if (node.children?.length) {
                    buildTreeNodeMap(node.children);
                }
            });
        };
        
        // Build cache once
        if (!this._treeNodeMap || this._treeNodeMap.size === 0) {
            buildTreeNodeMap(this.treeNodes);
            this._treeNodeMap = treeNodeMap;
        }

        // Get checked entries in one pass
        const checkedEntries = [];
        const categorizedSelections = new Map();
        
        // Single iteration for filtering and organizing
        Object.entries(this.selectedFolders).forEach(([path, nodeData]) => {
            if (!nodeData.checked) return;
            
            const treeNode = this._treeNodeMap.get(path);
            if (!treeNode) return;
            
            const category = treeNode.parent;
            
            // Batch updates to selectedFolders
            this.selectedFolders[path] = {
                checked: nodeData.checked,
                partialChecked: nodeData.partialChecked,
                key: treeNode.key,
                label: treeNode.label,
                data: treeNode.data,
                icon: treeNode.icon,
                children: treeNode.children || [],
                parent: category
            };

            if (!categorizedSelections.has(category)) {
                categorizedSelections.set(category, new Set());
            }
            let parsedPath = path.split('::parent::')[1]
            categorizedSelections.get(category).add(parsedPath);
        });

        // Convert Map/Set to plain objects for emission
        const categorizedOutput = Object.fromEntries(
            Array.from(categorizedSelections).map(([key, value]) => [
                key, 
                Array.from(value)
            ])
        );

        this.$emit('selection-change', {
            selections: this.selectedFolders,
            byCategory: categorizedOutput
        });
    } catch (e) {
        console.error('Selection change error:', e);
    }
},
        // async handleSelectionChange(event) {
        //     try {
        //         // Get all checked entries
        //         console.log("this is the event", event)
        //         const checkedEntries = Object.entries(this.selectedFolders)
        //             .filter(([_, nodeData]) => nodeData.checked);

        //         // Create a map to store items by their top-level category
        //         const categorizedSelections = {};

        //         for (let selectedFolder of Object.keys(this.selectedFolder)) {
                    
        //                 let category = ""

        //                 // Add to categorized selections
        //                 categorizedSelections[category].push(path);
        //         }
                

        //         this.$emit('selection-change', {
        //             selections: this.selectedFolders,
        //             byCategory: categorizedSelections
        //         });
        //     } catch (e) {
        //         console.error('Selection change error:', e);
        //     }
        // },

        // Helper method to find node in tree
        findNodeByKey(key, nodes = this.treeNodes) {
            for (const node of nodes) {
                if (node.key === key) return node;
                if (node.children) {
                    const found = this.findNodeByKey(key, node.children);
                    if (found) return found;
                }
            }
            return null;
        }
    }
}
</script>