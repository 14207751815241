<template>
  <node-view-wrapper ref="currentNode" @mouseover.stop="hover = true" @contextmenu.prevent="showContextMenu" 
    @mouseleave="hover = false" :style="createStyle" :class="createClass">
    <!-- <span class="label-tester"><div class="settings" contenteditable="false" draggable="true" data-drag-handle @click="colorPickerDialog = true"></div></span> -->
    <edit-styles-bar  v-if="hover" @interaction="currentInteraction = true"
      @endinteraction="currentInteraction = false" @newstyle="setStyle" :attrs="node?.attrs"
      :openSettingsDialog="() => { return colorPickerDialog = true }"
      :openAiDialog="() => { return aiElementDialog = true }" :delete="deleteNode"
      :copy="() => { editor.commands.insertContent(node.toJSON()) }" />
    <node-view-content :style="{ padding: 0, maxWidth: '100%', margin: 0, position: 'relative', ...nodeViewStyle }"
      :class="[{ glassmorph: glassMorph }, 'content']" />
    <color-picker-dialog :attrs="node?.attrs" v-if="colorPickerDialog" @newheight="setNewHeight" @newwidth="setNewWidth"
      :show="colorPickerDialog" @newimage="setNewBackgroundImage" @glassmorph="setGlassMorph" @newbp="setNewBp"
      @newgradient="setNewColors" @newstyle="setStyle" @close="colorPickerDialog = false" />
    <add-element-dialog v-if="addElementDialog" :show="addElementDialog" @createelement="createElement"
      @close="addElementDialog = false" />
    <ai-element-dialog v-if="aiElementDialog" @newstyle="setStyle" :show="aiElementDialog" :node="node"
      @createelement="createElement" @close="aiElementDialog = false" />
    <!-- <el-dropdown ref="dropdown" v-if="showMenu" v-click-outside="hideContextMenu" :style="{left: `${menuX}px`, position: `fixed`}"> -->
    <!-- <el-button >
        Dropdown List
      </el-button> -->
    <div class="dropdown dropdown-open" v-if="showMenu" 
      :style="{ left: `${menuX}px`, top: `${menuY}px`, position: `fixed` }">
      <custom-context :node="node" @interaction="currentInteraction = true"
      @endinteraction="showMenu = false" @newstyle="setStyle" :attrs="node?.attrs"
      :openSettingsDialog="() => { return colorPickerDialog = true }"
      :openAiDialog="() => { return aiElementDialog = true }" :delete="deleteNode"
      :copy="() => { editor.commands.insertContent(node.toJSON()) }" />
    </div>
  </node-view-wrapper>
</template>

<script>

function cssToVueStyle(cssString) {
  const regex = /([a-zA-Z-]+)\s*:\s*([^;]+)/g;
  const styleObject = {};
  let match;
  while ((match = regex.exec(cssString)) !== null) {
    const propName = match[1].replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
    const propValue = match[2].trim();
    styleObject[propName] = propValue;
  }
  return styleObject;
}

import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import ColorPickerDialog from '../ColorPickerDialog.vue';
import AddElementDialog from '../cardCssDrawer.vue';
import AiElementDialog from '../aiElementDialog.vue';
import EditStylesBar from '../EditStylesBar.vue';
import CustomContext from '../CustomContext.vue';
import { debug } from 'util';


function findStringInParentheses(str) {
  if(!str) return null
  const regex = /\(([^)]+)\)/;
  const match = str.match(regex);
  
  return match ? match[1] : null;
}

export default {
  props: nodeViewProps,

  components: {
    NodeViewWrapper,
    NodeViewContent,
    ColorPickerDialog,
    AddElementDialog,
    AiElementDialog,
    EditStylesBar,
    CustomContext
  },
  data: () => ({
    angle: '50',
    color1: 'red',
    hover: false,
    showMenu: false,
    menuX: 400,
    menuY: 600,
    color2: 'blue',
    gradientColor: '',
    style: '',
    styleString: '',
    classString: '',
    nodeViewStyle: null,
    colorPickerDialog: false,
    addElementDialog: false,
    aiElementDialog: false,
    lock: false,
    bp: null,
    newColor: null,
    newTextColor: null,
    newHeight: null,
    glassMorph: false,
    newWidth: null,
    currentInteraction: false
  }),
  async mounted() {
    const { background, style, customStyleString } = this?.node?.attrs
    
    this.classString = `${this.node.attrs.class} draggable-item`
    if (background) this.gradientColor = background
    else this.gradientColor = `linear-gradient(${this.angle}deg, ${this.color1}, ${this.color2})`
    if (style) this.styleString = style

    if (this.styleString?.includes('url') || this.styleString.includes("raw.githubusercontent.com")) {
      if (this.styleString.includes("https://raw.githubusercontent.com/avb-is-me/docusarusFun/main/static/img/2023-02-07T14%3A49%3A58.201Z.png?token=AY3CZN3SCJRGW7U27BOOUO3EIBVAU")) 
      await this.getData()
      
      
      

    } else {
      
      await this.setStyle(this.styleString)
    }



    // 
    // 
    // if (this?.node?.attrs?.customStyleString) await this.setStyle(this?.node?.attrs?.customStyleString)

  },
  methods: {
    showContextMenu(event) {
      
      // check if the right-clicked element is the specific node
      event.preventDefault()
      this.menuX = event.clientX;
      this.menuY = event.clientY;
      
      this.showMenu = true;
      
      this.$refs.dropdown.value.handleOpen()
      // window.alert(`hello ${event.clientX}`)

    },
    hideContextMenu() {
      this.showMenu = false;
    },
    doSomething() {
      // execute some TipTap command here
      this.editor.commands.setNode('myNode', { foo: 'bar' });
      this.hideContextMenu();
    },
    copy() {
      document.execCommand('copy');
      this.hideContextMenu();
    },
    setLock() {
      this.updateAttributes({ lock: true })
      this.lock = true
    },
    async setNewColors(val) {
      
      const { textColor, background } = val
      this.newColor = background
      this.newTextColor = textColor
      this.updateAttributes({ background: background })
      this.styleString = this.replaceBackground(this.styleString, background)
      this.style = await this.setStyle(this.styleString)
    },
    setNewBp(val) {
      this.bp = val
      
      
      this.updateAttributes({ backgroundPosition: val })
    },
    async setNewBackgroundImage(val) {
      
      
      this.newColor = val.previewUrl
      this.updateAttributes({ background: val.externalImage })
      this.styleString = this.replaceBackground(this.styleString, val.previewUrl)
      this.updateAttributes({ style: this.styleString })
      this.style = await this.setStyle(this.styleString)
    },
    setNewHeight(val) {
      
      this.newHeight = val
      this.updateAttributes({ height: this.newHeight })
    },
    setNewWidth(val) {
      
      this.newWidth = val
      this.updateAttributes({ width: this.newWidth })
    },
    async setStyle(val) {
      
      this.styleString = val
      const style = cssToVueStyle(val)
      
      this.style = style
      if (val) await this.updateAttributes({ customStyle: style })
      if (val) await this.updateAttributes({ style: val })
      if (val) await this.updateAttributes({ customStyleString: val })
      const { display, gap, alignContent, alignItems, flexDirection, justifyContent, flexWrap, flexFlow } = style
      if (val) this.nodeViewStyle = { display, gap, alignContent, alignContent, alignItems, flexDirection, justifyContent, flexWrap, flexWrap, padding: "0px", margin: "0px" }
    },
    setGlassMorph(val) {
      this.glassMorph = val
      if (val) this.updateAttributes({ class: "glass-morph" })
      else this.updateAttributes({ class: "" })
    },
    async destory() {
      this.deleteNode()
      return
    },
    async duplicate() {
      const node = this.node.toJSON()
      
      this.editor.commands.insertContent(node)
      return
    },
    replaceBackground(cssString, newBackgroundValue) {
      
      
      const regex = /(;?\s*background(-image)?\s*:\s*[^;]*;?)/g;
      const hasBackground = cssString.match(regex);
      
      
      let newString;
      if (hasBackground) {
        
        
        if (hasBackground[0][0] === ";") newString = cssString.replace(regex, `;background: ${newBackgroundValue};`);
        else newString = cssString.replace(regex, `background: ${newBackgroundValue};`);
      } else {
        newString = cssString.concat(`background: url('${newBackgroundValue}') no-repeat center center / cover;`)
      }

      return newString
    },
    async getData(folderNode) {
      console.log("FUC FUCK")
      try {
        const component = this
      
      const urlValue = `${findStringInParentheses(component.node.attrs.style)}`.replace(/"/g, "").replace(/'/g, "")

      
      var myHeaders = new Headers();
      var token = await this.$authInstance.getToken()
      myHeaders.append("Content-Type", "application/json");
      if (token) {
        myHeaders.append("Authorization", `Bearer ${token}`)
      }
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      
      var url = await this.$authInstance.getBaseUrl()

      var saveResponseOne = await fetch(`${url}/external_page?path=static/img`, requestOptions)
      
      var jsonResponseOne = await saveResponseOne.json()
      var saveResponseTwo = await fetch(`${url}/external_page?path=static/img/dev-docs-assets`, requestOptions)
      
      var jsonResponseTwo = await saveResponseTwo.json()
    
      
      const images = [...jsonResponseOne.docs, ...jsonResponseTwo.docs]
      var currentImage = images.find(function (item) { return item.path.includes(urlValue) })
      console.log("this is the current image", currentImage)
      
      // return currentImage
      this.styleString = this.replaceBackground(this.styleString, `url('${currentImage.download_url}') no-repeat center center / cover`)
      
      this.style = await this.setStyle(this.styleString)
      this.newColor = `url('${currentImage.download_url}') no-repeat center center / cover`
      this.updateAttributes({ style: this.styleString })

      } catch(e) {
        
      }
    
    },
  },
  watch: {
    colorPickerDialog(val) {
      
      if (val) this.editor.setEditable(false)
      else this.editor.setEditable(true)
    },
    addElementDialog(val) {
      
      if (val) this.editor.setEditable(false)
      else this.editor.setEditable(true)
    },
    aiElementDialog(val) {
      
      if (val) this.editor.setEditable(false)
      else this.editor.setEditable(true)
    },
    styleString(val) {
      
    }
  },
  computed: {
    isLocked() {
      
      return this.editor.storage.draggableItem.disableDrag
    },
    async shouldShow() {
      var modes = await JSON.parse(localStorage.getItem("editMode"))
      
      if (modes?.edit) return this.hover && true
      else return false
    },
    createBp() {
      
      if (this.bp) return this.bp
      else return
    },
    createBackgroundString() {

      if (!this.newColor && !this.node.attrs.background) return `#30363d`
      else {
        const background = this.newColor || this.node.attrs.background
        return background
      }
    },
    createStyle() {
      
      if (this.styleString.includes("position: relative;")) return this.styleString
      return "position: relative;" + this.styleString
    },
    createClass() {
      return this.classString
    },
    shouldBeFlex() {
      if (this?.style?.display == "flex") return "flex"
      else return "block"
    },
    createHeight() {
      return this.newHeight
    },
    createElement(val) {
      
      //this.editor.commands.setMark("customCssMark", { class: val.classes, style: val.style })
    },
    createWidth() {
      return this.newWidth
    }
  }
}
</script>

<style lang="scss">
.draggable-item {
  border-radius: 0.5rem;
  display: flex;
  background: white;
  color: black;
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1),
  ;

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: v-bind('color1');
    word-wrap: break-word;
    overflow-wrap: break-word;
  }



  .glassmorph {
    background: radial-gradient(100% 359.18% at 0% 0%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.2) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 20px;
    min-width: 100%;
    min-height: 100%;
    padding: 0.5rem;
  }



  .content {
    flex: 1 1 auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}


.settings {
  cursor: grab;
  color: white;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="#AB7C94" d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"></path></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 0.5rem;
  background-blend-mode: difference;
}

.chat {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill-opacity="0.2"  d="m174.72 855.68 130.048-43.392 23.424 11.392C382.4 849.984 444.352 864 512 864c223.744 0 384-159.872 384-352 0-192.832-159.104-352-384-352S128 319.168 128 512a341.12 341.12 0 0 0 69.248 204.288l21.632 28.8-44.16 110.528zm-45.248 82.56A32 32 0 0 1 89.6 896l56.512-141.248A405.12 405.12 0 0 1 64 512C64 299.904 235.648 96 512 96s448 203.904 448 416-173.44 416-448 416c-79.68 0-150.848-17.152-211.712-46.72l-170.88 56.96z"></path></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 0.5rem;
  background-blend-mode: difference;
}

.trash {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z"></path></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 0.5rem;
  background-blend-mode: difference;
}

.copy {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M768 832a128 128 0 0 1-128 128H192A128 128 0 0 1 64 832V384a128 128 0 0 1 128-128v64a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64h64z"></path><path fill="currentColor" d="M384 128a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64V192a64 64 0 0 0-64-64H384zm0-64h448a128 128 0 0 1 128 128v448a128 128 0 0 1-128 128H384a128 128 0 0 1-128-128V192A128 128 0 0 1 384 64z"></path></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 0.5rem;
  background-blend-mode: difference;
}

.drag-handle {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  margin-right: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path style="mix-blend-mode: difference;" fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-blend-mode: difference;
}

.lock {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  margin-right: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M224 448a32 32 0 0 0-32 32v384a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V480a32 32 0 0 0-32-32H224zm0-64h576a96 96 0 0 1 96 96v384a96 96 0 0 1-96 96H224a96 96 0 0 1-96-96V480a96 96 0 0 1 96-96z"></path><path fill="currentColor" d="M512 544a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V576a32 32 0 0 1 32-32zm192-160v-64a192 192 0 1 0-384 0v64h384zM512 64a256 256 0 0 1 256 256v128H256V320A256 256 0 0 1 512 64z"></path></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.inside-magic {
  background: var(--clr-2);
  min-height: 100%;
  display: grid;
  place-content: center;
  margin-top: 0px;
}

.magic-card {
  height: 60vh;
  aspect-ratio: 1 / 1.5;
  background: var(--clr-1);
  border-radius: 0.5rem;
  z-index: 1;
  position: relative;
}

.magic-card:hover {
  color: rgb(88, 199, 250);
  transition: color 1s;
}

.magic-card::before,
.magic-card::after {
  content: "";
  position: absolute;
  inset: -0.5rem;
  z-index: -1;
  background: conic-gradient(from var(--gradient-angle),
      var(--clr-3),
      var(--clr-4),
      var(--clr-5),
      var(--clr-4),
      var(--clr-3));
  border-radius: inherit;
  animation: rotation 20s linear infinite;
}

.magic-card::after {
  filter: blur(3.5rem);
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }

  100% {
    --gradient-angle: 360deg;
  }
}
</style>
