<template>
    <el-upload class="upload-demo" drag multiple :on-change="uploadImagesToBackend" :action="url">
      <el-icon class="el-icon--upload">
        <upload-filled />
      </el-icon>
      <div class="el-upload__text">
        Drop file here or <em>click to upload</em>
      </div>
      <template #tip>
        <div class="el-upload__tip">
          jpg/png files with a size less than 500kb
        </div>
      </template>
    </el-upload>
  </template>
  
  <script>
  export default {
    data: () => ({
      url: null,
    }),
    async mounted() {
      this.url = this.$vscodeInstance.getBaseUrl() + "/images"
    },
    methods: {
      async postImage(url) {
        
        try {
          var myHeaders = new Headers();
          var token = this.$vscodeInstance.token
          myHeaders.append("Authorization", `Bearer ${token}`);
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({ file: url })
          var res = await fetch(this.url, { method: 'POST', headers: myHeaders, body: raw });
          var responseJson = await res.json()
          
          this.$emit("postedimage", responseJson)
        } catch (e) {
          
        }
  
      },
      async uploadImagesToBackend(uploadFile, uploadFiles) {
        var component = this
        const reader = new FileReader();
        
        
        reader.addEventListener("load", async function () {
          if (!component.$vscodeInstance.token || component.$vscodeInstance.readFromLocal) {
            
            component.$emit("localimage", reader.result)
          } else {
            
            //await component.postImage(reader.result)
          }
  
          // convert image file to base64 string
        }, false);
  
        var dataUrl = await reader.readAsDataURL(uploadFile.raw)
        
      }
    }
  }
  </script>