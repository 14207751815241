var subMenu = function () {
    this.reference = function() {
        return `${this.repo}-reference`
    }
    this.general = function() {
        return `${this.repo}-general`
    }
    this.contexts = null
    this.folderlessContexts = null
    this.repo = undefined
    this.done = undefined
    this.sortContext = async function(docs) {
        try {
        var directories = docs.filter(function(doc) { return doc.folder != undefined && doc?.doctype == "context"}).sort(function(a, b) { return b.file.length - a.file.length})
        var miscFiles = docs.filter(function(doc) {return doc?.file?.includes("misc-")})
        
        var files = docs.filter(function(doc) { return doc.folder == undefined && doc?.doctype == "context"})
        
        var organizationObjects = []
        var allDirectoryFileNames = []
        for(const directory of directories) {
            
            
        
            var organizationObject = {name: directory.file, ...directory}
            organizationObject.files = files.filter(function(doc){ return doc?.file?.includes(directory.file)})
            organizationObject.fileNames = organizationObject.files.map(function(item) {
                return item.file
            })
            //allDirectoryFileNames = [...allDirectoryFileNames, ...organizationObject.fileNames]
            
            files = files.filter(doc => !organizationObject?.fileNames?.includes(doc?.file));
            organizationObjects.push(organizationObject)
        }
        this.folderlessContexts = files
        
        this.contexts = organizationObjects
        this.misc = miscFiles
        this.done = true
        return organizationObjects

        } catch(e) {
            this.done = true
            
        }
    }
}

export default subMenu