<template>
    <node-view-wrapper @mouseover="hover = true" @mouseleave="hover = false" class="draggable-image-item">
        <img v-if="imageSrc" class="c-image-holder" :style="styleString" loading="lazy" @error="retryImage"
            :src="imageSrc" />
        <Skeleton v-else width="20rem" height="20rem" borderRadius="16px"></Skeleton>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { getImageFiles, getFileContent, getOwnerAndRepo, getImageFileContent } from '@/plugins/devdocsBackendService';
import { getImageContent } from '@/plugins/imageService';
//   import ColorPickerDialog from '../ColorPickerDialog.vue';
//   import AddElementDialog from '../cardCssDrawer.vue';
//   import EditStylesBar from '../EditStylesBar.vue';
//   import { handleImageSrc, replaceImageUrl, getOrCreateImage } from '@/helper_functions/imageHelper';
//   import { updateCssAttributes, getCssUpdateData } from '@/helper_functions/cssHelper';
//   import { handlePaste } from '@/helper_functions/imageHelper';


export default {
    components: {
        NodeViewWrapper
    },
    data: () => ({
        imageSrc: null,
        hover: false,
        currentInteraction: false,
        colorPickerDialog: false,
        styleString: "",
        uploadImageDialog: false,
        apiUrl: ""
    }),
    props: nodeViewProps,
    async mounted() {
        try {
            let images = await getImageFiles({ branch: 'main' });
            let mainImage = images.fileNames.find(image => image.includes(this.node.attrs.src));
            let { owner, repo } = await getOwnerAndRepo();
            let { content } = await getImageFileContent({ branch: 'main', fileName: mainImage, owner, repo });

            // Create data URL
        
            this.imageSrc = content
            console.log('Image loaded:', this.imageSrc);
        } catch (error) {
            console.error('Error loading image:', error);
            // Fallback to raw GitHub URL
            //this.imageSrc = `https://raw.githubusercontent.com/team-dev-docs/devdocsprod-dev-docs/main/static${this.node.attrs.src}`;
        }
    },

};
</script>


<style scoped lang="scss">
.draggable-image-item {
    border-radius: 0.5rem;
    display: flex;
    background: transparent;
    align-items: center;
    position: relative;
    box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.05),
        0px 10px 20px rgba(0, 0, 0, 0.1),
    ;

    .image-handle {
        position: absolute;
        top: 0;
        left: 0;

        /* Remove unnecessary styles */
        flex: none;
        width: auto;
        height: auto;
    }

    .glassmorph {
        background: radial-gradient(100% 359.18% at 0% 0%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.2) 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        ;
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(15px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 20px;
        min-width: 100%;
        min-height: 100%;
        padding: 0.5rem;
    }



    .content {
        flex: 1 1 auto;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

}


.settings {
    cursor: grab;
    color: white;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="#AB7C94" d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 0.5rem;
    background-blend-mode: difference;
}

.chat {
    flex: 0 0 auto;
    position: relative;
    width: 1rem;
    height: 1rem;
    top: 0.3rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill-opacity="0.2"  d="m174.72 855.68 130.048-43.392 23.424 11.392C382.4 849.984 444.352 864 512 864c223.744 0 384-159.872 384-352 0-192.832-159.104-352-384-352S128 319.168 128 512a341.12 341.12 0 0 0 69.248 204.288l21.632 28.8-44.16 110.528zm-45.248 82.56A32 32 0 0 1 89.6 896l56.512-141.248A405.12 405.12 0 0 1 64 512C64 299.904 235.648 96 512 96s448 203.904 448 416-173.44 416-448 416c-79.68 0-150.848-17.152-211.712-46.72l-170.88 56.96z"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 0.5rem;
    background-blend-mode: difference;
}

.trash {
    flex: 0 0 auto;
    position: relative;
    width: 1rem;
    height: 1rem;
    top: 0.3rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 0.5rem;
    background-blend-mode: difference;
}

.copy {
    flex: 0 0 auto;
    position: relative;
    width: 1rem;
    height: 1rem;
    top: 0.3rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M768 832a128 128 0 0 1-128 128H192A128 128 0 0 1 64 832V384a128 128 0 0 1 128-128v64a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64h64z"></path><path fill="currentColor" d="M384 128a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64V192a64 64 0 0 0-64-64H384zm0-64h448a128 128 0 0 1 128 128v448a128 128 0 0 1-128 128H384a128 128 0 0 1-128-128V192A128 128 0 0 1 384 64z"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 0.5rem;
    background-blend-mode: difference;
}

.drag-handle {
    flex: 0 0 auto;
    position: relative;
    top: 0.3rem;
    margin-right: 0.5rem;
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path style="mix-blend-mode: difference;" fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-blend-mode: difference;
}

.lock {
    flex: 0 0 auto;
    position: relative;
    width: 1rem;
    height: 1rem;
    top: 0.3rem;
    margin-right: 0.5rem;
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M224 448a32 32 0 0 0-32 32v384a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V480a32 32 0 0 0-32-32H224zm0-64h576a96 96 0 0 1 96 96v384a96 96 0 0 1-96 96H224a96 96 0 0 1-96-96V480a96 96 0 0 1 96-96z"></path><path fill="currentColor" d="M512 544a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V576a32 32 0 0 1 32-32zm192-160v-64a192 192 0 1 0-384 0v64h384zM512 64a256 256 0 0 1 256 256v128H256V320A256 256 0 0 1 512 64z"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.c-image-holder {
    width: 100%;
    height: 100%;

}

.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>