
  

<template>
    <div ref="code">
        <editor-content autocorrect="off" autocapitalize="off" spellcheck="false" class="card-style-editor"
            :editor="editor" />
        <ai-block :lang="'css'" @airesult="aiResult" />
    </div>
</template>

<script>
import AiBlock from './aiReuseableBlock.vue'
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import { CodeBlock } from '@tiptap/extension-code-block'
import Focus from '@tiptap/extension-focus'
import Dropcursor from '@tiptap/extension-dropcursor'
import ListItem from '@tiptap/extension-list-item'
import { HardBreak } from '@tiptap/extension-hard-break'

const CustomListItem = ListItem.extend({
    addKeyboardShortcuts() {
        return {
            'Tab': () => {
                this.editor.chain()
                    .sinkListItem('listItem')
                    .command(({ tr }) => {
                        tr.insertText(' ')
                        return true
                    })
                    .run()
                this.editor.commands.insertContent("    ")
                return true // <- make sure to return true to prevent the tab from blurring.
            },
        }
    },
})



export default {
    components: {
        EditorContent, AiBlock
    },
    props: {
        css: String, collected: Array
    },
    data: () => ({
        editor: null,
        title: "",
        style: null,
        openReference: false,
        existingDocument: null,
        saveFormVisible: false,
        sectionOptions: [{ "label": "Guides", "value": "guides" }, { "label": "Sample Apps", "value": "sample apps" }, { "label": "Concepts", "value": "concepts" }, { "label": "Blog", "value": "blogs" }, { "label": "Rest API Reference", "value": "rest api" }, { "label": "Community Content", "value": "community content" }],
        section: "guides"
    }),
    computed: {
        exitFullscreen() {
            return document.exitFullscreen
        }
    },
    watch: {
        style(val) {
            
        },
        css(val) {
            
            this.editor.commands.setContent(val)
            this.editor.commands.setCodeBlock()
        }
    },
    methods: {
        aiResult(val) {
            this.editor.commands.setContent(val)
            this.editor.commands.setCodeBlock()
            // this.style = val
            //this.$emit("style", val)
        },
        toFullScreen() {
            
            if (!document.fullscreenElement) {
                
                const codeBlock = this.$refs.code;
                codeBlock.requestFullscreen();

            } else if (document.exitFullscreen) {
                document.exitFullscreen();
            }

        },
    },

    async mounted() {
        
        
        this.style = this.css
        var component = this
        this.editor = new Editor({
            async onUpdate({ editor }) {
                const text = await editor.getText()
                
                component.$emit("style", text)
            },
            editorProps: {
                handleClickOn({ view }) {
                    
                    
                }
            },
            spellcheck: false,
            extensions: [
                Document,
                Text,
                StarterKit,
                HardBreak.extend({
                    addKeyboardShortcuts() {
                        return {
                            Enter: () => this.editor.commands.newlineInCode()
                        }
                    }
                }),
                Dropcursor,
                CustomListItem,
                Focus.configure({
                    className: 'has-focus',
                    mode: 'all',
                }),
                CodeBlock
                    .extend({
                        addKeyboardShortcuts() {
                            return {
                                // ↓ your new keyboard shortcut
                                'Enter': (editorObject) => {
                                    
                                    const { editor } = editorObject
                                    return false
                                    // editor.commands.chain().focus().setHardBreak().run()
                                },
                            }
                        }
                    })
                    .configure({
                        exitOnArrowDown: false, exitOnTripleEnter: false, HTMLAttributes: {
                            class: 'language-html',
                        }
                    })
            ],
            content: `${this.style || ""}`
        })
        this.editor.commands.setCodeBlock()
    }
}
</script>

<!-- <style lang="scss">
.card-style-editor {
    max-height: 30vh !important;
    overflow-x: scroll;
    min-width: 100% !important;

    .ProseMirror {
        max-height: 30vh !important;
        min-width: 100%;
        background-color: black !important;
        color: white;
        padding: 0px !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        cursor: pointer;
        display: none;
    }

    >*+* {
        margin-top: 0.75em;
    }

    .language-css {
        min-width: 100% !important;
    }

    pre {
        background: #0D0D0D;
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0;
        border-radius: 0.5rem;
        min-width: 100vh !important;
        height: 100vh;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    //     .hljs-comment,
    //     .hljs-quote {
    //         color: #616161;
    //     }

    //     .hljs-variable,
    //     .hljs-template-variable,
    //     .hljs-attribute,
    //     .hljs-tag,
    //     .hljs-name,
    //     .hljs-regexp,
    //     .hljs-link,
    //     .hljs-name,
    //     .hljs-selector-id,
    //     .hljs-selector-class {
    //         color: #F98181;
    //     }

    //     .hljs-number,
    //     .hljs-meta,
    //     .hljs-built_in,
    //     .hljs-builtin-name,
    //     .hljs-literal,
    //     .hljs-type,
    //     .hljs-params {
    //         color: #FBBC88;
    //     }

    //     .hljs-string,
    //     .hljs-symbol,
    //     .hljs-bullet {
    //         color: #B9F18D;
    //     }

    //     .hljs-title,
    //     .hljs-section {
    //         color: #FAF594;
    //     }

    //     .hljs-keyword,
    //     .hljs-selector-tag {
    //         color: #70CFF8;
    //     }

    //     .hljs-emphasis {
    //         font-style: italic;
    //     }

    //     .hljs-strong {
    //         font-weight: 700;
    //     }
    // }

}
</style> -->
