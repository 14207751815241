const mermaid = require('mermaid')

var mermaidInstance = function() {
    this.mermaid = mermaid
    this.initialize = async function() {
        // await this.mermaid.initialize({startOnLoad:true});
        await this.mermaid.init({noteMargin: 10}, ".someOtherClass");
    }
}


export default mermaidInstance

