<template data-theme="dark" >
    <el-row>
        <el-col :span="4" style="border-right: 0.5px solid #979797 !important; min-height: 100vh !important">
            <el-menu text-color="black" class="el-menu-vertical-demo" :router="true" active-text-color="black"
                style="margin-bottom: 20px; border-style: none; padding-top: 0em !important; margin-top: 0em !important; min-height: 100%; max-height: 100vh; max-width: 100%"
                :collapse="false">
                <el-sub-menu index="1">
                    <template #title>
                        <span>Guides</span>
                    </template>
                    <el-menu-item style="padding: 1em;" v-for="(page, index) of guideDocs" :index="`/external_docs/${currentSection}/${page.title}`">{{page.title}}</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="2">
                    <template #title>
                        <span>Sample Apps</span>
                    </template>
                       <el-menu-item v-for="(page, index) of sampleDocs" :index="`/external_docs/${currentSection}/${page.title}`">{{page.title}}</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="3">
                    <template #title>
                        <span>Concepts</span>
                    </template>
                     <el-menu-item v-for="(page, index) of conceptDocs" :index="`/external_docs/${currentSection}/${page.title}`">{{page.title}}</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="4">
                    <template #title>
                        <span>Blog</span>
                    </template>
                  <el-menu-item v-for="(page, index) of blogDocs" :index="`/external_docs/${currentSection}/${page.title}`">{{page.title}}</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="4">
                    <template #title>
                        <span>Rest API Reference</span>
                    </template>
                   <el-menu-item v-for="(page, index) of restDocs" :index="`/external_docs/${currentSection}/${page.title}`">{{page.title}}</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="5">
                    <template #title>
                        <span>Commnunity Content</span>
                    </template>
                    <el-menu-item-group title="Group One">
                        <el-menu-item index="1-1">item one</el-menu-item>
                        <el-menu-item index="1-2">item two</el-menu-item>
                    </el-menu-item-group>
                    <el-menu-item-group title="Group Two">
                        <el-menu-item index="1-3">item three</el-menu-item>
                    </el-menu-item-group>
                    <el-sub-menu index="1-4">
                        <template #title>item four</template>
                        <el-menu-item index="1-4-1">item one</el-menu-item>
                    </el-sub-menu>
                </el-sub-menu>


            </el-menu>
        </el-col>
        <el-col :span="20">
            <div v-if="!isPublished" class="card-actions justify-end mr-5 mt-5"><el-button @click="edit()">Edit</el-button></div>
            <read-only v-if="currentDoc" :content="currentDoc.content" />
        </el-col>
    </el-row>
</template>

<script>

import ReadOnly from "@/components/ExternalReadOnly.vue";
export default {
    data: () => ({
        currentSection: null,
        externalDocs: null,
        conceptDocs: null,
        sampleDocs: null,
        guideDocs: null,
        blogDocs: null,
        restDocs: null,
        currentDoc: null,
        page: null
    }),
    computed: {
        isPublished() {
            return this.$route.name == "published document"
        }
    },
    components: {
        ReadOnly
    },
    async mounted() {
        const component = this
        this.currentSection = this.$route.params.section
        this.page = decodeURI(this.$route.params.page)
        var filter = `${this.currentSection}-${this.page}`
        // 
        // var myHeaders = new Headers();
        // var token = await this.$authInstance.getToken()
        // 
        // myHeaders.append("Content-Type", "application/json");
        // if (token) {
        //     myHeaders.append("Authorization", `Bearer ${token}`)
        // }
        // var url = await this.$authInstance.getBaseUrl()
        // var requestOptions = {
        //     method: 'GET',
        //     headers: myHeaders,
        //     redirect: 'follow'
        // };

        // var responseData = await fetch(`${url}/external_docs?searchKey=filter&searchValue=${filter}`, requestOptions)
        // 
        // var resJson = await responseData.json()

        // if (resJson?.data[0]) this.currentDoc = resJson.data[0]

        await this.getOtherDocs()

        // this.$subMenu.contexts = resJson.data.map(function(item) { return item.file })

    },
    methods: {
        edit() {
             var filter = `${this.currentSection}-${this.page}`
            this.$router.push(`/external_docs/${filter}/edit`)
        },
        async getOtherDocs() {
            // var myHeaders = new Headers();
            // var token = await this.$authInstance.getToken()
            // 
            // myHeaders.append("Content-Type", "application/json");
            // if (token) {
            //     myHeaders.append("Authorization", `Bearer ${token}`)
            // }
            // var url = await this.$authInstance.getBaseUrl()
            // var requestOptions = {
            //     method: 'GET',
            //     headers: myHeaders,
            //     redirect: 'follow'
            // };

            // var responseData = await fetch(`${url}/external_docs`, requestOptions)
            // 
            // var resJson = await responseData.json()
            // var externalDocs = resJson.data
            var externalDocs;
            if (this.$route.name == "published page") externalDocs = await this.getPublishedDocs()
            else externalDocs = await this.getUnPublishedDocs()
            if(!externalDocs) return
            this.conceptDocs = externalDocs.filter(function (item) { return item.section == "concepts"})
            this.sampleDocs = externalDocs.filter(function (item) { return item.section == "sample apps"})
            this.guideDocs = externalDocs.filter(function (item) { return item.section == "guides"})
            this.blogDocs = externalDocs.filter(function (item) { return item.section == "blogs"})
            this.restDocs = externalDocs.filter(function (item) { return item.section == "rest api"})
            var allDocs = {"guides": this.guideDocs, "sample apps": this.sampleDocs, "blogs": this.blogDocs, "rest api": this.restDocs, "concepts": this.conceptDocs}
            
            var filter = `${this.currentSection}-${this.page}`
            var currentSectionDocs = allDocs[this.currentSection]
            
            
            const foundDoc = currentSectionDocs.find(function(doc){ return doc.filter == filter})
            
            this.currentDoc = foundDoc

        },
        async getUnPublishedDocs() {
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var url = await this.$authInstance.getBaseUrl()
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            var responseData = await fetch(`${url}/external_docs`, requestOptions)
            
            var resJson = await responseData.json()
            var externalDocs = resJson.data
            
            return externalDocs
        },
        async getPublishedDocs() {
            
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var url = await this.$authInstance.getUrl()
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var org = this.$route.params.org
            var responseData = await fetch(`${url}/company/${org}/published_docs`, requestOptions)
            
            var resJson = await responseData.json()
            var externalDocs = resJson.data
            return externalDocs
        }

    }

}

</script>