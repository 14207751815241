<template>

    <div style="width: 100%; height: 100%">
        <div style="display: flex; flex-direction: column; gap: 1em; width: 100%; height: 100%">
            <div style="display: flex; flex-direction: column;" class="p-field">
                <label class="mt-4 mb-4 p-card-subtitle">Where do you want to create the new Document or Page</label>
                <InputGroup>
                    <InputGroupAddon>
                        <i class="pi pi-folder"></i>
                    </InputGroupAddon>
                    <AutoComplete v-model="locationValue" dropdown :suggestions="items" @complete="search" />
                </InputGroup>
            </div>
            <InputText v-model="titleValue" placeholder="Prompt for some starting content" />
            <SelectButton v-model="editorSelectvalue" :options="editorOptions" aria-labelledby="basic" />
            <div style="display: flex; width: 100%; height: 100%; gap: 2em;">
                <Skeleton v-if="loading" width="100%" height="100%"></Skeleton>
                <div v-else style="display: flex; flex-direction: column; width: 100%; height: 100%; gap: 2em;">
                    <a v-if="response" :href="response.url" target="_blank" class="p-card-subtitle">First Generated Draft</a>
                    <div style="display: flex; width: 100%; justify-content: flex-end; align-items: flex-end">
                        <Button @click="createExternalPage">Publish</Button>
                    </div>
                    <bubble-menu
                        :editor="editor"
                        :tippy-options="{ duration: 100 }"
                        v-if="editor"
                    >
                        <div class="bubble-menu">
                            <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                                Bold
                            </button>
                            <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                                Italic
                            </button>
                            <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
                                Strike
                            </button>
                        </div>
                    </bubble-menu>
                    <floating-menu :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor">
                        <div class="floating-menu">
                            <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                            H1
                            </button>
                            <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                            H2
                            </button>
                            <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
                            Bullet list
                            </button>
                        </div>
                    </floating-menu>
                    <editor-content style="width: 100%; height: 100%"
                        v-if="editor && editorSelectvalue === 'Use rich text editor(preview)'" :editor="editor" />
                    <Textarea v-else v-model="draftContent" style="width: 100%; height: 100% !important;" fluid />

                </div>
                <div style="display: flex; flex-direction: column; position: relative;">
                    <label class="mt-4 mb-4 p-card-subtitle">Generate or modify your content with AI</label>
                    <Textarea rows="5" cols="80" placeholder="Prompt for some starting content" class="w-full mt-4 mb-4"
                        style="resize: none; max-height: 40vh; padding-top: 4em" v-model="command" variant="filled"
                        autofocus />
                    <div style="display: flex; width: 100%; justify-content: flex-end; align-items: flex-end">
                        <Button @click="modifyContent" class="mt-6 mb-4 mr-4">Modify current content</Button>
                        <Button @click="gnererateBrandNewContent" severity="secondary" class="mt-6 mb-4 mr-4">Generate new content</Button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>




<script>
import ApiKey from '@/views/ApiKey.vue';
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu, BubbleMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import { Markdown } from 'tiptap-markdown';
import commands from './custom_extensions/commands';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import CodeBlockComponent from './CodeBlock.vue'
import { lowlight } from 'lowlight'

let extractResponse = function (input) {
    const regex = /<response>([\s\S]*?)<\/response>/;
    const match = input.match(regex);

    if (match && match[1]) {
        return match[1].trim().trimStart().trimEnd();
    }
    return null;
}

export default {
    props: {
        repo: String,
        docsLocation: String,
        title: String,
        content: String
    },
    components: {
        BubbleMenu,
        EditorContent,
        FloatingMenu,
    },
    data() {
        return {
            value: '',
            additionalOptionsValue: null,
            locationValue: 'docs',
            items: ["docs", "blog"],
            loading: false,
            response: null,
            titleValue: "",
            response: null,
            editorOptions: [
                "Edit raw markdown", "Use rich text editor(preview)"
            ],
            editorSelectvalue: 'Edit raw markdown',
            openapiOptions: [],
            openapiRoutes: [],
            draftContent: null,
            additonalContextOptions: [
                { name: "openapi files", value: "openapi files" }
            ],
            editor: null
        }
    },
    async mounted() {
        let component = this
        let items = await this.fetchOpenApi()
        console.log("what is the items", items)
        this.openapiOptions = items
        this.editor = new Editor({
            extensions: [
                StarterKit,
                CodeBlockLowlight
                    .extend({
                        addNodeView() {
                            return VueNodeViewRenderer(CodeBlockComponent)
                        },
                    })
                    .configure({ lowlight }),
                // BubbleMenu.configure({
                //     element: document.querySelector('.menu'),
                // }),
            ], onUpdate: ({ editor }) => {
                //component.draftContent = component.$parser.convertToMarkdown(editor.getHTML())
            }
        })
        this.editor.commands.setContent(`# Hey`)
        this.draftContent = `# Hey`
        if (this.title) {
            this.titleValue = this.title
        }
        if (this.docsLocation) {
            this.locationValue = this.docsLocation
        }
        if (this.content) {
            this.draftContent = this.content
            let html = this.$parser.convertToHTML(this.content)
            this.editor.commands.setContent(html)
        }
    },
    computed: {
        getContentThere() {
            try {
                let markdownContent = ""
                if (this.editorSelectvalue === 'Use rich text editor(preview)') {
                    markdownContent = this.$parser.convertToMarkdown(this.editor.getHTML())
                } else {
                    markdownContent = this.draftContent
                }
                return markdownContent.trim()

            } catch (e) {
                return ""
            }
        },
    },
    watch: {
        additionalOptionsValue: function (val) {
            console.log("what is the value", val)
        },
        openapiRoutes: function (val) {
            console.log("what is the value", val)
        },
        editorSelectvalue(val) {
            if (val === 'Use rich text editor(preview)') {
                let html = this.$parser.convertToHTML(this.draftContent)

                this.editor.commands.setContent(html)
            } else {
                let markdown = this.$parser.convertToMarkdown(this.editor.getHTML())
                this.draftContent = markdown
            }
        }
    },
    methods: {
        search(event) {
            this.items = ["docs", "blog"]
        },
        getContent() {
            try {
                let markdownContent = ""
                if (this.editorSelectvalue === 'Use rich text editor(preview)') {
                    markdownContent = this.$parser.convertToMarkdown(this.editor.getHTML())
                } else {
                    markdownContent = this.draftContent
                }
                return markdownContent.trim()

            } catch (e) {
                return ""
            }
        },
        setContent(content) {
            try {
                this.draftContent = content
                let html = this.$parser.convertToHTML(content)
                this.editor.commands.setContent(html)
            } catch (e) {
                console.log(e)
            }
        },
        async modifyContent() {
            try {
                let content = this.getContent()
                await this.updateBlock({ existingContent: content })
            } catch (e) {
                console.log(e)
            }
        },
        async gnererateBrandNewContent() {
            try {
                await this.updateBlock()
            } catch (e) {
                console.log(e)
            }
        },
        async updateBlock(options = {}) {
            let component = this
            this.loading = true
            let historicalMessage;
            if (options?.existingContent) {
                let existingContentToUse = options?.existingContent
                historicalMessage = `Can you make modifications to using the content below as a reference or starting point:

                <old-content>
                ${existingContentToUse}
                </old-content>

                Now that you have the old content, please follow these instructions to modify:

                ${this.command}
                `
            }
            // this.updateAttributes({ saved: true })
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                let message = `${historicalMessage || this.command}, additionally, please make sure you return only the response and in a markdown format and in <response> </response> tags`
                var raw = JSON.stringify({ message })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/proxy_url/message`, requestOptions)  // can we pass hakiu
                var result = await saveResponseTwo.json()

                let finalCleanedContent = extractResponse(result?.content)
                console.log("what is the before final cleaned content", result?.content)
                console.log("what is the final cleaned content", finalCleanedContent)
                component.setContent(finalCleanedContent)
                console.log("what is the result", result)
                this.loading = false

            } catch (e) {
                console.log(e)
            }
        },
        async createExternalPage(options = {}) {
            // this.record = false
            var myHeaders = new Headers();
            var org = await this.$authInstance.getOrg()
            var token = await this.$authInstance.getToken()
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            let markdownContent = this.getContent()


            let postBody = { content: markdownContent}
            postBody.specific_path = `${this.locationValue.trim()}/${this.titleValue}.md`
            postBody.page = true
            postBody.createBranch = true

            const body = JSON.stringify(postBody);
            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: body,
                redirect: 'follow'
            };

            var url = await this.$authInstance.getBaseUrl()
            var saveResponseTwo = await fetch(`${url}/external_page`, requestOptions)
            var result = await saveResponseTwo.json()
            console.log("what is result head", result?.head?.ref)
            if(result?.head?.ref) this.response = {url: `${result?.head?.repo?.html_url}/tree/${result?.head?.ref}/${postBody.specific_path}`}
            else if(this.repo) this.response = {url: `https://github.com/${this.repo}/branches`}
        },
        async fetchOpenApi() {
            try {
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                let url = await this.$authInstance.getBaseUrl()
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }
                const requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                const response = await fetch(`${url}/openapi`, requestOptions);
                const result = await response.json();
                console.log("what is the result", result)
                return result.configs
            } catch (e) {
                return {}
            }
        }
    }
}

</script>

<style>

</style>
