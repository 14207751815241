import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'

import CommandsList from './CommandsList.vue'

const methods = {
  addMermaidBlock(editor) {
      return editor.commands.insertContent(`<mermaid-block></mermaid-block>`)
  },
  addDraggableCard(editor) {
     return editor.commands.insertContent(`
  <div data-type="draggable-item" style="background: #000">
    <p>Your Content goes here!</p>
  </div>`)
  },
  addGradientText(editor) {
    return editor.commands.insertContent(`<gradient-text type="info" words="Develop. Preview. Ship" style="margin: 0;
    font-size: 4em !important;
    font-weight: 800;
    color: #fff;
    letter-spacing: -0.03em;" title="More Info" class="">
    </gradient-text>`)
  },
  async insertAiBlock(editor) {
      editor.commands.insertContent(`<ai-block></ai-block>`)
  },
  async insertAiTextBlock(editor) {
    editor.commands.insertContent([{
      type: 'aiBlock',
      attrs: {
        text: true
      }
    }])
      
  },
  addList(editor) {
    editor.commands.toggleTaskList()
  },
  insertCardWithColumn(editor) {
      const htmlString = `
      <div class="column-block">
          <div class="column">
              <h1>
                  <span style="color: #ffffff">Guides</span><br>
              </h1>
          </div>
          <div class="column">
              <h1>
                  <span style="color: #ffffff">Guides</span><br>
              </h1>
          </div>
      </div>`
      editor.commands.insertContent(htmlString)
  },

  insertAiBlock(editor) {
      editor.commands.insertContent(`<ai-block></ai-block>`)
      return
  },
  addButton(editor) {
      this.editor.commands.insertContent("<button>hey</button>")
      return
  },
  addIframe(editor) {
    editor.commands.insertContent("<iframe-block></iframe-block>")
    return
  },
  addCodeBlock(editor) {
    return editor.commands.insertContent(`<pre><code>Your code</code></pre>`)
    return
  },
  addCustomCard(editor) {
    return editor.commands.insertContent(`<div class="magic-card"><div class="inside-magic">Hey</div></div>`)
  }
}

export default {
  items: ({ query }) => {
    return [
      {
        title: 'Add AI Block',
        command: ({ editor, range }) => {
          editor
          .chain()
          .focus()
          .deleteRange(range).run()
          return methods.insertAiBlock(editor)
        },
      },
      {
        title: 'Add AI Text Block',
        command: ({ editor, range }) => {
          editor
          .chain()
          .focus()
          .deleteRange(range).run()
          return methods.insertAiTextBlock(editor)
        },
      },
      {
        title: 'Add Aniamtion Text',
        command: ({ editor, range }) => {
          editor
          .chain()
          .focus()
          .deleteRange(range).run()
          return methods.addGradientText(editor)
        },
      },
      {
        title: 'Insert Card',
        command: ({ editor, range }) => {
          editor
          .chain()
          .focus()
          .deleteRange(range).run()
          return methods.addDraggableCard(editor)
        },
      },
      {
        title: 'Add Custom Card',
        command: ({ editor, range }) => {
          editor
          .chain()
          .focus()
          .deleteRange(range).run()
          return methods.addCustomCard(editor)
        },
      },
      {
        title: 'Add Code Block',
        command: ({ editor, range }) => {
          editor
          .chain()
          .focus()
          .deleteRange(range).run()
          return methods.addCodeBlock(editor)
        },
      },
      {
        title: 'Add Iframe',
        command: ({ editor, range }) => {
          editor
          .chain()
          .focus()
          .deleteRange(range).run()
          return methods.addIframe(editor)
        },
      },
      {
        title: 'H1',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('heading', { level: 1 })
            .run()
        },
      },
      {
        title: 'H2',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('heading', { level: 2 })
            .run()
        },
      },
      {
        title: 'H3',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('heading', { level: 3 })
            .run()
        },
      },
      {
        title: 'bold',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setMark('bold')
            .run()
        },
      },
      {
        title: 'italic',
        command: ({ editor, range }) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setMark('italic')
            .run()
        },
      },
      {
        title: 'Insert Mermaid Block',
        command: ({ editor, range }) => {
          editor
          .chain()
          .focus()
          .deleteRange(range).run()
          return methods.addMermaidBlock(editor, range)
        },
      }
    ].filter(item => item.title.toLowerCase().startsWith(query.toLowerCase())).slice(0, 10)
  },

  render: () => {
    let component
    let popup

    return {
      onStart: props => {
        component = new VueRenderer(CommandsList, {
          // using vue 2:
          // parent: this,
          // propsData: props,
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        component.destroy()
      },
    }
  },
}