<template>
    <node-view-wrapper>
        <div class="katex-component" :class="{'is-selected': selected}">
            <div class="katex-component__title">
              <h3>Math Input</h3>
              <a href="#" @click.prevent="deleteNode">Remove</a>
            </div>
            <textarea rows="3" v-model="rawFormula"></textarea>
            <div class="katex-component__formula" v-html="renderedFormula"></div>
        </div>
    </node-view-wrapper>
  </template>

  <script>

import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import katex from 'katex';

export default {
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
  data() {
    return {
      rawFormula: this.node.attrs.content,
      options: {
        throwOnError: false,
        strict: false,
        displayMode: true,
        maxSize: 300
      }
    }
  },
  watch: {
    rawFormula(newVal, val) {
      if (newVal == val) {
        return;
      }

      this.updateAttributes({
        content: newVal,
      })
    }
  },
  computed: {
    renderedFormula() {
      if (!this.rawFormula) {
        return '';
      }

      return katex.renderToString(this.rawFormula, this.options);
    }
  }
}


  </script>

<style lang="css">
  .katex-component {
      display: flex;
      flex-direction: column;
      padding: 10px;
      margin: 5px 0;
      background: #736BF5;
      border: 3px solid #0D0D0D;
      border-radius: 0.5rem;
      margin: 1rem 0;
      position: relative;
  }
  
  .katex-component.is-selected {
      border-color: rgb(243, 245, 247);
  }
  
  .katex-component h3 {
      margin: 0;
  }
  
  .katex-component__title {
      display: flex;
      margin-bottom: 20px;
  }
  
  .katex-component__title a {
      margin-left: auto;
  }
  
  .katex-component__formula {
      margin-top: 10px;
      padding: 10px;
      margin-top: 10px;
      background: rgb(238 230 230) !important;
      padding: 10px;
      border-radius: 1em;
  
  }
  
  .katex-component textarea {
      padding: 10px;
      font-size: 1.2rem;
  }
  </style>
  
    