<template>
    <div class="mt-2 pt-2 pb-2">
        <h3 class="mb-2">Logo</h3>
        <el-upload class="avatar-uploader" :action="apiUrl" :show-file-list="true" :on-success="uploadData"
            :on-preview="handlePictureCardPreview" :headers="{ 'Authorization': 'Bearer ' + token }">
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon"><i class="el-icon-plus"></i></el-icon>
        </el-upload>
    </div>

</template>

<script>


function generateUniqueId() {
    const now = new Date();
    const id = now.toISOString();
    return id;
}

async function getDataUri(imageUrl) {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export default {
    data() {
        return {
            imageUrl: '',
            apiUrl: '',
            token: null,
            imageUrl: null
        }
    },
    async mounted() {
        var baseUrl = await this.$authInstance.getBaseUrl()
        this.token = await this.$authInstance.getToken()
        this.apiUrl = `${baseUrl}/external_images`
        var data = await this.getData()
        
    },
    methods: {

        handlePictureCardPreview(uploadFile) {
            
        },
        async uploadData(response, uploadFile) {
            
            
            var newLogoImage = response.content.path.split("static")[1]
            const component = this
            var myHeaders = new Headers();
                        try {
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var data = {
                    content: { logo: newLogoImage }
                }
                var raw = JSON.stringify({ ...data, path: "logo.json" })

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/theme_json`, requestOptions)
            } catch (e) {
                
            }


        },
        async getData(folderNode) {
            const component = this

            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseOne = await fetch(`${url}/external_page?path=static/img`, requestOptions)
            var jsonResponseOne = await saveResponseOne.json()
            
            var saveResponseTwo = await fetch(`${url}/external_page?path=logo.json`, requestOptions)
            var jsonResponse = await saveResponseTwo.json()
            var docContent = atob(jsonResponse?.docs?.content, 'base64')
            var itemsJson = JSON.parse(docContent)
            const { logo } = itemsJson
            
            var currentImage = jsonResponseOne.docs.find(function (item) { return item.path.includes(logo) })
            this.imageUrl = currentImage.download_url
        },
        beforeAvatarUpload(rawFile) {
            
            if (rawFile.type !== 'image/jpeg') {
                ElMessage.error('Avatar picture must be JPG format!')
                return false
            } else if (rawFile.size / 1024 / 1024 > 2) {
                ElMessage.error('Avatar picture size can not exceed 2MB!')
                return false
            }
            return true
        }
    }
}
</script>

<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}
</style>
