<template>
    <figure class="px-10 pt-10 pb-10">
        <h1 class="text-left">Teams</h1>
    </figure>

    <el-card class="box-card w-80">
        <figure class="pt-2">
            <h3 class="text-left">Invite new user</h3>
        </figure>
        <div class="card-actions justify-end pt-4">
            <button @click="dialogFormVisible = true" class="btn btn-primary">Add User</button>
        </div>
    </el-card>

    <el-card class="box-card mt-10">
        <el-table :data="teams" style="width: 100%">
            <el-table-column label="Name" prop="name" />
            <el-table-column label="Email" prop="email" />
            <el-table-column label="codebases" prop="codebases" />
            <el-table-column align="right">
                <template #header>
                    <el-input v-model="search" size="small" placeholder="Type to search" />
                </template>
                <template #default="scope">
                    <el-button size="small" @click="handleEdit(scope.$index, scope.row)">Edit</el-button>
                    <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">Delete
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>

    <el-dialog v-model="dialogFormVisible" title="Add User">
        <el-form ref="form" :model="form">
            <el-form-item label="name">
                <input v-model="form.name" type="text" placeholder="their full name"
                    class="input input-bordered input-primary w-full max-w-xs" />
            </el-form-item>

            <el-form-item label="Github Username" :label-width="formLabelWidth">
                <input v-model="form.login" placeholder="their github user name"
                    class="input input-bordered input-primary w-full max-w-xs" />
            </el-form-item>
            <el-form-item label="email" :label-width="formLabelWidth">
                <input v-model="form.email" class="input input-bordered input-primary w-full max-w-xs" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Cancel</el-button>
                <el-button @click="addUser()" type="primary">Save</el-button>
            </span>
        </template>
    </el-dialog>

</template>

<script>

// for every foruth element
import { ref, computed } from 'vue'
import {
    ElButton,
    ElTag,
    TableV2FixedDir,
    TableV2SortOrder,
    RowEventHandler
} from 'element-plus'
import { async } from '@firebase/util'



export default {
    data: () => ({
        teams: null,
        form: {
            name: '',
            login: '',
            email: ''
        }
    }),
    setup() {
        const dialogFormVisible = ref(false)
        const search = ref('')
        const filterTableData = computed(() =>
            tableData.filter(
                (data) =>
                    !search.value ||
                    data.name.toLowerCase().includes(search.value.toLowerCase())
            )
        )
      
        const tableData = [
            {
                name: 'Tom',
                teams: ["yeah"],
                codebases: ["cool api", "rails"]
            }]

        return {
            tableData,
            dialogFormVisible,
            filterTableData,
            search
        }
    },
    async mounted() {
        var idtoken = await this.$authInstance.getToken()
        
        await this.getUsers()
    },
    methods: {
        getItem() {
            try {
                
            } catch (e) {
                
            }
        },
        async addUser() {

            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var orgs = await this.$authInstance.getOrgs()
            var org = orgs[0]
            myHeaders.append("Authorization", `Bearer ${idtoken}`);
            myHeaders.append("Content-Type", "application/json");
            this.form.login = this.form.login.trim()
            var raw = JSON.stringify(this.form);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
             var url = await this.$authInstance.getBaseUrl()
            fetch(`${url}/users`, requestOptions)

        },
        async handleDelete(index, row) {
            
            await this.removeUser(row.id)
        },
        async handleEdit(index, row) {
            
        },
        async getUsers() {
            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var baseUrl = await this.$authInstance.getBaseUrl()
            myHeaders.append("Authorization", `Bearer ${idtoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            var teamsRes = await fetch(`${baseUrl}/teams`, requestOptions)
            var teamsJson = await teamsRes.json()
            
            this.teams = teamsJson.data
        },
        async removeUser(id) {
            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var baseUrl = await this.$authInstance.getBaseUrl()
            myHeaders.append("Authorization", `Bearer ${idtoken}`);

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };

            var userRes = await fetch(`${baseUrl}/users?model_id=${id}`, requestOptions)
        }
    }
}
</script>