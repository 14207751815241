import { Node } from '@tiptap/core';

export default Node.create({
  name: 'style',

  group: 'block',

  atom: true,

  content: 'text*',

  addAttributes() {
    return {
      type: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'style' }];
  },

  renderHTML({ HTMLAttributes, text }) {
    return ['style', HTMLAttributes, text];
  },

  addCommands() {
    return {
      setType: (type) => ({ tr, state }) => {
        const { selection } = state;

        if (!selection || !selection.empty) {
          return false;
        }

        tr.replaceSelectionWith(this.type.create({ type }));

        return true;
      },
    };
  },
});
