<template>
    <div id="form" ref="typeformContainer"></div>
</template>

<script>
import { createWidget } from '@typeform/embed';

export default {
    name: 'TypeformWidget',
    props: {
        formId: {
            type: String,
            required: true,
        },
        // Add other Typeform options as props if needed
    },
    data: () => ({

    }),
    mounted() {
        this.initializeTypeform();
    },
    methods: {
        initializeTypeform() {
            let component = this
            createWidget(this.formId, {
                container: this.$refs.typeformContainer,
                onSubmit: ({ formId, responseId }) => {
                    console.log(`Form ${formId} submitted, response id: ${responseId}`)
                    component.handleFormSubmit()
                }
                // Add additional options if needed
            });
        },
        handleFormSubmit(event) {
            console.log('Form Completed!', event);
            // Emit the form-completed event to the parent component
            this.$emit('form-completed', event);
        }
    },
};
</script>

<style>
#form {
    iframe {
        height: 100vh;
        width: 50vw;
    }
}

/* Add any necessary styles */
</style>