<template>
    <node-view-wrapper @mouseover="hover = true" @mouseleave="hover = false" class="ai-block">
        <el-affix v-if="hover && fullRender" position="bottom" target=".ai-block" :offset="100">
            <el-row v-if="hover">
                <el-col class="drag-col" :span="24">
                    <div class="drag-handle" contenteditable="false" draggable="true" data-drag-handle
                        @click="colorPickerDialog = true">
                    </div>

                    <div class="settings" contenteditable="false" draggable="true" data-drag-handle
                        @click="colorPickerDialog = true">
                    </div>
                    <div class="chat" contenteditable="false" draggable="true" data-drag-handle
                        @click="aiElementDialog = true"></div>
                    <div class="trash" contenteditable="false" draggable="true" data-drag-handle @click="destory()"></div>
                    <div class="copy" contenteditable="false" draggable="true" data-drag-handle @click="duplicate()"></div>
                </el-col>
            </el-row>
        </el-affix>

        <div v-if="fullRender != true" class="content">
            <span class="label">AI Block</span>
            <div class="card shadow-xl">
                <div class="card-body">
                    <!-- <el-input v-model="command" placeholder="add command" /> -->
                    <pre @input="handleInput" ref="codeBlock" contenteditable="true"><code></code></pre>
                    <!-- <div @change="testMethod" v-html="output"></div> -->
                    <div class="card-actions justify-end">
                        <el-button v-if="output && isNotText" @click="reRender()">Re Render</el-button>
                        <el-button v-if="output" @click="reset()">reset</el-button>
                        <el-button v-if="output && isNotText" @click="insertRawHtml()">insert Raw Html</el-button>
                    </div>
                </div>
            </div>
        </div>

        <el-button v-if="fullRender != true" @click="sizeToFit">
            Resize
        </el-button>
        <color-picker-dialog :attrs="node?.attrs" v-if="colorPickerDialog" @newheight="setNewHeight" @newwidth="setNewWidth"
      :show="colorPickerDialog" @newimage="setNewBackgroundImage" @glassmorph="setGlassMorph" @newbp="setNewBp"
      @newgradient="setNewColors" @newstyle="setStyle" @close="colorPickerDialog = false" />
        <iframe v-if="isNotText" :style="style" ref="editor"></iframe>
    </node-view-wrapper>
</template>

<script>
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import { CodeBlock } from '@tiptap/extension-code-block'
import StarterKit from '@tiptap/starter-kit'

import CardStyleEditor from '../CardStyleEditor.vue'
import { Codemirror } from 'vue-codemirror'


import ColorPickerDialog from '../ColorPickerDialog.vue';
import AddElementDialog from '../cardCssDrawer.vue';


function cssToVueStyle(cssString) {
  const regex = /([a-zA-Z-]+)\s*:\s*([^;]+)/g;
  const styleObject = {};
  let match;
  while ((match = regex.exec(cssString)) !== null) {
    const propName = match[1].replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
    const propValue = match[2].trim();
    styleObject[propName] = propValue;
  }
  return styleObject;
}



var toParseAble = function (htmlString) {

    var html = htmlString
    const regex = /style\s*=\s*(['"])(.*?)\1/g;
    html = html.replace(regex, "customStyleString=\"$2\"");

    
    return html
}

export default {
    components: {
        NodeViewWrapper, CardStyleEditor, Codemirror, EditorContent, ColorPickerDialog
    },
    props: nodeViewProps,
    data: () => ({
        command: null,
        saved: null,
        output: null,
        hover: false,
        htmlEditor: null,
        initialized: false,
        style: {},
        fullRender: false,
        colorPickerDialog: false,
        aiElementDialog: false,
        loading: false,
        error: false,
        code: ''
    }),
    watch: {
        colorPickerDialog(val) {
            
            if (val) this.editor.setEditable(false)
            else this.editor.setEditable(true)
        },
        addElementDialog(val) {
            
            if (val) this.editor.setEditable(false)
            else this.editor.setEditable(true)
        },
        aiElementDialog(val) {
            
            if (val) this.editor.setEditable(false)
            else this.editor.setEditable(true)
        },
        output(val) {
            var notText = this.isNotText
            

            const codeBlock = this.$refs.codeBlock;
            codeBlock.textContent = val

            if (!notText) return
            const editor = this.$refs.editor;
            const style = `body {
                background: #121220;
            }
            h1, h2, h3, h4, h5, h6, p {
                color: white;
            }
            `
            //const style = Array.from(document.styleSheets).map(function(sheet) { return Array.from(sheet?.rules).flat()}).flat().map(function(item) { return item.cssText}).join()
            const html = `<html><head><style>${style}</style></head><body>${val}</body></html>`;
            editor.contentDocument.open();
            editor.contentDocument.write(html);
            editor.contentDocument.close();
        },
        initialized(val) {

        }
    },
    async mounted() {
        


        
        
        if (this.node.attrs.customHtml) {
            
            this.setHtml(this.node.attrs.customHtml)
        }
        
        if (this.$refs.editor) {
            const iframe = this.$refs.editor
            iframe.addEventListener('load', () => {
                
                iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`
            });

        }

        var component = this
        this.command = this?.node?.attrs?.command
        // 
        this.initialized = true
        // 
        // 
        // if(this.node.attrs.customHtml) {
        //     
        //     this.setHtml(this.node.attrs.customHtml)
        // }
    },
    methods: {
        setNewColors(val) {
            
            const { textColor, background } = val
            this.newColor = background
            this.newTextColor = textColor
            this.updateAttributes({ background: background })
        },
        setNewBp(val) {
            this.bp = val
            
            
            this.updateAttributes({ backgroundPosition: val })
        },
        setNewBackgroundImage(val) {
            
            this.newColor = val.previewUrl
            this.updateAttributes({ background: val.externalImage })
        },
        setNewHeight(val) {
            
            this.newHeight = val
            this.updateAttributes({ height: this.newHeight })
        },
        setNewWidth(val) {
            
            this.newWidth = val
            this.updateAttributes({ width: this.newWidth })
        },
        setStyle(val) {
            
            const style = cssToVueStyle(val)
            this.style = style
            if (val) this.updateAttributes({ customStyleString: val })
            if (val) this.updateAttributes({ customStyle: style })
            const { display, gap, alignContent, alignItems, flexDirection, justifyContent, flexWrap, flexFlow } = style
            if (val) this.nodeViewStyle = { display, gap, alignContent, alignContent, alignItems, flexDirection, justifyContent, flexWrap, flexWrap }
        },
        setGlassMorph(val) {
            this.glassMorph = val
            if (val) this.updateAttributes({ class: "glass-morph" })
            else this.updateAttributes({ class: "" })
        },
        async destory() {
            this.deleteNode()
            return
        },
        async duplicate() {
            const node = this.node.toJSON()
            
            this.editor.commands.insertContent(node)
            return
        },
        async getData(folderNode) {
            const component = this
            const urlValue = `${findStringInParentheses(component.node.attrs.background)}`.replace(/"/g, "").replace(/'/g, "")
            
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseOne = await fetch(`${url}/external_page?path=static/img`, requestOptions)
            var jsonResponseOne = await saveResponseOne.json()
            
            var currentImage = jsonResponseOne.docs.find(function (item) { return item.path.includes(urlValue) })
            
            this.newColor = `url('${currentImage.download_url}') no-repeat center center / cover`
        },
        testMethod() {
            
        },
        sizeToFit() {

            const iframe = this.$refs.editor
            
            
            iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`
        },
        insertRawHtml() {
            this.fullRender = true
            const iframe = this.$refs.editor
            const html = `<html>${iframe.contentDocument.documentElement.innerHTML}</html>`;
            
            this.updateAttributes({ customHtml: html })
        },
        handleInput(e) {
            
            const codeBlock = this.$refs.codeBlock;
            
            
            this.output = codeBlock.textContent
        },
        async reRender() {
            const codeBlock = this.$refs.codeBlock;
            this.output = codeBlock.textContent
        },
        async reset() {
            this.error = false
            this.output = null
        },
        setHtml(htmlString) {
            const editor = this.$refs.editor
            const html = `${htmlString}`;
            editor.contentDocument.open();
            editor.contentDocument.write(html);
            editor.contentDocument.close();
            this.fullRender = true
        }
    },
    computed: {
        isNotText() {
            return this?.node?.attrs?.text == false || this?.node?.attrs?.text == undefined
        }
    }
}
</script>

<style lang="scss">
.ai-block {
    background: transparent !important;
    border: 3px solid #0D0D0D;
    border-radius: 0.5rem;
    margin: 1rem 0;
    position: relative;
    overflow: auto;
}

iframe {
  resize: both;
  overflow: auto;
}

.label {
    margin-left: 1rem;
    background-color: #0D0D0D;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.content {
    margin-top: 1.5rem;
    padding: 1rem;
}
</style>