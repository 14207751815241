<template>
    <el-drawer :append-to-body="true" v-model="colorDialog" direction="rtl" title="Allow Live Plaground"
        class="color-dialog" style="color: white" draggable>
        <h2>Select Repo</h2>
        <select v-if="selectedRepo != 'css'" contenteditable="false" v-model="selectedRepo">
            <option :value="null">
                auto
            </option>
            <option disabled>
                —
            </option>
            <option v-for="(repo, index) in repos" :value="repo" :key="index">
                {{ repo }}
            </option>
        </select>
        <el-input disabled v-model="attrs.language"></el-input>
        <el-input v-model="name" placeholder="What will be the name of the example" />
        <el-input v-model="filename" placeholder="What will the filename be for example 'snippet.js or snippet.py'" />
        <el-button @click="uploadExample()">Update Snippet</el-button>
    </el-drawer>
</template>

<script>

import StyleEditor from './CardStyleEditor.vue'
import { ArrowDown } from '@element-plus/icons-vue'
import ImageSelectorDialog from './imageSelectorDialog.vue'
export default {
    components: {
        StyleEditor, ArrowDown, ImageSelectorDialog
    },
    data: () => ({
        repos: ["python/examples"], colorDialog: false, name: "", filename: ""
    }),
    props: {
        show: Boolean, attrs: Object, type: String, lang: String, code: String
    },
    async mounted() {
        this.url = this?.attrs?.url
        this.colorDialog = this.show
        var baseUrl = await this.$authInstance.getBaseUrl()
        this.token = await this.$authInstance.getToken()
        this.apiUrl = `${baseUrl}/external_images`
        
    },
    methods: {
        async uploadExample() {
            const content = this.code
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()
                

                var raw = JSON.stringify({ user, content: content, filename: this.filename, name: this.name, lang: this.attrs.language })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/snippet`, requestOptions)
                var result = await saveResponseTwo.json()
                
                this.$emit("url", result.url)

            } catch (e) {
                
            }
        }
    },
    unmounted() {
        this.colorDialog = false
    },
    computed: {}
}
</script>

<style lang="scss">
.color-dialog {
    color: white !important;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: white !important;
    }
}

.preview {
    min-width: 400px;
    min-age: 400px;
}

</style>