import Image from '@tiptap/extension-image';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import ResizableImageTemplate from './CustomImage.vue';

export default Image.extend({
	name: 'ResizableImage',
	addAttributes() {
		return {
			...this.parent?.(),

			width: {
				default: null,
				renderHTML: (attributes) => {
					return {
						width: attributes.width
					};
				}
			},

			height: {
				default: 'auto',
				renderHTML: (attributes) => {
					return {
						height: attributes.height
					};
				}
			},
			customStyle: {
				default: null
			},
			customStyleString: {
				default: 'background: transparent'
			},
			external: {
				default: null
			},
			externalImagePath: {
				default: null
			},
			lock: {
				default: false
			},
			isDraggable: {
				default: true,
				renderHTML: (attributes) => {
					return {};
				}
			}
		};
	},
	addNodeView() {
		return VueNodeViewRenderer(ResizableImageTemplate);
	}
});