export var getFileName = function(file) {
    if(!file) return
    
    var fileFragments = file.split("/")
    return fileFragments[fileFragments.length - 1]
}

export var getRepoName = function(repo) {
    var repoFragments = repo.split("/")
    return repoFragments[repoFragments.length - 1].split(".git")[0]
}

