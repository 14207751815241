<template>
    <el-container>
        <el-main ref="generaldoc" :id="repo + '-' + 'general'" class="docsection">
            <div class="card-actions justify-end">
                <div class="dropdown dropdown-end">
                    <label tabindex="0">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="white" viewBox="0 0 24 24"
                            stroke="white" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                    </label>
                    <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                        <li><a @click="generatePDF()">Export to PDF</a></li>
                    </ul>
                </div>
            </div>
            <h2 class="content-header">General Overview of {{ repo }}</h2>
            <div class="elevated-content">


                <read-only :branch="general.branch" :commit="general.commit" v-if="general" :content="general.content"
                    :tags="general.tags" />
            </div>

        </el-main>
    </el-container>
    <!-- <el-container>
        <el-main class="docsection content-section">
            <h1 class="content-header">Context Docs</h1>
            <context :repo="repo" />
        </el-main>
    </el-container> -->
    <!-- <el-container>
        <el-main :id="repo + '-' + 'reference'" class="docsection reference-section">
            <h1 class="content-header">Reference Docs</h1>
            <read-only :branch="reference.branch" :commit="reference.commit" v-if="reference"
                :content="reference.content" />
        </el-main>
    </el-container> -->
</template>

<script>
import Context from './Context.vue'
import ReadOnly from "./ReadOnly.vue"
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas"

import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import CodeBlockComponent from './CodeBlock.vue'
import Focus from '@tiptap/extension-focus'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import VueComponent from '../extensions/gettingStarted'
import ContextBlock from '../extensions/contextBlock'
import Image from '@tiptap/extension-image'
import ResizableImage from './custom_extensions/ImageUpload'
import MermaidBlock from './custom_extensions/MermaidBlock'
import { lowlight } from 'lowlight'

import { ArrowRight } from '@element-plus/icons-vue'
function toPDF(htmlString) {

    // var iframe=document.createElement('iframe');
    // document.body.appendChild(iframe);
    // var iframedoc= iframe.contentDocument||iframe.contentWindow.document;
    // iframedoc.body.innerHTML= htmlString
    try {
        html2canvas(htmlString).then(function (canvas) {
            
            var img = canvas.toDataURL("image/png");
            var doc = new jsPDF();
            var width = doc.internal.pageSize.getWidth();
            var height = doc.internal.pageSize.getHeight();
            doc.addImage(img, 'JPEG', 0, 0, width, height);
            doc.save('test.pdf');
        });

    } catch (e) {
        
    }

    // html2canvas(document.body, {
    //   onrendered: function(canvas) {
    //     
    //     
    //     var img = canvas.toDataURL("image/png");
    //     var doc = new jsPDF();
    //     doc.addImage(img, 'JPEG', 20, 20);
    //     doc.save('test.pdf');
    //   }

    // });
};

var section = document.getElementById("fullDocument")

function printDiv(divId,
    title) {

    let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

    mywindow.document.write(`<html><head><title>${title}</title>`);
    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById(divId).innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
}

export default {
    props: {
        repo: String
    },
    components: {
        Context,
        ReadOnly,
        EditorContent,
        ArrowRight
    },
    async mounted() {
        try {
            await this.getGeneral()
            await this.getReference()
            this.org = await this.$authInstance.this.getOrg()

        } catch (e) {
            
        }

    },
    data: () => ({
        general: null,
        reference: null,
        activeName: 'first',
        editor: null,
        org: ""
    }),
    methods: {
        async generatePDF() {
            this.editor = await this.setEditor()

            var docs = await this.getAllDocs()
            
            //this.editor.commands.insertContent(docs.data[0].content)
            for (const doc of docs.data) {
                this.editor.commands.insertContent(doc.content)
                this.editor.commands.insertContent("<br><br>")
            }
            const html = this.editor.getHTML()
            
            // const docyDiv = document.getElementById('fullDocument')
            var editorHtml = this.$refs.pdf_editor.rootEl
            
            toPDF(editorHtml)
            //var pdf = new jsPDF('p','pt','a4');
            // var canvas = await html2canvas(docyDiv)
            //     
            //     var img = canvas.toDataURL("image/png");
            //     
            //     var pdf = new jsPDF();
            //     var width = pdf.internal.pageSize.getWidth();
            //     var height = pdf.internal.pageSize.getHeight();
            //     pdf.addImage(img, 'JPEG', 0, 0, width, height);
            //     pdf.save('test.pdf');

            // pdf.html(html, {
            //     callback: function (doc) {
            //         pdf.save();
            //     }
            // });
            // toPDF()
        },
        async setEditor() {
            const editor = new Editor({
                extensions: [
                    Document,
                    Paragraph,
                    Text,
                    StarterKit,
                    TaskList,
                    VueComponent,
                    ContextBlock,
                    TaskItem.configure({
                        nested: true,
                    }),
                    ResizableImage.configure({ allowBase64: true, inline: true }),
                    Focus.configure({
                        className: 'has-focus',
                        mode: 'all',
                    }),
                    MermaidBlock.extend({
                        addAttributes() {
                            return {
                                ...this.parent?.(),
                                readonly: {
                                    default: true
                                },
                            }
                        },
                    }),
                    CodeBlockLowlight
                        .extend({
                            addNodeView() {
                                return VueNodeViewRenderer(CodeBlockComponent)
                            },
                        })
                        .configure({ lowlight }),
                ],
                content: ``,
            })

            editor.commands.setContent(this.content)
            editor.setEditable(false)
            return editor
        },
        async getGeneral() {
            
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var url = await this.$authInstance.getBaseUrl()
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            var responseData = await fetch(`${url}/docs?searchKey=filter&searchValue=${this.repo}-general`, requestOptions)
            
            var resJson = await responseData.json()
            
            this.general = resJson.data[0]
        },
        goto(refName) {
            
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo(0, top);
        },
        async getAllDocs() {
            try {
                
                var myHeaders = new Headers();
                var token = await this.$authInstance.getToken()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var url = await this.$authInstance.getBaseUrl()
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                var responseData = await fetch(`${url}/docs?searchKey=repo&searchValue=${this.repo}`, requestOptions)
                
                var resJson = await responseData.json()
                return resJson

            } catch (e) {
                
            }

        },
        async getReference() {
            try {
                
                var myHeaders = new Headers();
                var token = await this.$authInstance.getToken()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var url = await this.$authInstance.getBaseUrl()
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                var responseData = await fetch(`${url}/docs?searchKey=filter&searchValue=${this.repo}-reference`, requestOptions)
                
                var resJson = await responseData.json()
                
                this.reference = resJson.data[0]

            } catch (e) {
                
            }

        }
    }
}
</script>

<style>
el-container {}

.doc-tabs {
    padding-left: 1em;
    border-radius: 0px 0px 10px 0px;
    background-color: #736BF9;
}



.el-tabs__item {
    color: white !important;
}

/* 
.elevated-content {
padding: 2em;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.45);
border-radius: 24px;
} */

.docsection {
    padding-top: 5em !important;
    padding-left: 5em !important;
    border-radius: 0px 10px 10px 0px;
    max-width: 95% !important;
    background-color: #2C2738
}

.pagesection {
    padding-top: 1em !important;
    padding-left: 5em !important;
    border-radius: 0px 10px 10px 0px;
    max-width: 95% !important;
    background-color: #2C2738
}

.headersection {
    padding-left: 5em !important;
    max-width: 100% !important;
    background-color: #2C2738
}

.content-section {
    background-color: #2C2738
}

.reference-section {
    background-color: #2C2738
}

.howto-section {
    background-color: #2C2738
}

.tutorial-section {
    background-color: #2C2738
}
</style>

<!-- <style lang="scss">
ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    p {
        margin: 0;
    }

    li {
        display: flex;

        >label {
            flex: 0 0 auto;
            margin-right: 0.5rem;
            user-select: none;
        }

        >div {
            flex: 1 1 auto;
        }
    }
}

.has-focus {
    border-radius: 3px;
    box-shadow: 1px 0 5px -1px #888;
}

.editor-box {
    padding: 1em;
}

/* Basic editor styles */
.ProseMirror {
    background-color: transparent !important;
    padding-left: 5em;

    h1 {
        font-family: "Inter", sans-serif !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 36px !important;
        line-height: 22px !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: white !important;
    }

    p {
        color: white;
    }

    >*+* {
        margin-top: 0.75em;
    }


    ul,
    ol {
        padding: 0 2rem;
    }

    pre {
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        background: black !important;
        overflow-y: scroll !important;

        code {
            color: inherit;
            padding: 0;
            background: black !important;
            font-size: 0.8rem;
            overflow-y: scroll !important;
        }

        .hljs-comment,
        .hljs-quote {
            color: #616161;
        }

        .hljs-variable,
        .hljs-template-variable,
        .hljs-attribute,
        .hljs-tag,
        .hljs-name,
        .hljs-regexp,
        .hljs-link,
        .hljs-name,
        .hljs-selector-id,
        .hljs-selector-class {
            color: #F98181;
        }

        .hljs-number,
        .hljs-meta,
        .hljs-built_in,
        .hljs-builtin-name,
        .hljs-literal,
        .hljs-type,
        .hljs-params {
            color: #FBBC88;
        }

        .hljs-string,
        .hljs-symbol,
        .hljs-bullet {
            color: #B9F18D;
        }

        .hljs-title,
        .hljs-section {
            color: #FAF594;
        }

        .hljs-keyword,
        .hljs-selector-tag {
            color: #70CFF8;
        }

        .hljs-emphasis {
            font-style: italic;
        }

        .hljs-strong {
            font-weight: 700;
        }
    }
}
</style> -->
