<template>

    <div class="card-actions justify-end pt-4 pb-4">
            <Button style="flex-grow: 0 !important; color: white" @click="addRow()" outlined>
                Add row
            </Button>
            <Button style="flex-grow: 0 !important" @click="sendData()">Save</Button>
        </div>

        <DataTable :value="tableData">
            <Column field="label" header="Label">
                <template #body="{ data, node, column, field, index }">
                    <InputText v-model="data.label" placeholder="Please input" />
                </template>
            </Column>
            <Column field="href" header="href">
                <template #body="{ data, node, column, field, index }">
                    <InputText v-model="data.href" placeholder="Please input" />
                </template>
            </Column>
            <Column field="position" header="Position">
                <template #body="{ data, node, column, field, index }">
                    <SelectButton v-model="data.position" :options="positions" aria-labelledby="basic" />

                </template>
            </Column>
            <Column field="className" header="Class Name">
                <template #body="{ data, node, column, field, index }">
                    <InputText v-model="data.className" placeholder="Please input" />
                </template>
            </Column>
            <Column header="Operations" style="width: 120px">
                <template #body="{ data, node, column, field, index }">
                    <Button link type="primary" size="small" @click.prevent="deleteRow(data)">
                        Remove
                    </Button>
                </template>
            </Column>
        </DataTable>


</template>

<script>

import { ref } from 'vue';


export default {
    data() {
        return {
            search: ref(''),
            positions: ["left", "right"],
            tableData: [
                {
                    type: 'default',
                    label: 'Home',
                    html: '',
                    to: '/home',
                    href: '',
                    prependBaseUrlToHref: 'false',
                    position: 'left',
                    activeBasePath: '/home',
                    activeBaseRegex: '',
                    className: '',
                },
                {
                    type: 'default',
                    label: 'About',
                    html: '',
                    to: '/about',
                    href: '',
                    prependBaseUrlToHref: 'false',
                    position: 'left',
                    activeBasePath: '/about',
                    activeBaseRegex: '',
                    className: '',
                },
                {
                    type: 'default',
                    label: 'Contact Us',
                    html: '',
                    to: '/contact',
                    href: '',
                    prependBaseUrlToHref: 'false',
                    position: 'right',
                    activeBasePath: '/contact',
                    activeBaseRegex: '',
                    className: '',
                },
            ],
        };
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        async sendData() {
            const component = this;
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg();
                var token = await this.$authInstance.getToken();

                myHeaders.append('Content-Type', 'application/json');
                if (token) {
                    myHeaders.append('Authorization', `Bearer ${token}`);
                }

                const cleanedTableData = [];
                for (const tableRow of this.tableData) {
                    var keysToDelete = Object.keys(tableRow).filter(function (key) {
                        return tableRow[key] == '';
                    });
                    for (const keyToDelete of keysToDelete) {
                        delete tableRow[keyToDelete];
                    }
                    let regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
                    var isFullHref = regex.test(tableRow.href);
                    if (!isFullHref) tableRow.prependBaseUrlToHref = true;
                    else tableRow.prependBaseUrlToHref = false;
                    cleanedTableData.push(tableRow);
                }
                var data = {
                    content: { items: [...cleanedTableData] },
                };
                var raw = JSON.stringify({ ...data, path: 'items.json' });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                };
                var url = await this.$authInstance.getBaseUrl();
                var saveResponseTwo = await fetch(`${url}/theme_json`, requestOptions);
            } catch (e) {}
        },
        async getData(folderNode) {
            const component = this;

            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken();
            myHeaders.append('Content-Type', 'application/json');
            if (token) {
                myHeaders.append('Authorization', `Bearer ${token}`);
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };
            var url = await this.$authInstance.getBaseUrl();
            var saveResponseTwo = await fetch(
                `${url}/external_page?path=items.json`,
                requestOptions
            );
            var jsonResponse = await saveResponseTwo.json();

            var docContent = atob(jsonResponse?.docs?.content, 'base64');

            var itemsJson = JSON.parse(docContent);
            console.log("what is the docs content", itemsJson)
            this.tableData = itemsJson.items;
        },
        addRow() {
            var newRow = {
                label: 'About',
                href: '/about',
                prependBaseUrlToHref: 'false',
                position: 'left',
                className: '',
            };
            this.tableData.push(newRow);
        },
        deleteRow(rowData) {
            const index = this.tableData.indexOf(rowData);
            if (index !== -1) {
                this.tableData.splice(index, 1);
            }
        },
    },
};
</script>