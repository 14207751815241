<template>

    <el-card class="box-card">
        <figure class="pt-2">
            <h3 class="text-left">Who are your users?</h3>
        </figure>
        <div class="card-body">
            <el-select v-model="users" multiple filterable allow-create default-first-option :reserve-keyword="false"
                placeholder="Who are you building for?">
                <el-option v-for="item in personas" :key="item" :label="item" :value="item" />
            </el-select>
        </div>
    </el-card>

</template>

<script>

export default {
    data: () => ({
        personas: ['Engineers', 'Dev Operations', 'General Consumers', 'Security Specialists', 'IT admins', 'General Employees'],
        users: []
    }),
    watch: {
        async users(val) {
            this.$emit("users_generated", val)
        }
    },
}
</script>