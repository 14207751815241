<template>
    <ConfirmDialog group="templating">
        <template #message="slotProps">
            <div class="flex flex-col items-center w-full gap-4">
                <figure class="mt-2 mb-2">
                    <h3>If you delete your account there is no going back so make sure you are certain. If you are type
                        the word "confirm" and click Delete Account</h3>
                </figure>
                <FloatLabel class="w-full mt-2 mb-2">
                    <InputText class="w-full" id="delete_confirm" :invalid="deleteConfirm != 'confirm'"
                        v-model="deleteConfirm" />
                    <label for="delete_confirm">Confirm Deletion of account</label>
                </FloatLabel>
            </div>
        </template>
    </ConfirmDialog>
    <div style="display: flex">
        <Card class="mt-10 ml-5" style="width: 25rem; overflow: hidden">

            <template #title>Account actions</template>
            <template #subtitle>Manage your Dev-Docs account</template>
            <template #footer>
                <div class="flex gap-4 mt-1">
                    <Button label="Close Account" @click="showTemplate()" severity="danger" outlined
                        class="w-full" />
                </div>
            </template>
        </Card>
        <Card class="mt-10 ml-5" style="width: 25rem; overflow: hidden">

            <template #title>Org actions</template>
            <template #subtitle>Manage your associated orgs</template>
            <template #footer>
                <div class="flex gap-4 mt-1">
                    <Button label="Close an Org" @click="deleteOrg()" severity="danger" outlined />
                    <a class="p-button" href="/start">Create a New Org</a>
                </div>
            </template>
        </Card>
    </div>

    <el-dialog v-model="closeOrgDialog" title="Unlink Org">
        <el-row>
            <el-col :span="14">
                <el-select v-if="orgs.length > 0" v-model="selectedOrg" placeholder="Select Org">
                    <el-option v-for="org in orgs" :key="org" :label="org" :value="org" />
                </el-select>
            </el-col>
            <el-col :span="4">
                <h3>Once you close an Org follow up with Your Dev-Docs team to kick off full deletetion Process</h3>
                <!-- <el-link href="/start" type="primary">Create Org</el-link> -->
            </el-col>
            <el-col :span="10">

            </el-col>
        </el-row>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closeOrgDialog = false">Cancel</el-button>
                <el-button @click="closeOrg()" type="danger">Remove Org</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>

// for every foruth element
import { ref, computed, onMounted } from 'vue'
import {
    ElButton,
    ElTag,
    TableV2FixedDir,
    TableV2SortOrder,
    RowEventHandler
} from 'element-plus'
import { async } from '@firebase/util'
import Card from 'primevue/card';
import Button from 'primevue/button'

import ConfirmDialog from 'primevue/confirmdialog';

import InputText from 'primevue/inputtext';

import FloatLabel from 'primevue/floatlabel';






let calculatePercentage = function (originalAmount, usedTokens) {

    let difference = originalAmount - usedTokens
    let percentage = (usedTokens / originalAmount) * 100
    return percentage.toFixed(2)
}

export default {
    data: () => ({
        users: null,
        orgs: [],
        deleteConfirm: "",
        orgCreditsSetup: null,
        usedSignUpInputCredits: null,
        usedSignUpOutputCredits: null,
        usedSignUpInputCreditsPercent: null,
        usedSignUpOutputCreditsPercent: null,
        usedOrgInputCredits: null,
        usedOrgOutputCredits: null,
        usedOrgInputCreditsPercent: null,
        usedOrgOutputCreditsPercent: null,
        form: {
            name: '',
            login: '',
            email: ''
        },
        subscriptionTitle: "",
        org: ""
    }),
    components: {
        Card,
        Button,
        ConfirmDialog,
        InputText,
        FloatLabel
    },
    setup() {
        const dialogFormVisible = ref(false)
        const closeOrgDialog = ref(false)
        const selectedOrg = ref("")
        const search = ref('')
        const filterTableData = computed(() =>
            tableData.filter(
                (data) =>
                    !search.value ||
                    data.name.toLowerCase().includes(search.value.toLowerCase())
            )
        )


        const tableData = [
            {
                name: 'Tom',
                teams: ["yeah"],
                codebases: ["cool api", "rails"]
            }]

        return {
            tableData,
            dialogFormVisible,
            filterTableData,
            search,
            closeOrgDialog,
            selectedOrg
        }
    },
    async mounted() {
        var idtoken = await this.$authInstance.getToken()
        this.orgs = await this.$authInstance.getOrgs()
        var org = await this.$authInstance.getOrg()
        this.subscriptionTitle = `Payment`
        this.org = org
        await this.getUser()
        await this.getFreeTrialCredits()

    },
    methods: {
        getItem() {
            try {

            } catch (e) {

            }
        },
        async closeAccount() {
            await this.$authInstance.closeAccount()
        },
        async deleteOrg() {
            this.closeOrgDialog = true
        },
        async setupPayment(options = {}) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var token = await this.$authInstance.getToken()
            var org = await this.$authInstance.getOrg()
            var url = await this.$authInstance.getBaseUrl()

            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            const response = await fetch(`${url}/create-checkout-session`, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({ ...options }),
            });

            const session = await response.json();
            if (session.url) window.location.href = session.url

        },
        async closeOrg() {
            try {
                var myHeaders = new Headers();
                var idtoken = await this.$authInstance.getToken()
                myHeaders.append("Authorization", `Bearer ${idtoken}`);
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({ org: this.selectedOrg });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getUrl();
                const res = await fetch(`${url}/close_org`, requestOptions)
                //await this.$authInstance.logout()
            } catch (e) {

            }
        },
        async addUser() {

            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var orgs = await this.$authInstance.getOrgs()
            var org = orgs[0]
            myHeaders.append("Authorization", `Bearer ${idtoken}`);
            myHeaders.append("Content-Type", "application/json");
            this.form.login = this.form.login.trim()
            var raw = JSON.stringify(this.form);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            fetch(`${url}/users`, requestOptions)

        },
        async handleDelete(index, row) {

            await this.removeUser(row.id)
        },
        async handleEdit(index, row) {

        },
        async getUser() {
            var userinfo = await this.$authInstance.userinfo()

            this.users = [userinfo]
        },
        async showTemplate() {
            let component = this
            console.log(this.$confirm)
            this.$confirm.require({
                group: 'templating',
                header: 'Confirmation',
                message: 'Please confirm to proceed moving forward.',
                icon: 'pi pi-exclamation-circle',
                rejectProps: {
                    label: 'Cancel',
                    icon: 'pi pi-times',
                    outlined: true,
                    size: 'small'
                },
                acceptProps: {
                    label: 'Delete Account',
                    label: 'Delete',
                    severity: 'danger'
                },
                accept: async () => {
                    if (this.deleteConfirm == "confirm") {
                        await component.closeAccount()
                    }
                },
                reject: async () => {
                    component.deleteConfirm = ""
                }
            });
        },
        async getFreeTrialCredits(apiConfig) {
            var myHeaders = new Headers();
            let component = this
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }


                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                let creditsResponse = await fetch(`${url}/credits`, requestOptions)
                let creditsJSON = await creditsResponse.json()
                console.log("what is the credits response", creditsJSON)
                component.usedSignUpInputCredits = creditsJSON.inputCredits
                component.usedSignUpOutputCredits = creditsJSON.outputCredits
                component.usedSignUpInputCreditsPercent = calculatePercentage(2000000, creditsJSON.inputCredits)
                component.usedSignUpOutputCreditsPercent = calculatePercentage(500000, creditsJSON.outputCredits)

                component.usedOrgInputCredits = creditsJSON?.orgInputCredits
                component.usedOrgOutputCredits = creditsJSON?.orgOutputCredits
                component.usedOrgInputCreditsPercent = calculatePercentage(10000000, creditsJSON?.orgInputCredits)
                component.usedOrgOutputCreditsPercent = calculatePercentage(2000000, creditsJSON?.orgOutputCredits)
                // usedOrgInputputCredits: null,
                // usedOrgOutputCredits: null,
            } catch (e) {
                console.log(e)
            }
        },
    }
}
</script>