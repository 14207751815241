<template>
    <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52" style="padding: 1em;">
        <li class="mt-2"><el-button @click="openSettings">Do Somthing</el-button></li>
        <li class="mt-2"><el-button v-if="!hideAi" class="chat-new" contenteditable="false" @click="openAi">Ask Ai
            </el-button></li>
        
        <li class="mt-2">
            <el-button v-if="!hideDelete" class="trash-new" contenteditable="false" draggable="true" data-drag-handle
                @click="destory()">Delete</el-button>
        </li>
        <li class="mt-2"><el-button v-if="!hideCopy" class="copy-new" contenteditable="false" draggable="true"
                data-drag-handle @click="duplicate()"> copy
            </el-button>
        </li>
        <li class="mt-2"><el-button v-if="!hideCopy" class="copy-new" contenteditable="false" draggable="true"
                data-drag-handle @click="layerSelect()"> Select Layer
            </el-button>
        </li>
        <li class="mt-2">
            <el-popover @after-leave="endInteraction" placement="right" :width="400" :visible="showPopup">
                <template #reference>
                    <el-button @click="emitInteraction">size</el-button>
                </template>
                <div class="slider-demo-block">
                    <div class="card-actions justify-end">
                        <el-button @click="showPopup = false">
                            <el-icon>
                                <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728="">
                                    <path fill="currentColor"
                                        d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                                    </path>
                                </svg>
                            </el-icon>
                        </el-button>
                    </div>
                    <el-form>
                        <el-form-item label="height">
                            <el-slider v-model="height" show-input />
                        </el-form-item>
                        <el-form-item label="height options">
                            <el-select v-model="heightOption" class="m-2" placeholder="Select" size="small">
                                <el-option v-for="item in  measureMentFormatOptions" :key="item" :label="item"
                                    :value="item" />
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="slider-demo-block">
                    <el-form>
                        <el-form-item label="width">
                            <el-slider v-model="width" show-input />
                        </el-form-item>
                        <el-form-item label="width options">
                            <el-select v-model="widthOption" class="m-2" placeholder="Select" size="small">
                                <el-option v-for="item in  measureMentFormatOptions" :key="item" :label="item"
                                    :value="item" />
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="slider-demo-block">
                    <el-form>
                        <el-form-item label="z index">
                            <el-slider v-model="zIndex" show-input />
                        </el-form-item>
                    </el-form>
                </div>
            </el-popover>
        </li>
        <li class="mt-2">
            <el-popover @after-leave="endInteraction" placement="right" :width="400" :visible="showColorPopup">
                <template #reference>
                    <el-button @click="emitColorsInteraction">Colors</el-button>
                </template>
                <div class="card-actions justify-end">
                    <el-button @click="showColorPopup = false">
                        <el-icon>
                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728="">
                                <path fill="currentColor"
                                    d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                                </path>
                            </svg>
                        </el-icon>
                    </el-button>
                </div>
                <ColorPopup @color="setColor" />
            </el-popover>
        </li>
        <slot></slot>
    </ul>
</template>
  
<script>


//use like so const updatedCSSString = replaceCSSProperty(cssString, { property: 'height', value: '150px' });

function replaceCSSProperty(cssString, propertiesToUpdate) {
    // Define a regular expression to match the property to update or add
    const regex = new RegExp(`(${propertiesToUpdate.property}\\s*:\\s*[^;]*;)|$`, 'g');

    // Replace the property if it already exists, or add it if it doesn't
    const replacedCSSString = cssString.replace(regex, (match) => {
        if (match) {
            // Property already exists, replace it with the new value
            return `${propertiesToUpdate.property}: ${propertiesToUpdate.value};`;
        } else {
            // Property doesn't exist, add it to the end of the CSS string
            return `${cssString.trim()}\n${propertiesToUpdate.property}: ${propertiesToUpdate.value};`;
        }
    });

    return replacedCSSString;
}

import ColorPopup from './ColorPopup.vue';
// import RichSettingsPopup from './RichSettingsPopup.vue';
// import FlexContainer from './FlexContainer.vue'
import StylePopup from './StylePopup.vue';


export default {
    components: {
        ColorPopup, StylePopup
    },
    props: {
        openSettingsDialog: {
            type: Function,
            required: false
        },
        node: {
            type: Object
        },
        openAiDialog: {
            type: Function,
            required: false
        },
        delete: {
            type: Function,
            required: false
        },
        copy: {
            type: Function,
            required: false
        },
        hideAi: Boolean,
        hideSettings: Boolean,
        hideCopy: Boolean,
        hideDelete: Boolean,
        attrs: Object
    },
    data: () => ({
        height: 0,
        width: 0,
        zIndex: 0,
        color: "#000",
        showPopup: false,
        currentStyle: '',
        widthOption: '%',
        heightOption: '%',
        measureMentFormatOptions: ["vh", "em", "px", "%", "ch", "rem"],
        showColorPopup: false,
        showRichSettings: false
    }),
    watch: {
        height(val) {

            var updatedCss = replaceCSSProperty(this.currentStyle, { property: 'height', value: `${val}${this.heightOption}` })

            this.$emit("newstyle", updatedCss)
        },
        width(val) {

            var updatedCss = replaceCSSProperty(this.currentStyle, { property: 'width', value: `${val}${this.widthOption}` })

            this.$emit("newstyle", updatedCss)
        },
        zIndex(val) {

            var updatedCss = replaceCSSProperty(this.currentStyle, { property: 'z-index', value: `${val}` })
            this.$emit("newstyle", updatedCss)
        },
        color(val) {

            var updatedCss = replaceCSSProperty(this.currentStyle, { property: 'background', value: `${val}` })
            this.$emit("newstyle", updatedCss)
        }

    },
    async mounted() {
        if (this.attrs?.customStyleString) this.currentStyle = this.attrs?.style || this.attrs?.customStyleString
        else this.currentStyle = this.attrs?.style || ""
    },
    methods: {
        openSettings() {

            return this.openSettingsDialog();
        },
        layerSelect() {
            
        },
        updateStyle(val) {
            this.$emit("newstyle", val)
        },
        setColor(val) {
            this.color = val
        },
        openAi() {
            return this.openAiDialog();
        },
        destory() {
            return this.delete()
        },
        duplicate() {
            return this.copy()
        },
        emitInteraction(options = {}) {
            if (options.showRichSettings) this.showRichSettings = true
            else this.showPopup = true
            this.$emit("interaction")
        },
        emitColorsInteraction() {
            this.showColorPopup = true
            this.$emit("interaction")
        },
        endInteraction() {
           if(this.showColorPopup) return
           if(this.showRichSettings) return
           if(this.showPopup) return
           
            this.$emit("endinteraction")
        }
    }
};
</script>
  

<style lang="scss">
.draggable-item {

    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
        color: v-bind('color1')
    }



    .glassmorph {
        background: radial-gradient(100% 359.18% at 0% 0%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.2) 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        ;
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(15px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 20px;
        min-width: 100%;
        min-height: 100%;
        padding: 0.5rem;
    }



    .content {
        flex: 1 1 auto;
    }
}

.label-tester {
    display: flex;
    margin-left: 1rem;
    background-color: white;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
    z-index: 10
}


.settings-new {
    cursor: grab;
    color: white;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 0.5rem;
    background-blend-mode: difference;
}

.chat-new {
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor"  d="m174.72 855.68 130.048-43.392 23.424 11.392C382.4 849.984 444.352 864 512 864c223.744 0 384-159.872 384-352 0-192.832-159.104-352-384-352S128 319.168 128 512a341.12 341.12 0 0 0 69.248 204.288l21.632 28.8-44.16 110.528zm-45.248 82.56A32 32 0 0 1 89.6 896l56.512-141.248A405.12 405.12 0 0 1 64 512C64 299.904 235.648 96 512 96s448 203.904 448 416-173.44 416-448 416c-79.68 0-150.848-17.152-211.712-46.72l-170.88 56.96z"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 0.5rem;
    background-blend-mode: difference;
}

.trash-new {
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 0.5rem;
    background-blend-mode: difference;
}

.copy-new {
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M768 832a128 128 0 0 1-128 128H192A128 128 0 0 1 64 832V384a128 128 0 0 1 128-128v64a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64h64z"></path><path fill="currentColor" d="M384 128a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64V192a64 64 0 0 0-64-64H384zm0-64h448a128 128 0 0 1 128 128v448a128 128 0 0 1-128 128H384a128 128 0 0 1-128-128V192A128 128 0 0 1 384 64z"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 0.5rem;
    background-blend-mode: difference;
}

.drag-handle-new {
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="m186.496 544 41.408 41.344a32 32 0 1 1-45.248 45.312l-96-96a32 32 0 0 1 0-45.312l96-96a32 32 0 1 1 45.248 45.312L186.496 480h290.816V186.432l-41.472 41.472a32 32 0 1 1-45.248-45.184l96-96.128a32 32 0 0 1 45.312 0l96 96.064a32 32 0 0 1-45.248 45.184l-41.344-41.28V480H832l-41.344-41.344a32 32 0 0 1 45.248-45.312l96 96a32 32 0 0 1 0 45.312l-96 96a32 32 0 0 1-45.248-45.312L832 544H541.312v293.44l41.344-41.28a32 32 0 1 1 45.248 45.248l-96 96a32 32 0 0 1-45.312 0l-96-96a32 32 0 1 1 45.312-45.248l41.408 41.408V544H186.496z"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 0.5rem;
    background-blend-mode: difference;
}

.lock-new {
    flex: 0 0 auto;
    position: relative;
    width: 1rem;
    height: 1rem;
    top: 0.3rem;
    margin-right: 0.5rem;
    cursor: grab;
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M224 448a32 32 0 0 0-32 32v384a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V480a32 32 0 0 0-32-32H224zm0-64h576a96 96 0 0 1 96 96v384a96 96 0 0 1-96 96H224a96 96 0 0 1-96-96V480a96 96 0 0 1 96-96z"></path><path fill="currentColor" d="M512 544a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V576a32 32 0 0 1 32-32zm192-160v-64a192 192 0 1 0-384 0v64h384zM512 64a256 256 0 0 1 256 256v128H256V320A256 256 0 0 1 512 64z"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}</style>