<template>
  <node-view-wrapper class="vue-component">
    <span class="label">Reference</span>

    <div class="content">
      <el-row>
        <el-col :span="24">
          <el-input v-model="description" :rows="3" type="textarea" placeholder="add a description" />
        </el-col>
      </el-row>
      <el-row v-if="updatedFiles?.length > 0">
        <el-col :span="24">
          <el-button v-for="(file, index) of updatedFiles" type="primary" @click="toFile(file)">
            Linked to {{ file }}
          </el-button>
        </el-col>
      </el-row>
    </div>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import { getFileName } from "../../helper_functions/friendlyNamesForPaths"
export default {
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
  data: () => ({
    options: [],
    updatedFiles: [],
    description: '',
    value: []
  }),
  watch: {
    value(val) {
      
    }
  },
  async mounted() {
    var component = this
    
    
    this.updatedFiles = this.node.attrs.relativeFiles || []
    this.description = this.node.attrs.description || "Replace with a description of how these references are related."
  },
  methods: {
    async toFile(file) {
      var idString = `${file}`
      document.getElementById(idString).scrollIntoView({
        behavior: "smooth"
      });
      //this.$emit("showclicked", this.node.attrs.file)
    },
    updateTheFiles() {
      this.updateAttributes({
        files: this.value
      })
      this.updateAttributes({
        description: this.description
      })
      this.updatedFiles = this.value
    },
    friendlyName(file) {
      return getFileName(file)
    },
    increase() {
      this.updateAttributes({
        count: this.node.attrs.count + 1,
      })
    },
  },
}
</script>

<style lang="scss">
.vue-component {
  background: #736BF5;
  border: 3px solid #0D0D0D;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;

  .label {
    margin-left: 1rem;
    background-color: #0D0D0D;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
  }
}



.content {
  margin-top: 1.5rem;
  padding: 1rem;
}
</style>