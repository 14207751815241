import Image from "@tiptap/extension-image";
import ResizableImageTemplate from "./ReadOnlyImage.vue";
import { VueNodeViewRenderer } from "@tiptap/vue-3";

export default Image.extend({
  addOptions() {
    return {
      HTMLAttributes: {
        class: null,
        style: null,
      },
      validate: undefined,
    };
  },
  addAttributes() {
    return {
      src: {},
      alt: { default: null },
      width: { default: null },
      height: { default: null },
      style: {
        default: this.options?.HTMLAttributes?.style || "",
      },
    };
  },
  get schema() {
    return {
      draggable: true,
      inline: true,
      group: "inline",
      selectable: true,
    };
  },
  addNodeView() {
    return VueNodeViewRenderer(ResizableImageTemplate);
  },
});
