<template>
  <div class="p-fluid mt-[1.25rem]">
    <form
      class="flex flex-col gap-[1.25rem]"
      @submit.prevent="submitForm"
    >
      <div class="flex flex-col gap-[0.62rem]">
        <label for="repos">Repo(s)</label>
        <MultiSelect
          pt:filterIcon:style="vertical-align: unset !important;"
          id="repos"
          v-model="formData.repos"
          :options="repoOptions"
          optionLabel="name"
          placeholder="Select Repos"
          display="chip"
          class="!bg-[#1c1c1c] !border-none"
        />
      </div>
      <div
        class="flex flex-col gap-[0.62rem]"
      >
        <div>Fetch and use commits by:</div>
        <!-- <SelectButton v-model="value" :options="options" aria-labelledby="basic" /> -->
        <!-- <Dropdown
          v-model="value"
          :options="options"
          placeholder="Select an option"
          class="w-full md:w-14rem !bg-[#1c1c1c]"
          position="bottom"
        /> -->

        <select
          v-model="value"
          class="w-full md:w-14rem bg-[#1c1c1c] p-2 rounded-[0.5rem] border border-gray-700 text-white border-none "
        >
          <!-- <option value="" disabled selected>Select an option</option> -->
          <option v-for="option in options" :key="option" :value="option">
            {{ option }}
          </option>
        </select>


      </div>
      <div v-if="value == 'By Time of Commits'" class="flex flex-col gap-[0.62rem]">
        <label for="timestamp">Fetch and Use Commits Commited by Time</label>
        <InputNumber
          id="timestamp"
          v-model="formData.timestamp"
          showButtons
          suffix=" Minutes Ago"
          :min="0"
          :max="50000"
          class="!bg-[#1c1c1c] !border-none"
        />
      </div>
      <div v-if="value == 'By Number of Last Recent Commits'" class="flex flex-col gap-[0.62rem]">
        <label for="timestamp">Fetch and Use Most Recent Commits by Number</label>
        <InputNumber id="timestamp" v-model="formData.commitNumber" prefix="Last " suffix=" Commits" showButtons
          :min="0" :max="50000" />
      </div>
      <div style="display: flex; flex-direction: column" v-if="value == 'Tag'" class="flex flex-col gap-[0.62rem]">
        <label class="" for="tag">Use commits between release tags</label>
        <label class="" for="tag">Start tag</label>
        <InputText
          id="tag"
          v-model.trim="formData.tag.startTag"
          class="!bg-[#1c1c1c] !border-none"
        />
        <label class="" for="tag">End tag</label>
        <InputText
          id="tag"
          v-model.trim="formData.tag.endTag"
          class="!bg-[#1c1c1c] !border-none"
        />
      </div>


      <div style="display: flex; flex-direction: column;" class="p-field">
        <label class="mt-4 mb-4 p-card-subtitle">Where do you want to create the new Document or Page</label>
        <InputGroup>
          <InputGroupAddon>
            <i class="pi pi-folder"></i>
          </InputGroupAddon>
          <AutoComplete v-model="formData.locationValue" dropdown :suggestions="items" @complete="search" />
        </InputGroup>

      </div>

      <div class="field">
        <label for="someText">Prompt</label>
        <Textarea id="someText" v-model="formData.finalPrompt" rows="5" autoResize />
      </div>

      <Button @click="generateChangeLog" type="submit" label="Submit" />
    </form>
    <Skeleton height="10rem" v-if="loading" fluid />
    <div v-if="changeLog">
      <a :href="changeLog.pullUrl" target="_blank" class="p-card-subtitle">View your new Document or Page</a>
      <ScrollPanel style="width: 100%; height: 200px">
        <pre>
                <code>
                    {{ changeLog.content }}
                </code>
            </pre>
      </ScrollPanel>
    </div>
  </div>
</template>

<script>
import MultiSelect from 'primevue/multiselect';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';


export default {
  props: {
    repo: String,
  },
  components: {
    MultiSelect,
    InputNumber,
    Textarea,
    Button
  },
  data() {
    return {
      repoOptions: [
        { name: 'Option 1', code: 'opt1' },
        { name: 'Option 2', code: 'opt2' },
        { name: 'Option 3', code: 'opt3' },
      ],
      items: ["docs", "blog"],
      repos: [],
      value: 'By Time of Commits',
      options: ['By Time of Commits', 'By Number of Last Recent Commits', 'Tag'],
      loading: false,
      changeLog: null,
      formData: {
        repos: [],
        timestamp: 10,
        commitNumber: 10,
        tag: {},
        locationValue: 'docs',
        finalPrompt: "Please write a customer facing changelog from the commits provided earlier, in terms of new features these code changes cover"
      }
    };
  },
  async mounted() {
    await this.getRepos()
  },
  methods: {
    search(event) {
      this.items = ["docs", "blog"]
    },
    submitForm() {
      console.log('Form submitted:', this.formData);
      // Here you can add logic to send the form data to your backend
    },
    async getRepos() {
      var myHeaders = new Headers();
      try {
        var token = await this.$authInstance.getToken()

        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`)
        }

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        var url = await this.$authInstance.getBaseUrl()
        var response = await fetch(`${url}/repos`, requestOptions)
        var jsonResponse = await response.json()
        this.repos = jsonResponse
        this.repoOptions = jsonResponse.map(repo => {
          return { name: repo.full_name, code: repo.full_name }
        })
      } catch (e) {
        this.repos = []
      }
    },
    async generateChangeLog() {
      this.loading = true
      var myHeaders = new Headers();
      try {
        let githubRepository = this.repo || `team-dev-docs/${org}-dev-docs`
        var token = await this.$authInstance.getToken()

        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`)
        }

        let postData = { ...this.formData }
        postData.repos = postData.repos.map(repo => repo.name)
        if (this.value == 'By Time of Commits') {
          delete postData["commitNumber"]
          delete postData["tag"]
        } else if(this.value == 'Tag') {
          delete postData["commitNumber"]
          delete postData["timestamp"]
        } else {
          delete postData["timestamp"]
          delete postData["tag"]
        }
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(postData),
          redirect: 'follow'
        };
        var url = await this.$authInstance.getBaseUrl()
        var response = await fetch(`${url}/changelog`, requestOptions)
        var jsonResponse = await response.json()
        this.changeLog = { content: jsonResponse.content, pullUrl: `https://github.com/${githubRepository}/pulls` }
        this.loading = false

      } catch (e) {
        this.loading = false
        // this.repos = []
      }
    }
  }
};
</script>

<style scoped>
.card {
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem;
}

.field {
  margin-bottom: 1.5rem;
}

:deep(.p-inputnumber-input) {
  background-color: #1c1c1c !important;
  border: none !important;
}
</style>