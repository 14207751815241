<template>
    <node-view-wrapper class="shell-block">
        <span class="label">Shell Block</span>
        <div class="content">
            <div class="card shadow-xl">
                <div class="card-body">
                    <el-input v-model="command" placeholder="add command" />
                    <h4 v-if="output">{{output}}</h4>
                    <div class="card-actions justify-end">
                        <el-button @click="runCommand()">Run Command</el-button>
                    </div>
                </div>
            </div>
        </div>
    </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
export default {
    components: {
        NodeViewWrapper,
    },
    props: nodeViewProps,
    data: () => ({
        command: null,
        saved: null,
        output: null
    }),
    async mounted() {
        var component = this
        this.command = this?.node?.attrs?.command
    },
    watch: {
        command(val) {
            //this.updateAttributes({ command: val })
           // this.updateAttributes({ saved: true })
        }
    },
    methods: {
        updateBlock() {
           // this.updateAttributes({ saved: true })
        }
    },
}
</script>

<style lang="scss">
.shell-block {
    background: #736BF5;
    border: 3px solid #0D0D0D;
    border-radius: 0.5rem;
    margin: 1rem 0;
    position: relative;
}

.label {
    margin-left: 1rem;
    background-color: #0D0D0D;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.content {
    margin-top: 1.5rem;
    padding: 1rem;
}
</style>