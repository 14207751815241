<template>
    <figure class="px-10 pt-10 pb-10">
        <h2>Settings</h2>
        <h3 class="mt-4 subpage-heading">Manage account and workspace settings</h3>
    </figure>

    <TabView v-model="activeName" class="px-10 demo-tabs" @tab-change="handleTabChange">
        <TabPanel header="Account">
            <h2>Account and org settings</h2>
            <AccountSettings />
        </TabPanel>
        <TabPanel header="API Keys">
            <h2>API Key settings</h2>
            <APIKeySettings />
        </TabPanel>
        <TabPanel header="Billing">
            <h2>Billing settings</h2>
            <BillingSettings />
        </TabPanel>
    </TabView>

</template>
<script>

import AccountSettings from "@/components/AccountSettings.vue";
import WorkplaceSettings from "@/components/WorkplaceSettings.vue";
import APIKeySettings from "@/components/APIKeySettings.vue";
import BillingSettings from "@/components/BillingSettings.vue";

export default {
    data: () => ({
        activeName: null,
    }),
    components: {
        AccountSettings,
        WorkplaceSettings,
        APIKeySettings,
        BillingSettings
    },
    methods: {
        handleTabChange(tab) {
            console.log("Selected tab:", tab);
        }
    }
}
</script>

<style>
/* Your custom styles here */
</style>
