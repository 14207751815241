<template>
<el-dialog :append-to-body="true" v-model="imageDialog" title="Choose Images" class="color-dialog"
        style="color: white">

        <div v-if="images">
            <el-row v-for="(array, index) of imagesInGrid" :gutter="20" v-bind:key="index">
                <el-col style="margin-top: 2em;" v-for="(image, index) of array" :span="12" v-bind:key="index">
                    <el-card @click="getintegration(image)" class="box-card">
                        <img :src="image.download_url" style="height: 200px;" class="image" />
                        <figure class="pt-2">
                            <h2 class="text-left">{{ image.name }}</h2>
                        </figure>
                        <div class="pt-10">
                            <el-button @click="selectImage(image)" type="primary">Select</el-button>
                            <el-button @click="deleteImage(image)" type="danger">Delete</el-button>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>


</el-dialog>
</template>

<script>

var cutItemsBy4 = function (bigarray) {
    var size = 2; var arrayOfArrays = [];
    for (var i = 0; i < bigarray.length; i += size) {
        arrayOfArrays.push(bigarray.slice(i, i + size));
    }
    
    return arrayOfArrays
}


export default {
    props: {
        show: Boolean
    },
    watch: {
        imageDialog(val) {
            if(!val) this.$emit("close")
        }
    },
    data:() => ({
        imageDialog: false,
        images: [],
        imagesInGrid: null
    }),
    methods: {
        async getImages() {
            try {
              var myHeaders = new Headers();
              var idtoken = await this.$authInstance.getToken()
              myHeaders.append("Authorization", `Bearer ${idtoken}`);

              var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
              };
              var baseUrl = await this.$authInstance.getBaseUrl()
              var imageRes = await fetch(`${baseUrl}/external_images`, requestOptions)
              var imagesJson = await imageRes.json()
              
              this.imagesInGrid = cutItemsBy4(imagesJson?.images)
              this.images = imagesJson?.images
            } catch (e) {
              
            }
        }, 
        selectImage(image) {
            this.$emit("select", image)
        },
        async deleteImage(image) {
            
            try {
              var myHeaders = new Headers();
              var idtoken = await this.$authInstance.getToken()
              myHeaders.append("Authorization", `Bearer ${idtoken}`);
              myHeaders.append("Content-Type", "application/json");
              
              var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({path: image?.path})
              };
              var baseUrl = await this.$authInstance.getBaseUrl()
              await fetch(`${baseUrl}/delete_external_image`, requestOptions)
              return
            } catch (e) {
              
            }
            // delete_external_image
        }
    },
    async mounted() {
        this.imageDialog = this.show
        const images = await this.getImages()
    }
}
</script>
