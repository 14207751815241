import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import GettingStartedView from '../views/GettingStartedView.vue'
import DashboardView from '../views/DashboardView.vue'
import CodebasesView from '../views/CodebasesView.vue'
import authInstance from '../plugins/authn'
import UsersView from '../views/UsersView.vue'
import DocView from '../views/DocView.vue'
import HomeView from '../views/HomeView.vue'
import TeamsView from '../views/TeamsView.vue'
import ProfileView from '../views/ProfileView.vue'
import GlobalObjects from '../views/GlobalObjects.vue'
// import Editor from '../views/ExternalEditor.vue'
import StyleEditor from '../views/CssEditor.vue'
// import ExternalDash from '../views/ExternalDocsDash.vue'
import ExternalDash from '../views/ExternalDocsDashboard.vue'
import RevampedDash from '../views/RevampedDashboard.vue'
import NewHome from '../views/NewHome.vue'
import ExternalDocView from '../views/ExternalDocsSection.vue'
import ExternalDocViewPage from '../views/ExternalDocView.vue'
import ExternalLandingPage from '../views/LandingPage.vue'
import Intergrations from '../views/Intergrations.vue'
import ApiKey from '../views/ApiKey.vue'
import ExternalTheme from '../views/ExternalTheme.vue'
import InternalDocPage from '../views/InternalDocPage.vue'
import Analytics from '../views/Analytics.vue'
import RevampedDashboard from '../views/RevampedDashboard.vue'
import OpenApi from '../views/OpenAPI.vue'
import Settings from '../views/Settings.vue'
import NewHomeTwo from '@/views/NewHomeTwo.vue'
import GithubActionSetup from '@/views/GithubActionSetup.vue'
import EditorView from '../views/EditorView.vue'

var auth = new authInstance()

async function guardMyroute(to, from, next, redirectUrl = '') {
  var orgs = await auth.getOrgs()
  var isAuthenticated = await auth.checkAuth()
  console.log("what is is auth", isAuthenticated)
  if (orgs.length < 1 && isAuthenticated != 'unauthenticated') {

    if (redirectUrl) {
      localStorage.setItem('start_redirect', redirectUrl)
    }

    next(`/start`)
  } else if (isAuthenticated != 'unauthenticated' ) {
    next() // allow to enter route
  } else {

    if (redirectUrl) {
      localStorage.setItem('login_redirect', redirectUrl)
    }
    await auth.logout()
    next('/') // go to '/login';
  }
}

const getGuardWithRedirect = (redirectUrl) => {
  return (to, from, next) => {
    guardMyroute(to, from, next, redirectUrl);
  };
};

async function protectGettingStarted(to, from, next) {
  var isAuthenticated = await auth.checkAuth()
  if (isAuthenticated != 'unauthenticated') {
    next() // allow to enter route
  } else {
    await auth.logout()
    next('/') // go to '/login';
  }
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/account',
    name: 'account',
    beforeEnter: guardMyroute,
    component: ProfileView,
  },
  {
    path: '/github_app',
    name: 'github app',
    beforeEnter: getGuardWithRedirect('/github_app'),
    component: GithubActionSetup
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/start',
    name: 'getting started',
    beforeEnter: protectGettingStarted,
    component: GettingStartedView,
  },
  {
    path: '/theme',
    name: 'Theme',
    beforeEnter: protectGettingStarted,
    component: ExternalTheme,
  },
  {
    path: '/personal_api_keys',
    name: 'Api Keys',
    beforeEnter: protectGettingStarted,
    component: ApiKey,
  },
  {
    path: '/settings',
    name: 'Settings',
    beforeEnter: guardMyroute,
    component: Settings
  },
  {
    path: '/dash',
    name: 'dashboard',
    beforeEnter: guardMyroute,
    component: NewHome,
  },
  {
    path: '/dash_var_two',
    name: 'dashboard two',
    beforeEnter: guardMyroute,
    component: NewHomeTwo,
  },
  {
    path: '/users_docs',
    name: 'User Docs',
    beforeEnter: getGuardWithRedirect('/users_docs'),
    component: RevampedDashboard,
  },
  {
    path: '/editor',
    name: 'editor',
    beforeEnter: guardMyroute,
    component: EditorView,
  },
  {
    path: '/users',
    name: 'users',
    beforeEnter: guardMyroute,
    component: UsersView,
  },
  {
    path: '/teams',
    name: 'teams',
    beforeEnter: guardMyroute,
    component: TeamsView,
  },
  {
    path: '/external_docs',
    name: 'external',
    beforeEnter: guardMyroute,
    component: ExternalDash,
  },
  {
    path: '/analytics',
    name: 'analytics',
    beforeEnter: guardMyroute,
    component: Analytics,
  },
  {
    path: '/documentation/:org',
    name: 'published documentation',
    component: ExternalDash,
  },
  {
    path: '/documentation/:org/:section',
    name: 'published section',
    component: ExternalDocView,
  },
  {
    path: '/documentation/:org/:section/:page',
    name: 'published page',
    component: ExternalDocViewPage,
  },
  {
    path: '/external_docs/:section',
    name: 'external section',
    beforeEnter: guardMyroute,
    component: ExternalDocView,
  },
  {
    path: '/external_docs/:section/:page',
    name: 'external page',
    beforeEnter: guardMyroute,
    component: ExternalDocViewPage,
  },
  // {
  //   path: '/external_docs/:filter/edit',
  //   name: 'external edit page',
  //   beforeEnter: guardMyroute,
  //   component: Editor,
  // },
  {
    path: '/intergrations',
    name: 'intergrations',
    beforeEnter: guardMyroute,
    component: Intergrations,
  },
  {
    path: '/openapi',
    name: 'openapi',
    beforeEnter: guardMyroute,
    component: OpenApi,
  },
  {
    path: '/objects',
    name: 'Global Objects',
    beforeEnter: guardMyroute,
    component: GlobalObjects,
  },
  // {
  //   path: '/editor',
  //   name: 'editor',
  //   beforeEnter: guardMyroute,
  //   component: Editor,
  // },
  {
    path: '/landing_page',
    name: 'Landing Page',
    beforeEnter: guardMyroute,
    component: ExternalLandingPage,
  },
  {
    path: '/style_editor',
    name: 'style editor',
    beforeEnter: guardMyroute,
    component: StyleEditor,
  },
  {
    path: '/codebases',
    name: 'codebases',
    beforeEnter: guardMyroute,
    component: CodebasesView,
  },
  {
    path: '/docs/:repo/:id',
    name: 'internal doc',
    component: InternalDocPage,
    beforeEnter: guardMyroute,
  },
  {
    path: '/docs/:id',
    name: 'doc',
    component: DocView,
    beforeEnter: guardMyroute,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
