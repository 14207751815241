<template>
    <el-row v-if="branch">
        <el-col :span="24">
            <el-button type="primary">
                <svg class="h-6 w-6 pr-2" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 448 512">
                    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                        d="M160 80C160 112.8 140.3 140.1 112 153.3V241.1C130.8 230.2 152.7 224 176 224H272C307.3 224 336 195.3 336 160V153.3C307.7 140.1 288 112.8 288 80C288 35.82 323.8 0 368 0C412.2 0 448 35.82 448 80C448 112.8 428.3 140.1 400 153.3V160C400 230.7 342.7 288 272 288H176C140.7 288 112 316.7 112 352V358.7C140.3 371 160 399.2 160 432C160 476.2 124.2 512 80 512C35.82 512 0 476.2 0 432C0 399.2 19.75 371 48 358.7V153.3C19.75 140.1 0 112.8 0 80C0 35.82 35.82 0 80 0C124.2 0 160 35.82 160 80V80zM80 104C93.25 104 104 93.25 104 80C104 66.75 93.25 56 80 56C66.75 56 56 66.75 56 80C56 93.25 66.75 104 80 104zM368 56C354.7 56 344 66.75 344 80C344 93.25 354.7 104 368 104C381.3 104 392 93.25 392 80C392 66.75 381.3 56 368 56zM80 456C93.25 456 104 445.3 104 432C104 418.7 93.25 408 80 408C66.75 408 56 418.7 56 432C56 445.3 66.75 456 80 456z" />
                </svg>
                {{ branch }}
            </el-button>
            <el-button class="commit-button" type="primary">
                <svg class="h-6 w-6 pr-2" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 640 512">
                    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                        d="M476.8 288C461.1 361 397.4 416 320 416C242.6 416 178 361 163.2 288H32C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224H163.2C178 150.1 242.6 96 320 96C397.4 96 461.1 150.1 476.8 224H608C625.7 224 640 238.3 640 256C640 273.7 625.7 288 608 288H476.8zM320 336C364.2 336 400 300.2 400 256C400 211.8 364.2 176 320 176C275.8 176 240 211.8 240 256C240 300.2 275.8 336 320 336z" />
                </svg>
                {{ commit }}
            </el-button>
        </el-col>
    </el-row>

    <el-row v-if="formattedTags.length > 0">
        <el-col :span="24">
            <el-tag v-for="item in formattedTags" :key="item" :type="item" class="mx-1"
                effect="dark" round>
                {{ item }}
            </el-tag>
        </el-col>
    </el-row>

    <div class="editor-box" v-if="editor">

        <editor-content :editor="editor" />
    </div>
</template>

<script>




import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import CodeBlockComponent from './CodeBlock.vue'
import Focus from '@tiptap/extension-focus'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import VueComponent from '../extensions/gettingStarted'
import ContextBlock from '../extensions/contextBlock'
import Image from '@tiptap/extension-image'
import ResizableImage from './custom_extensions/ImageUpload'
import MermaidBlock from './custom_extensions/MermaidBlock'
import ShellBlock from './custom_extensions/shellBlock'
import ReferenceMark from './custom_extensions/ReferenceMark'
import Link from '@tiptap/extension-link'
import editorConfig from '@/editorConfiguration/readerConfig.js'

import {unified} from 'unified'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'


// load specific languages only
import { lowlight } from 'lowlight'

// import javascript from 'highlight.js/lib/languages/javascript'
// lowlight.registerLanguage('javascript', javascript) loool

let processMarkdown = async function(contents) {
    let result = await unified()
  .use(remarkParse) // Parse markdown content to a syntax tree
  .use(remarkRehype) // Turn markdown syntax tree to HTML syntax tree, ignoring embedded HTML
  .use(rehypeStringify) // Serialize HTML syntax tree
  .process(contents)
  return String(result)
}

export default {
    components: {
        EditorContent, FloatingMenu
    },
    props: {
        code: String,
        type: String,
        tags: Array,
        content: Object,
        branch: String,
        commit: String
    },
    data() {
        return {
            editor: null,
            files: [],
            value: '',
            formattedTags: [],
            receivedCodeFile: null,
            remoteRepos: null,
            remoteRepo: null
        }
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this._keyListener);
    },
    watch: {
        files(val) {
            
        },
        async receivedCodeFile(val) {
            if (val) {
                if (val?.length > 0) {
                    this.editor.commands.insertContent(`<pre><code class="language-javascript">${val}</code></pre>`)
                }
            }
        },
        async value(val) {
            
            await this.getFileContents(val)
        }
    },
    async mounted() {
        
        
        if(this.tags && Array.isArray(this.tags)) this.formattedTags = this.tags.filter(function(item) {return !item.includes("git")})
        var component = this
        //window.alert("i am reached")
        this.editor = new Editor(editorConfig)
   
        console.log(typeof this.content)
        let content;
        if(typeof this.content == "string") content = this.$parser.convertToHTML(this.content)
        else content = this.content
        console.log("what is the contents", content)
        this.editor.commands.setContent(content)
        this.editor.setEditable(false)
    },
    methods: {
        async talkToVsCode() {
            if (vscode) {
                var items = await vscode.postMessage({
                    command: 'alert',
                    text: 'looooool'
                })
            }
        },
        async getFileContents(path) {
            if (vscode) {
                await vscode.postMessage({
                    command: 'file',
                    path: path
                })
            }

        },
        async saveContent() {
            var data = this.editor.getJSON()
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {

                const contentAsString = JSON.stringify(data)
                var request_body = { content: contentAsString, file: this.value, repo: this.remoteRepo, user: "andrew", team: "engineering team x", doctype: this.value }
                var raw = JSON.stringify(request_body);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                fetch(`${url}/doc`, requestOptions)

            } catch (e) {
                
            }
        }
    },
    beforeUnmount() {
        this.editor.destroy()
    },
}
</script>

<!-- <style lang="scss">
ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    p {
        margin: 0;
    }

    li {
        display: flex;

        >label {
            flex: 0 0 auto;
            margin-right: 0.5rem;
            user-select: none;
        }

        >div {
            flex: 1 1 auto;
        }
    }
}

.has-focus {
    border-radius: 3px;
    box-shadow: 1px 0 5px -1px #888;
}

.editor-box {
    // padding: 1em;
    padding-left: 1em;
    padding-right: 1em;
}

/* Basic editor styles */
.ProseMirror {
    background-color: transparent !important;
    padding-left: 5em;

    h1 {
        font-family: "Inter", sans-serif !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 36px !important;
        line-height: 22px !important;
        color: white !important;
    }


    h2,
    h3,
    h4,
    h5,
    h6 {
        color: rgba(245, 245, 245, 0.81) !important;
    }

    p {
        color: rgba(245, 245, 245, 0.81) !important;
    }
    

    >*+* {
        margin-top: 0.75em;
    }


    ul,
    ol {
        padding: 0 2rem;
    }

    pre {
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        background: black !important;
        overflow-y: scroll !important;

        code {
            color: inherit;
            padding: 0;
            background: black !important;
            font-size: 0.8rem;
            overflow-y: scroll !important;
        }

        .hljs-comment,
        .hljs-quote {
            color: #616161;
        }

        .hljs-variable,
        .hljs-template-variable,
        .hljs-attribute,
        .hljs-tag,
        .hljs-name,
        .hljs-regexp,
        .hljs-link,
        .hljs-name,
        .hljs-selector-id,
        .hljs-selector-class {
            color: #F98181;
        }

        .hljs-number,
        .hljs-meta,
        .hljs-built_in,
        .hljs-builtin-name,
        .hljs-literal,
        .hljs-type,
        .hljs-params {
            color: #FBBC88;
        }

        .hljs-string,
        .hljs-symbol,
        .hljs-bullet {
            color: #B9F18D;
        }

        .hljs-title,
        .hljs-section {
            color: #FAF594;
        }

        .hljs-keyword,
        .hljs-selector-tag {
            color: #70CFF8;
        }

        .hljs-emphasis {
            font-style: italic;
        }

        .hljs-strong {
            font-weight: 700;
        }
    }
}
</style> -->
