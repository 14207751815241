<template>
    <el-drawer v-if="drawer" v-model="drawer" :title="title" direction="rtl" :before-close="handleClose">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="Setup">
                <el-switch v-model="pat" active-text="Scoped Github Pat" inactive-text="Private Key" />
                <br>
                <el-button v-if="!addFile && !pat" @click="addFile = true" type="primary">Add Private Key</el-button>
                <el-upload v-model:file-list="fileList" :disabled="docusarusRepo.repo?.length <= 1"
                    :name="docusarusRepo.repo" :on-success="uploadSuccess" class="upload-demo" v-if="apiUrl && !pat"
                    :data="{ 'appId': docusarusRepo.appId, 'installationId': docusarusRepo.installationId }"
                    :headers="{ 'Authorization': 'Bearer ' + token }" :action="apiUrl" :on-preview="handlePreview"
                    :on-remove="handleRemove">
                    <!-- <el-input v-if="addFile" minlength="1" :show-word-limit="true" v-model="newFileName"
                        placeholder="new file name" clearable><template #append>
                            <el-button v-if="addFile" type="primary">Click to upload</el-button>
                        </template></el-input> -->
                    <el-form v-if="addFile">
                        <el-form-item label="Repo Name">
                            <el-input v-model="docusarusRepo.repo" />
                        </el-form-item>
                        <el-form-item label="Github App Id">
                            <el-input v-model="docusarusRepo.appId" />
                        </el-form-item>
                        <el-form-item label="Github Installation Id">
                            <el-input v-model="docusarusRepo.installationId" />
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="addFile" type="primary">Click to upload</el-button>
                        </el-form-item>
                    </el-form>
                    <template #tip>
                        <div class="el-upload__tip">
                            Your current OpenAPI Files.
                        </div>
                    </template>
                </el-upload>
                <el-form v-else-if="pat">
                    <el-form-item label="Repo Name">
                        <el-input v-model="docusarusRepoPat.repo" />
                    </el-form-item>
                    <el-form-item label="Personal Access Token">
                        <el-input type="password" v-model="docusarusRepoPat.pat" />
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="saveConfig(docusarusRepoPat)" type="primary">Click to upload</el-button>
                        <el-button v-if="docusarusRepoPat.repo" @click="deleteConfig()" type="primary">Delete Github Integration</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="GPT Github setup">
                <el-button v-if="!addFile" @click="addFile = true" type="primary">Add new File</el-button>
                <el-upload v-model:file-list="gptFileList" :disabled="gptRepo.repo?.length <= 1" :name="gptRepo.repo"
                    :on-success="uploadSuccess" class="upload-demo" v-if="gptApiUrl"
                    :headers="{ 'Authorization': 'Bearer ' + token }"
                    :data="{ 'appId': gptRepo.appId, 'installationId': gptRepo.installationId }" :action="gptApiUrl"
                    :on-preview="handlePreview" :on-remove="handleRemove">
                    <el-form v-if="addFile" label-position="top" label-width="100px" :model="gptRepo">

                        <el-form-item label="Repo Name">
                            <el-input v-model="gptRepo.repo" />
                        </el-form-item>
                        <el-form-item label="Github App Id">
                            <el-input v-model="gptRepo.appId" />
                        </el-form-item>
                        <el-form-item label="Github Installation Id">
                            <el-input v-model="gptRepo.installationId" />
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="addFile" type="primary">Click to upload</el-button>
                        </el-form-item>
                    </el-form>
                    <template #tip>
                        <div class="el-upload__tip">
                            Your current OpenAPI Files.
                        </div>
                    </template>
                </el-upload>
                <el-card>
                    <el-descriptions v-if="chatCreated" title="Chat Url Setup Info" direction="vertical" border>
                        <el-descriptions-item label="Setup">{{ chatCreated }}</el-descriptions-item>
                        <el-descriptions-item label="Url Status">Operational</el-descriptions-item>
                    </el-descriptions>
                    <!-- <el-button @click="repo_name()" type="primary">Create Chat Server</el-button> -->
                    <el-button @click="addToDocs()">Add to Your Dev-Docs Template</el-button>
                </el-card>

                <el-form label-position="top" label-width="100px" :model="chatRepo">

                    <el-form-item label="Repo Name">
                        <el-input v-model="chatRepo.repo" />
                    </el-form-item>
                    <el-form-item label="Owner">
                        <el-input v-model="chatRepo.repo_owner" />
                    </el-form-item>
                    <el-form-item label="Docs Path">
                        <el-input v-model="chatRepo.folder" />
                    </el-form-item>
                    <el-form-item>
                        <el-button v-if="addFile" type="primary">Click to upload</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="setupChat()" type="primary">Sync docs to the chatbot</el-button>
                        <el-button v-if="chatCreated" @click="resetChat()" type="danger">Clear all data For chat</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="Live playground examples">
                <figure v-if="playgroundGithubUrl" class="mt-2">
                    <a :href="playgroundGithubUrl" class="mt-4" type="primary" target="_blank">Your playground repo</a>
                </figure>
                <br>
                <figure v-if="playgroundGithubUrl">
                    <a :href="playgroundCodespacesUrl">Codespace URL</a>
                </figure>
                <el-skeleton class="mt-4" v-else :rows="1" animated />
                <el-button class="mt-4" @click="createPlaygroundGithub()">Create example playground</el-button>
            </el-tab-pane>
        </el-tabs>
    </el-drawer>
    <el-dialog>

    </el-dialog>
</template>

<script>
import CodebaseCards from './CodebaseIntegrationCards.vue'
import { ElNotification } from 'element-plus'

export default {
    props: {
        open: Boolean,
        title: String,
        reference: String
    },
    components: {
        CodebaseCards
    },
    methods: {
        async getIntegrations() {
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_githubs?decrypt=yes`, requestOptions)

                var response = await saveResponseTwo.json()

                var integration = response?.docs[0]

                if (!integration?.appId && !integration?.installationId) this.docusarusRepoPat = { repo: integration?.content?.repo, pat: integration?.content?.content }
                //this.form_data = {url: integration?.url, user: integration?.user, api_token: integration?.api_token || "set"}
            } catch (e) {

            }

        },
        closeDialog() {
            this.$emit("closeapi")
        },
        async uploadSuccess(response, uploadFile, uploadFiles) {


        },
        async addToDocs() {
            const chatUrl = this.chatUrl
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var data = {
                    content: { chatUrl: chatUrl }
                }
                var raw = JSON.stringify({ ...data, path: "chat.json" })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/theme_json`, requestOptions)
            } catch (e) {

            }
        },
        async setupChat() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()
                var org = await this.$authInstance.getOrg()

                const formData = this.chatRepo

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                myHeaders.append("Content-Type", "application/json");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify(formData)
                };
                console.log(this.chatRepo)
                var url = await this.$authInstance.getBaseUrl()
                let chatBaseServer = `https://u43zkrn3fm.us-east-1.awsapprunner.com`
                if(url.includes("http://localhost")) chatBaseServer = "http://localhost:4000"
                var saveResponse = await fetch(`${chatBaseServer}/${org}/update_collection_github`, requestOptions)
                var jsonResponse = await saveResponse.json()
                window.location.reload()
            } catch (e) {

            }
        },
        async resetChat() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var token = await this.$authInstance.getToken()
                var org = await this.$authInstance.getOrg()

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                myHeaders.append("Content-Type", "application/json");

                var requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                let chatBaseServer = `https://u43zkrn3fm.us-east-1.awsapprunner.com`
                if(url.includes("http://localhost")) chatBaseServer = "http://localhost:4000"
                var saveResponse = await fetch(`${chatBaseServer}/${org}/delete_collection`, requestOptions)
                // var jsonResponse = await saveResponse.json()
                ElNotification({
                    title: 'Chat Data Deleted',
                    message: "Your Documents were removed from the Chatbot",
                    position: 'bottom-right'
                })
            } catch (e) {
                console.log(e)
            }
        },
        async refreshDocs() {

        },
        async getAPIConfigs() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/external_githubs`, requestOptions)
                var saveResponseOne = await fetch(`${url}/external_githubs?gpt=yes`, requestOptions)
                var jsonResponse = await saveResponseTwo.json()
                var jsonResponseOne = await saveResponseOne.json()
                return { github_docs: jsonResponse, github_gpts: jsonResponseOne }
            } catch (e) {

            }

        },
        async saveConfig(apiConfig) {
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var raw = JSON.stringify(apiConfig);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_githubs`, requestOptions)
            } catch (e) {

            }
        },
        async deleteConfig() {
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }


                var requestOptions = {
                    method: 'delete',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_githubs`, requestOptions)
                let result = await saveResponseTwo.json()
                if(result.message == "successfully deleted") window.location.reload()
            } catch (e) {

            }
        },
        async createPlaygroundGithub() {


            // this.updateAttributes({ saved: true })
            // if (this.connectedGitUrl) this.showConnectedGithub = true
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()


                var raw = JSON.stringify({ user })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/fork_playground`, requestOptions)
                var result = await saveResponseTwo.json()
                console.log(result)
                this.playgroundGithubUrl = `https://github.com/team-dev-docs/${org}-dev-docs-playground`
                this.playgroundCodespacesUrl = `https://codespaces.new/team-dev-docs/${org}-dev-docs-playground?devcontainer_path=.devcontainer%2Fdevcontainer.json`
                this.showConnectedGithub = true
            } catch (e) {
                console.log(e)
                //this.playgroundGithubUrl = `https://github.com/team-dev-docs/${org}-dev-docs-playground`
            }

        },
        async saveContent() {

            var data = this.editor.getJSON()

            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                const contentAsString = JSON.stringify(data)
                var request_body = { content: contentAsString, title: this.title, tags: this.tags || [], user: org, section: this.section }

                var raw = JSON.stringify(request_body);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_styling`, requestOptions)
            } catch (e) {

            }
        },

        handleClose() {
            this.$emit("reset")
        },
        async checkChatSetup() {
            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                let chatBaseServer = `https://u43zkrn3fm.us-east-1.awsapprunner.com`
                if(url.includes("http://localhost")) chatBaseServer = "http://localhost:4000"
                var saveResponse = await fetch(`${chatBaseServer}/${org}/get_collection`, requestOptions)
                const result = await saveResponse.json()

                this.chatCreated = result?.created
                this.chatUrl = `https://u43zkrn3fm.us-east-1.awsapprunner.com/v1/${org}/query`
                this.code = this.getCode(this.chatUrl)
                return result
            } catch (e) {

            }

        },
        copyToClipboard() {
            const codeBlock = document.querySelector('.code-block');
            const codeSnippet = codeBlock.querySelector('code');

            // create a temporary textarea to copy the code snippet
            const tempTextarea = document.createElement('textarea');
            tempTextarea.value = codeSnippet.textContent;
            document.body.appendChild(tempTextarea);

            // select and copy the contents of the textarea
            tempTextarea.select();
            document.execCommand('copy');

            // remove the temporary textarea
            document.body.removeChild(tempTextarea);
        },
        getCode(url) {
            // const code = `
            // curl --location --request POST '${url}' \
            // --header 'Content-Type: application/json' \
            // --data-raw '{
            //     "key1": "value1",
            //     "key2": "value2"
            // }'
            // `
            // return code
            return
        },
        async checkForExistingPlaygroundGithub() {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()

                var requestOptions = {
                    method: 'get',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/created_playground_repo?username=${user.name}`, requestOptions)

                var result = await saveResponseTwo.json()
                console.log(result)
                if(result.statusCode == 500) throw Error("does not exist")

                this.playgroundGithubUrl = result?.clone_url
                this.playgroundCodespacesUrl = `https://codespaces.new/team-dev-docs/${org}-dev-docs-playground?devcontainer_path=.devcontainer%2Fdevcontainer.json`
            } catch (e) {

            }

        },
        async submitFormData() {

            var postBodyObject = this.form_data



            var myHeaders = new Headers();
                   try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var raw = JSON.stringify({ name: this.title, ...postBodyObject });
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_githubs`, requestOptions)
            } catch (e) {

                return
            }
        },

    },
    data: () => ({
        drawer: null,
        formItems: null,
        playgroundGithubUrl: null,
        playgroundCodespacesUrl: null,
        pat: true,
        form_data: { user: "boogie" },
        dialogFormVisible: false,
        editor: null,
        apiUrl: null,
        chatCreated: false,
        chatUrl: null,
        code: null,
        chatRepo: {
            repo_owner: "",
            repo: "",
            folder: ""
        },
        gptApiUrl: null,
        docusarusRepoPat: {
            pat: "",
            repo: ""
        },
        docusarusRepo: {
            appId: "123",
            installationId: "123",
            repo: ""
        },
        gptRepo: {
            appId: "123",
            installationId: "123",
            repo: ""
        },
        token: null,
        addFile: false,
        headers: null,
        newFileName: "",
        fileList: [],
        gptFileList: [],
        form_schemas: {
            "github":
                [
                    { title: "GitHub Secret", details: "", type: "password", key: "api_token", value: "" },
                    { title: "Github Repo", details: "", type: "text", key: "repo", value: "" }
                ]
        }
    }),
    async mounted() {

        var org = await this.$authInstance.getOrg()
        this.chatRepo.repo_owner = "team-dev-docs"
        this.chatRepo.repo = `${org}-dev-docs`
        this.chatRepo.folder = "/docs"
        this.formItems = this.form_schemas[this.title]
        await this.getIntegrations()
        this.drawer = this.open
        var baseUrl = await this.$authInstance.getBaseUrl()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        var token = await this.$authInstance.getToken()
        // var user = this.$authInstance.getUserInfo()

        if (token) {
            this.token = token
        }
        await this.checkForExistingPlaygroundGithub()
        this.apiUrl = `${baseUrl}/external_githubs`
        this.gptApiUrl = `${baseUrl}/external_github_gpts`
        var configsObject = await this.getAPIConfigs()
        var chatIsSetup = await this.checkChatSetup()


        this.fileList = configsObject?.github_docs?.docs
        this.gptFileList = configsObject?.github_gpts?.docs
        // if(chatIsSetup) return

    },
    unmounted() {
        this.$emit("reset")
    }
}
</script>