<template>
    <editor />
</template>
<script>


import Editor from "@/components/EditLandingPage.vue";
export default {
    components: {
        Editor
    }
}
</script>