<template>
  <div class="sections-holder">
    <figure class="px-10 pt-10 pb-10">
      <h1 class="text-left">Analytics</h1>
    </figure>
    <div class="external-docs-grid">
      <figure class="px-10 pt-10 pb-10">
        <h1 class="text-left">Playgrounds</h1>
      </figure>
      <!-- <el-button @click="resetDateFilter">reset date filter</el-button>
      <el-button @click="clearFilter">reset all filters</el-button> -->
      <el-button @click="dialogVisible = true">Chat with Data</el-button>
      <el-table v-if="labels.length > 0" ref="tableRef" row-key="date" :data="tableData" style="width: 100%">
        <!-- <el-table-column prop="date" label="Date" sortable width="180" column-key="date" :filters="[
          { text: '2016-05-01', value: '2016-05-01' },
          { text: '2016-05-02', value: '2016-05-02' },
          { text: '2016-05-03', value: '2016-05-03' },
          { text: '2016-05-04', value: '2016-05-04' },
        ]" :filter-method="filterHandler" /> -->
        <el-table-column v-for="(label, index) of labels" :prop="label" :label="label" width="180" />
        <!-- <el-table-column prop="address" label="Address" :formatter="formatter" /> -->

        <!-- <el-table-column prop="tag" label="Tag" width="100" :filters="[
          { text: 'Home', value: 'Home' },
          { text: 'Office', value: 'Office' },
        ]" :filter-method="filterTag" filter-placement="bottom-end">
          <template #default="scope">
            <el-tag :type="scope.row.tag === 'Home' ? '' : 'success'" disable-transitions>{{ scope.row.tag }}</el-tag>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
  </div>
  <el-dialog v-if="dialogVisible" v-model="dialogVisible" :fullscreen="true">
    <ChatUi :info="JSON.stringify(this.tableData)" :prompt="'Answer Questions about the text'" :welcome="'What do you want to know about the metrics?'" />
  </el-dialog>
</template>
  
<script>

var setUpKeysAsLabels = function(items, order, keyToTitleMap, keysToHide) {
  if (!items || items.length === 0) return [];
  
  // Extract keys from the first item and filter out the ones to hide or already in the order array
  let keys = Object.keys(items[0]).filter(key => !order.includes(key) && !keysToHide.includes(key));
  
  // Combine the order array with the remaining keys
  let combinedKeys = [...order, ...keys];

  // Replace keys with titles using the keyToTitleMap, or use the key itself if no title is provided
  let labels = combinedKeys.map(key => keyToTitleMap[key] || key);
  
  // Finally, filter out any keys that are meant to be hidden
  labels = labels.filter(label => !keysToHide.includes(label));

  return labels;
}

import ChatUi from "@/components/ChatUi.vue"

export default {
  components: {
    ChatUi
  },
  data() {
    return {
      labels: [],
      dialogVisible: false,
      tableData: [
        {
          date: '2016-05-03',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
          tag: 'Home',
        },
        {
          date: '2016-05-02',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
          tag: 'Office',
        },
        {
          date: '2016-05-04',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
          tag: 'Home',
        },
        {
          date: '2016-05-01',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
          tag: 'Office',
        },
      ]
    }
  },

  methods: {
    async chatWiththeData() {

    },
    async fetchMetrics() {
      try {
        const myHeaders = new Headers();
        const idtoken = await this.$authInstance.getToken()
        myHeaders.append("Authorization", `Bearer ${idtoken}`);

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        const url = await this.$authInstance.getBaseUrl()
        const response = await fetch(`${url}/step_metrics`, requestOptions)
        const result = await response.json()
        console.log(result)
        return result.data
        // this.stepMetrics = result
      } catch (e) {
        console.error(e);
      }
    },

    resetDateFilter() {
      if (this.tableRef) {
        this.tableRef.clearFilter(['date'])
      }
    },

    clearFilter() {
      if (this.tableRef) {
        this.tableRef.clearFilter()
      }
    },

    formatter(row, column) {
      return row.address
    },

    filterTag(value, row) {
      return row.tag === value
    },

    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    }
  },

  async mounted() {
    const result = await this.fetchMetrics()
    var items = [{ name: 'Alice', age: 25, email: 'alice@example.com' }];
var order = ['tutorial', 'step']; // The desired order
var keyToTitleMap = { 
  code_ran: 'ran code'
};
    this.labels = setUpKeysAsLabels(result, order, {}, ["code", "code_ran", "id", "codespace"])
    console.log("this is the labels", this.labels)
    this.tableData = result
  },

  // ... Other options like computed properties, watchers, etc.
}
</script>
  