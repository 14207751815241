<template>
    <TabView v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <TabPanel header="Bring your own repo">
            <div>
                <br>
                <Button v-if="!addFile && !pat" @click="addFile = true" type="primary">Add Private Key</Button>
                <FileUpload v-model="fileList" :disabled="docusarusRepo.repo?.length <= 1" :name="docusarusRepo.repo"
                    :on-success="uploadSuccess" class="upload-demo" v-if="apiUrl && !pat"
                    :data="{ 'appId': docusarusRepo.appId, 'installationId': docusarusRepo.installationId }"
                    :headers="{ 'Authorization': 'Bearer ' + token }" :url="apiUrl" :on-preview="handlePreview"
                    :on-remove="handleRemove">
                    <!-- <InputText v-if="addFile" minlength="1" :show-word-limit="true" v-model="newFileName"
                        placeholder="new file name" clearable><template #append>
                            <Button v-if="addFile" type="primary">Click to upload</Button>
                        </template></InputText> -->
                    <Form v-if="addFile">
                        <FormItem label="Repo Name">
                            <InputText v-model="docusarusRepo.repo" />
                        </FormItem>
                        <FormItem label="Github App Id">
                            <InputText v-model="docusarusRepo.appId" />
                        </FormItem>
                        <FormItem label="Github Installation Id">
                            <InputText v-model="docusarusRepo.installationId" />
                        </FormItem>
                        <FormItem>
                            <Button v-if="addFile" type="primary">Click to upload</Button>
                        </FormItem>
                    </Form>
                    <template #tip>
                        <div class="el-upload__tip">
                            Your current OpenAPI Files.
                        </div>
                    </template>
                </FileUpload>
                <div class="flex flex-col items-start gap-2 pat card" v-else-if="pat">
                    <label for="repo">Repo</label>
                    <AutoComplete style="width: 100%; flex-grow: 1" v-model.trim="docusarusRepoPat.repo"
                        :invalid="!isValidGitHubRepoName(docusarusRepoPat.repo)" dropdown :suggestions="repoItems"
                        @complete="search" fluid />
                    <Message v-if="!isValidGitHubRepoName(docusarusRepoPat.repo)" severity="error">
                        Please enter a valid GitHub repo name such as 'user/repo'
                    </Message>   

                    <label for="password">Scoped personal access token</label>
                    <div style="width: 100%" v-if="hasGithubAppSetup">
                        <SelectButton v-model.trim="patValue" :options="patOptions" aria-labelledby="basic" />
                        <Password v-if="patValue == 'Custom Personal Access Token'" class="w-full"
                            v-model="docusarusRepoPat.pat" inputId="password" fluid />
                    </div>
                    <Password v-else class="w-full" v-model="docusarusRepoPat.pat" inputId="password" fluid />


                    <div style="display: inline-flex; gap: 1rem; margin-top: 1rem;">
                        <Button @click="saveConfig(docusarusRepoPat)" type="primary">Click to upload</Button>
                        <Button severity="danger" v-if="docusarusRepoPat.pat || docusarusRepoPat.repo"
                            @click="deleteConfig()" type="primary">Delete GitHub
                            Integration</Button>

                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel header="GPT GitHub setup">
            <div style="display: flex; flex-direction: row;" class="gap-2">
                <Tag v-if="chatCreated" severity="success" value="Success">Chatbot data is synced</Tag>
                <Tag v-else severity="danger" value="Success">No chatbot data found</Tag>
                <ToggleButton v-model="chatbotOn" onLabel="Chatbot enabled" offLabel="Chatbot disabled" />
            </div>
                <div class="flex flex-col items-start gap-2 pat card">
                    <label for="username">Repo</label>
                    <InputText v-model="chatRepo.repo" fluid />

                    <label for="username">Repo owner name</label>
                    <InputText v-model="chatRepo.repo_owner" fluid />

                    <label for="username">Folder</label>
                    <InputText v-model="chatRepo.folder" fluid />


                    <Button v-if="addFile" type="primary">Click to upload</Button>
                    <div class="inline-flex display:">
                        <Button @click="setupChat()" type="primary">Sync docs to the chatbot</Button>
                        <Button v-if="chatCreated" @click="resetChat()" type="danger">Clear all data For chat</Button>
                    </div>

                </div>
        </TabPanel>
        <TabPanel header="Live playground examples">
            <figure v-if="playgroundGithubUrl" class="mt-2">
                <a :href="playgroundGithubUrl" class="mt-4" type="primary" target="_blank">Your playground repo</a>
            </figure>
            <br>
            <figure v-if="playgroundGithubUrl">
                <a :href="playgroundCodespacesUrl">Codespace URL</a>
            </figure>
            <Skeleton class="mt-4" v-else :rows="1" animated />
            <Button class="mt-4" @click="createPlaygroundGithub()">Create example playground</Button>
        </TabPanel>
    </TabView>
</template>

<script>

import CodebaseCards from './CodebaseIntegrationCards.vue'
import { ElNotification } from 'element-plus'
import Button from 'primevue/button';
export default {
    props: {
        open: Boolean,
        title: String,
        reference: String,
        chatSettings: Object
    },
    components: {
        CodebaseCards, Button
    },
    watch: {
        chatRepo: {
            handler: function (newVal, oldVal) {
                console.log('User object changed:', newVal, oldVal);
                this.$emit("updatechat", newVal)
            },
            deep: true
        }
    },
    methods: {
        search(event) {
            this.repoItems = this.repos.filter(repo => repo.full_name.includes(event.query)).map(repo => repo.full_name)
        },
        isValidGitHubRepoName(repoName) {
            // Regular expression to match GitHub repo name format
            const githubRepoRegex = /^[a-zA-Z0-9_.-]+\/[a-zA-Z0-9_.-]+$/;

            // Test the repoName against the regex
            return githubRepoRegex.test(repoName);
        },
        async getIntegrations() {
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_githubs?decrypt=yes`, requestOptions)

                var response = await saveResponseTwo.json()

                var integration = response?.docs[0]

                if (!integration?.appId && !integration?.installationId) this.docusarusRepoPat = { repo: integration?.content?.repo, pat: integration?.content?.content }
                //this.form_data = {url: integration?.url, user: integration?.user, api_token: integration?.api_token || "set"}
            } catch (e) {

            }

        },
        closeDialog() {
            this.$emit("closeapi")
        },
        async uploadSuccess(response, uploadFile, uploadFiles) {


        },
        async addToDocs() {
            const chatUrl = this.chatUrl
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var data = {
                    content: { chatUrl: chatUrl }
                }
                var raw = JSON.stringify({ ...data, path: "chat.json" })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/theme_json`, requestOptions)
            } catch (e) {

            }
        },
        async getRepos() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var response = await fetch(`${url}/repos`, requestOptions)
                var jsonResponse = await response.json()
                console.log(jsonResponse)
                this.repos = jsonResponse
                this.repoItems = jsonResponse.map(repo => repo.full_name)
                console.log("this is the repo item", this.repoItems)
                this.hasGithubAppSetup = jsonResponse.length > 0

                // this.$emit("customrepo", { github_docs: jsonResponse, github_gpts: jsonResponseOne })
                // return { github_docs: jsonResponse, github_gpts: jsonResponseOne }
            } catch (e) {
                this.repos = []
            }

        },
        async setupChat() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()
                var org = await this.$authInstance.getOrg()

                const formData = this.chatRepo

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                myHeaders.append("Content-Type", "application/json");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify(formData)
                };
                console.log(this.chatRepo)
                var url = await this.$authInstance.getBaseUrl()
                let chatBaseServer = `https://u43zkrn3fm.us-east-1.awsapprunner.com`
                if (url.includes("http://localhost")) chatBaseServer = "http://localhost:4000"
                var saveResponse = await fetch(`${chatBaseServer}/${org}/update_collection_github`, requestOptions)
                var jsonResponse = await saveResponse.json()
                window.location.reload()
            } catch (e) {

            }
        },
        async resetChat() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var token = await this.$authInstance.getToken()
                var org = await this.$authInstance.getOrg()

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                myHeaders.append("Content-Type", "application/json");

                var requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                let chatBaseServer = `https://u43zkrn3fm.us-east-1.awsapprunner.com`
                if (url.includes("http://localhost")) chatBaseServer = "http://localhost:4000"
                var saveResponse = await fetch(`${chatBaseServer}/${org}/delete_collection`, requestOptions)
                // var jsonResponse = await saveResponse.json()
                ElNotification({
                    title: 'Chat Data Deleted',
                    message: "Your Documents were removed from the Chatbot",
                    position: 'bottom-right'
                })
            } catch (e) {
                console.log(e)
            }
        },
        async refreshDocs() {

        },
        async getAPIConfigs() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/external_githubs`, requestOptions)
                var saveResponseOne = await fetch(`${url}/external_githubs?gpt=yes`, requestOptions)
                var jsonResponse = await saveResponseTwo.json()
                var jsonResponseOne = await saveResponseOne.json()
                // this.$emit("customrepo", { github_docs: jsonResponse, github_gpts: jsonResponseOne })
                // return { github_docs: jsonResponse, github_gpts: jsonResponseOne }
            } catch (e) {

            }

        },
        async saveConfig(apiConfig) {
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                let github_app = this.patValue == 'Saved GitHub App Token'
                if (this.hasGithubAppSetup == true && github_app == true) {
                    apiConfig["github_app"] = true
                    delete apiConfig["pat"]
                }
                var raw = JSON.stringify(apiConfig);
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_githubs`, requestOptions)
                let creationResponse = await saveResponseTwo.json() 
                if(creationResponse) window.location.reload()
            } catch (e) {
                console.log(e)
            }
        },
        async deleteConfig() {
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }


                var requestOptions = {
                    method: 'delete',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_githubs`, requestOptions)
                let result = await saveResponseTwo.json()
                if (result.message == "successfully deleted") window.location.reload()
            } catch (e) {

            }
        },
        async createPlaygroundGithub() {


            // this.updateAttributes({ saved: true })
            // if (this.connectedGitUrl) this.showConnectedGithub = true
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()


                var raw = JSON.stringify({ user })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/fork_playground`, requestOptions)
                var result = await saveResponseTwo.json()
                console.log(result)
                this.playgroundGithubUrl = `https://github.com/team-dev-docs/${org}-dev-docs-playground`
                this.playgroundCodespacesUrl = `https://codespaces.new/team-dev-docs/${org}-dev-docs-playground?devcontainer_path=.devcontainer%2Fdevcontainer.json`
                this.showConnectedGithub = true
            } catch (e) {
                console.log(e)
                //this.playgroundGithubUrl = `https://github.com/team-dev-docs/${org}-dev-docs-playground`
            }

        },
        async saveContent() {

            var data = this.editor.getJSON()

            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                const contentAsString = JSON.stringify(data)
                var request_body = { content: contentAsString, title: this.title, tags: this.tags || [], user: org, section: this.section }

                var raw = JSON.stringify(request_body);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_styling`, requestOptions)
            } catch (e) {

            }
        },

        handleClose() {
            this.$emit("reset")
        },
        async checkChatSetup() {
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                let chatBaseServer = `https://u43zkrn3fm.us-east-1.awsapprunner.com`
                if (url.includes("http://localhost")) chatBaseServer = "http://localhost:4000"
                var saveResponse = await fetch(`${chatBaseServer}/${org}/get_collection`, requestOptions)
                const result = await saveResponse.json()

                this.chatCreated = result?.created
                this.chatUrl = `https://u43zkrn3fm.us-east-1.awsapprunner.com/v1/${org}/query`
                this.code = this.getCode(this.chatUrl)
                return result
            } catch (e) {

            }

        },
        copyToClipboard() {
            const codeBlock = document.querySelector('.code-block');
            const codeSnippet = codeBlock.querySelector('code');

            // create a temporary textarea to copy the code snippet
            const tempTextarea = document.createElement('textarea');
            tempTextarea.value = codeSnippet.textContent;
            document.body.appendChild(tempTextarea);

            // select and copy the contents of the textarea
            tempTextarea.select();
            document.execCommand('copy');

            // remove the temporary textarea
            document.body.removeChild(tempTextarea);
        },
        getCode(url) {
            // const code = `
            // curl --location --request POST '${url}' \
            // --header 'Content-Type: application/json' \
            // --data-raw '{
            //     "key1": "value1",
            //     "key2": "value2"
            // }'
            // `
            // return code
            return
        },
        async checkForExistingPlaygroundGithub() {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()

                var requestOptions = {
                    method: 'get',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/created_playground_repo?username=${user.name}`, requestOptions)

                var result = await saveResponseTwo.json()
                console.log(result)
                if (result.statusCode == 500) throw Error("does not exist")

                this.playgroundGithubUrl = result?.clone_url
                this.playgroundCodespacesUrl = `https://codespaces.new/team-dev-docs/${org}-dev-docs-playground?devcontainer_path=.devcontainer%2Fdevcontainer.json`
            } catch (e) {

            }

        },
        async submitFormData() {

            var postBodyObject = this.form_data
            Object.keys(postBodyObject).forEach(key => {
                if (typeof postBodyObject[key] === 'string') {
                    postBodyObject[key] = postBodyObject[key].trim().trimStart().trimEnd();
                }
            });

            if (this.patValue == 'Saved GitHub App Token') {
                postBodyObject["github_app"] = true
            }


            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var raw = JSON.stringify({ name: this.title, ...postBodyObject });
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_githubs`, requestOptions)
            } catch (e) {

                return
            }
        },

    },
    data: () => ({
        drawer: null,
        chatbotOn: false,
        formItems: null,
        playgroundGithubUrl: null,
        playgroundCodespacesUrl: null,
        pat: true,
        form_data: { user: "boogie" },
        dialogFormVisible: false,
        editor: null,
        apiUrl: null,
        chatCreated: false,
        chatUrl: null,
        code: null,
        repoItems: [],
        chatRepo: {
            repo_owner: "",
            repo: "",
            folder: ""
        },
        gptApiUrl: null,
        docusarusRepoPat: {
            pat: "",
            repo: ""
        },
        docusarusRepo: {
            appId: "123",
            installationId: "123",
            repo: ""
        },
        gptRepo: {
            appId: "123",
            installationId: "123",
            repo: ""
        },
        patValue: 'Custom Personal Access Token',
        patOptions: ['Saved GitHub App Token', 'Custom Personal Access Token'],
        hasGithubAppSetup: null,
        token: null,
        addFile: false,
        headers: null,
        newFileName: "",
        fileList: [],
        repos: [],
        gptFileList: [],
        form_schemas: {
            "github":
                [
                    { title: "GitHub Secret", details: "", type: "password", key: "api_token", value: "" },
                    { title: "Github Repo", details: "", type: "text", key: "repo", value: "" }
                ]
        }
    }),
    async mounted() {

        var org = await this.$authInstance.getOrg()
        if (this.chatSettings) {
            this.chatRepo = this.chatSettings
        } else {
            this.chatRepo.repo_owner = "team-dev-docs"
            this.chatRepo.repo = `${org}-dev-docs`
            this.chatRepo.folder = "/docs"
        }

        this.formItems = this.form_schemas[this.title]
        await this.getIntegrations()
        this.drawer = this.open
        var baseUrl = await this.$authInstance.getBaseUrl()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        var token = await this.$authInstance.getToken()
        // var user = this.$authInstance.getUserInfo()

        if (token) {
            this.token = token
        }
        await this.checkForExistingPlaygroundGithub()
        this.apiUrl = `${baseUrl}/external_githubs`
        this.gptApiUrl = `${baseUrl}/external_github_gpts`
        var configsObject = await this.getAPIConfigs()
        var chatIsSetup = await this.checkChatSetup()


        this.fileList = configsObject?.github_docs?.docs
        this.gptFileList = configsObject?.github_gpts?.docs
        await this.getRepos()
        // if(chatIsSetup) return

    },
    unmounted() {
        this.$emit("reset")
    }
}
</script>

<style>
form .p-inputtext {
    width: 100% !important;
}

form {}

.p-password-input {
    width: 100% !important;
}

.p-dialog {
    border-radius: 0.5rem !important;

    border: 1px #1D1E23 !important;

    background: #030711 !important;
}
</style>