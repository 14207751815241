<template>
    <floating-menu class="menu-one" :editor="editor" :tippy-options="{ duration: 10 }" v-if="editor && visible != true">
        <el-button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
            H1
        </el-button>
        <el-button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
            H2
        </el-button>
        <el-button @click="imagesDialog = true">
            Add Images
        </el-button>
        <el-button @click="editor.chain().focus().setHorizontalRule().run()">
            setHorizontalRule
        </el-button>
        <el-button @click="addMermaidBlock()">
            Add Mermaid Block
        </el-button>
        <!-- <el-button type="button" class="btn-editor" @click="editor.chain().focus().toggleFormulaMark().run()"
            :class="{ 'is-active': editor.isActive('formulaMark') }">
            Inline math
        </el-button> -->
        <el-button type="button" class="btn-editor" @click="editor.chain().focus().addKatex().run()"
            :class="{ 'is-active': editor.isActive('formulaNode') }">
            Math block
        </el-button>
    </floating-menu>
    <el-dialog v-model="saveFormVisible" title="Save new Page">
        <el-form>
            <el-form-item label="Page Name" :label-width="formLabelWidth">
                <el-input v-model="title" autocomplete="off" />
            </el-form-item>
            <el-form-item label="Section Type" :label-width="formLabelWidth">
                <el-select v-model="section" placeholder="Please select a section">
                    <el-option v-for="(section, index) of sectionOptions" :label="section.label"
                        :value="section.value" />
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="saveFormVisible = false">Cancel</el-button>
                <el-button type="primary" @click="saveContent()">Save</el-button>
            </span>
        </template>
    </el-dialog>
    <el-row class="editor-save-actions mt-8 pt-2 pb-2">
        <el-col :span="2">
            <el-button @click="openDocsNav()">
                <el-icon><Expand /></el-icon>
            </el-button>
        </el-col>
        <el-col :span="17">
            <h2>Create Doc</h2>
        </el-col>
        <el-col class="flex" :span="4">
            <el-button @click="saveFormVisible = true" color="#626aef" :dark="isDark">save</el-button>
            <el-button color="#ec38bc">
                <router-link :to="{ path: `/external_docs/${section}/${title}` }" target="_blank">preview</router-link>
            </el-button>
            <el-button color="#7303c0" @click="publishContent()">
                publish
            </el-button>
        </el-col>
        <el-col :span="1"></el-col>
    </el-row>
    <reference-drawer v-if="openReference" :open="openReference" @reset="openReference = false" />
    <documents-nav v-if="showDocsNav" :title="'whatever'" :dialog="showDocsNav" @loaded_doc="loadDoc"  @close="showDocsNav = false" />
    <editor-content @drop="dropHandler" @dragstart="dragOverHandler" class="landing-external-editor" :editor="editor" />
</template>

<script>
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import CodeBlockComponent from './CodeBlock.vue'
import Focus from '@tiptap/extension-focus'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Image from '@tiptap/extension-image'
import ResizableImage from './custom_extensions/ImageUpload'
import MermaidBlock from './custom_extensions/MermaidBlock'
import Dropcursor from '@tiptap/extension-dropcursor'
import FormulaComponent from './custom_extensions/FormulaExtension.js'
import FormulaMark from './custom_extensions/FormulaMark.js'
import ReferenceMark from './custom_extensions/ReferenceWord.js'
import Commands from './custom_extensions/commands'
import suggestion from './custom_extensions/suggestion'
import DraggableItem from './custom_extensions/DraggableItem.js'


// load specific languages only
import { lowlight } from 'lowlight'
import ReferenceDrawer from './ReferenceDrawer.vue'
import { Expand } from '@element-plus/icons-vue'
import DocumentsNav from './DocumentsNav.vue'
import { ColumnExtension } from "@gocapsule/column-extension";
import parser from '@/plugins/parser'


const reader = new FileReader();





export default {
    components: {
    EditorContent,
    FloatingMenu,
    ReferenceDrawer,
    DocumentsNav,
    Expand
},
    data: () => ({
        editor: null,
        title: "",
        showDocsNav: false,
        openReference: false,
        existingDocument: null,
        saveFormVisible: false,
        sectionOptions: [{ "label": "Guides", "value": "guides" }, { "label": "Sample Apps", "value": "sample apps" }, { "label": "Concepts", "value": "concepts" }, { "label": "Blog", "value": "blogs" }, { "label": "Rest API Reference", "value": "rest api" }, { "label": "Community Content", "value": "community content" }],
        section: "guides"
    }),
    methods: {
        async checkExistingContent() {
            var route = this.$route
            if (!route?.params?.filter) return
            var filter = decodeURI(route?.params?.filter)
            
            await this.getExistingContent(filter)
        },
        addMermaidBlock() {
            this.editor.commands.insertContent(`<mermaid-block></mermaid-block>`)
        },
        async openDocsNav() {
            this.showDocsNav = true
        },
        async loadDoc(val) {
            window.alert("HE")
            
            
            const parsedHtml = parser.convertToHTML(val)
            this.editor.commands.setContent(parsedHtml)
        },
        async getExistingContent(filter) {
            try {
                var myHeaders = new Headers();
                var token = await this.$authInstance.getToken()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var url = await this.$authInstance.getBaseUrl()
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var responseData = await fetch(`${url}/external_docs?searchKey=filter&searchValue=${filter}`, requestOptions)
                
                var resJson = await responseData.json()
                
                if (!resJson?.data[0]) throw Error("No matching external page")
                this.existingDocument = resJson?.data[0]
                this.title = this.existingDocument.title
                this.section = this.existingDocument.section
                this.editor.commands.setContent(this.existingDocument.content)
            } catch (e) {
                
            }

        },
        async saveContent() {
            
            var data = this.editor.getJSON()
            
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                const contentAsString = JSON.stringify(data)
                var request_body = { content: contentAsString, title: this.title, tags: this.tags || [], user: org, section: this.section }
                
                var raw = JSON.stringify(request_body);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
               // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_landing`, requestOptions)
            } catch (e) {
                
            }
        },
        async publishContent() {
            
            var data = this.editor.getJSON()
            
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                const filter = `${this.section}-${this.title}`
                var request_body = { searchKey: "filter", searchValue: filter }
                
                var raw = JSON.stringify(request_body);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponse = await fetch(`${url}/external_doc/publish`, requestOptions)
                var jsonResponse = await saveResponse.json()
                
            } catch (e) {
                
            }
        },
        clickReference() {
            this.openReference = true
        },
        dragOverHandler(ev) {
            

            // Prevent default behavior (Prevent file from being opened)
            // ev.preventDefault();
        },
        dropHandler(ev) {
            
            ev.preventDefault();

            // Prevent default behavior (Prevent file from being opened)


            if (ev.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                [...ev.dataTransfer.items].forEach((item, i) => {
                    // If dropped items aren't files, reject them
                    if (item.kind === 'file') {
                        const file = item.getAsFile();
                        
                        reader.readAsDataURL(file);
                        var fileUrl = URL.createObjectURL(file);
                        
                    }
                });
            } else {
                // Use DataTransfer interface to access the file(s)
                [...ev.dataTransfer.files].forEach((file, i) => {
                    
                    reader.readAsDataURL(file);
                    var fileUrl = URL.createObjectURL(file);
                    
                });
            }
        },

    },
    unmounted() {
        localStorage.removeItem("tree_data")
    },
    async mounted() {
        var component = this
        this.editor = new Editor({
            editorProps: {
                handleClickOn: (view, pos, node) => {
                    
                    if(node) {
                        
                        //childCount node.childCount
                        
                        var childNode = node.child(0)  
                        if(childNode.marks.length > 0) {
                            var referenceMark = childNode.marks.find(function(item) {
                                return item?.type?.name == "referenceMark"
                            })
                            if(referenceMark) {
                                component.clickReference()
                            }
                        }
                    }
                }
            },
            extensions: [
                Document,
                Paragraph,
                Text,
                StarterKit.configure({ document: false }),
                ColumnExtension,
                TaskList,
                Dropcursor,
                FormulaComponent,
                Commands.configure({
                    suggestion,
                }),
                FormulaMark,
                ReferenceMark,
                DraggableItem,
                MermaidBlock.configure({ saved: "yeah yeah" }),
                ResizableImage.configure({ allowBase64: true, inline: true }),
                TaskItem.configure({
                    nested: true,
                }),
                Focus.configure({
                    className: 'has-focus',
                    mode: 'all',
                }),
                CodeBlockLowlight
                    .extend({
                        addNodeView() {
                            return VueNodeViewRenderer(CodeBlockComponent)
                        },
                    })
                    .configure({ lowlight }),
            ],
            content: {
  type: 'doc',
  content: [
    {
      type: 'heading',
      attrs: { level: 2 },
      content: [{ type: 'text', text: 'Hi there,' }],
    },
    {
      type: 'paragraph',
      content: [
        { type: 'text', text: 'This is a ' },
        { type: 'text', marks: [{ type: 'italic' }], text: 'basic' },
        { type: 'text', text: ' example of ' },
        { type: 'text', marks: [{ type: 'bold' }], text: 'tiptap' },
        {
          type: 'text',
          text: '. Sure, there are all kind of basic text styles you’d probably expect from a text editor. But wait until you see the lists:',
        },
      ],
    },
    {
      type: 'bulletList',
      content: [
        {
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: 'That’s a bullet list with one …' }],
            },
          ],
        },
        {
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [{ type: 'text', text: '… or two list items.' }],
            },
            {
              type: 'bulletList',
              content: [
                {
                  type: 'listItem',
                  content: [
                    {
                      type: 'paragraph',
                      content: [{ type: 'text', text: 'Sub-item 1' }],
                    },
                  ],
                },
                {
                  type: 'listItem',
                  content: [
                    {
                      type: 'paragraph',
                      content: [{ type: 'text', text: 'Sub-item 2' }],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'blockquote',
      content: [
        {
          type: 'paragraph',
          content: [
            { type: 'text', text: 'Wow, that’s amazing. Good work, boy! ' },
            { type: 'hardBreak' },
            { type: 'text', text: '— Mom' },
          ],
        },
      ],
    },
    { type: 'paragraph' },
    {
      type: 'columnBlock',
      content: [
        {
          type: 'column',
          content: [
            { type: 'paragraph', content: [{ type: 'text', text: 'First column' }] },
            { type: 'paragraph', content: [{ type: 'text', text: 'with 2 paragraphs' }] },
            {
              type: 'bulletList',
              content: [
                {
                  type: 'listItem',
                  content: [
                    {
                      type: 'paragraph',
                      content: [{ type: 'text', text: 'And even a list…' }],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'column',
          content: [{ type: 'paragraph', content: [{ type: 'text', text: 'Second column' }] }],
        },
        {
          type: 'column',
          content: [{ type: 'paragraph', content: [{ type: 'text', text: 'Third columns' }] }],
        },
      ],
    },
  ],
}
        })

        this.editor.commands.insertContent(`<p>This is a boring paragraph.</p>
        <div data-type="draggable-item">
          <p>Followed by a fancy draggable item.</p>
        </div>
        <div data-type="draggable-item">
          <p>And another draggable item.</p>
          <div data-type="draggable-item">
            <p>And a nested one.</p>
            <div data-type="draggable-item">
              <p>But can we go deeper?</p>
            </div>
          </div>
        </div>
        <p>Let’s finish with a boring paragraph.</p>`)
        reader.addEventListener("load", () => {
            
            // convert image file to base64 string
            component.editor.commands.insertContent(`<img src="${reader.result}">`,
                {
                    parseOptions: {
                        preserveWhitespace: true,
                    }
                })
        }, false);

        this._keyListener = async function (e) {
            
            if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault(); // present "Save Page" from getting triggered.
                component.saveFormVisible = true
                //await this.saveContent()
                //this.saveNote();
            } else {
                //
            }
        };
        document.addEventListener('keydown', this._keyListener.bind(this));
        await this.checkExistingContent()
        await this.editor.commands.focus('end')
    }
}
</script>
<!-- 
<style lang="scss">

.editor-save-actions {

}

.docs-notification {
    background-color: #2C2738 !important;
    background: #2C2738 !important;
}

.commit-button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.menu-one {
    padding-top: 5em;
    margin-left: 0em !important;
    display: flex !important;
    justify-content: space-around !important;
    padding-left: 10em;
}

ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    p {
        margin: 0;
    }

    li {
        display: flex;

        >label {
            flex: 0 0 auto;
            margin-right: 0.5rem;
            user-select: none;
        }

        >div {
            flex: 1 1 auto;
        }
    }
}

.arch-diagram {
    background: red;
}

img[title="arch-diagram"] {
    background: red;
}



.has-focus {
    border-radius: 3px;
    box-shadow: 1px 0 5px -1px #888;
}

.editor-box {
    //padding: 1em;
}

.editing-external-editor span[data-inline-katex] {
    background: rgb(255, 252, 196);
    padding: 2px;
    display: inline-block;
}

/* Basic editor styles */
.landing-external-editor {
    background: #2c2738 !important;
    [data-inline-reference="true"] {
        color: red;
    /* Styles */
    }



    .ProseMirror {
        min-height: 100vh !important;
        // background: #2C2738 !important;
        padding-left: 5em;

        h1 {
            font-family: "Inter", sans-serif !important;
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 36px !important;
            line-height: 22px !important;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {}

        p {
            // color: white;

        }

        >*+* {
            margin-top: 0.75em;
        }


        ul,
        ol {
            padding: 0 2rem;
        }

        pre {
            color: #FFF;
            font-family: 'JetBrainsMono', monospace;
            padding: 0.75rem 1rem;
            border-radius: 0.5rem;
            background: black !important;
            overflow-y: scroll !important;

            code {
                color: inherit;
                padding: 0;
                background: black !important;
                font-size: 0.8rem;
                overflow-y: scroll !important;
            }

            .hljs-comment,
            .hljs-quote {}

            .hljs-variable,
            .hljs-template-variable,
            .hljs-attribute,
            .hljs-tag,
            .hljs-name,
            .hljs-regexp,
            .hljs-link,
            .hljs-name,
            .hljs-selector-id,
            .hljs-selector-class {
                color: #F98181;
            }

            .hljs-number,
            .hljs-meta,
            .hljs-built_in,
            .hljs-builtin-name,
            .hljs-literal,
            .hljs-type,
            .hljs-params {}

            .hljs-string,
            .hljs-symbol,
            .hljs-bullet {}

            .hljs-title,
            .hljs-section {}

            .hljs-keyword,
            .hljs-selector-tag {}

            .hljs-emphasis {}

            .hljs-strong {
                font-weight: 700;
            }
        }
    }
}

.ProseMirror .column-block {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 24px;
  padding: 8px 0;
}

.ProseMirror .column {
  overflow: auto;
  border: 1px gray dashed;
  border-radius: 8px;
  padding: 8px;
  margin: -8px;
}
</style> -->
