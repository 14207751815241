<template>
    <div class="chat-container">
        <el-card class="chat-history" :body-style="{ padding: '0px' }">
            <el-scrollbar wrap-class="scrollbar-wrapper">
                <div class="message" v-for="(message, index) in messages" :key="index">
                    <el-avatar :src="message.avatar"></el-avatar>
                    <div class="content">
                        <div class="text">{{ message.text }}</div>
                        <div class="time">{{ message.time }}</div>
                    </div>
                </div>
            </el-scrollbar>
        </el-card>
        <div class="input-area">
            <el-input class="input-inner" v-model="newMessage" placeholder="Type a message..." @keyup.enter="sendMessage">
                <template #append>
                    <el-button icon="el-icon-send" @click="sendMessage"></el-button>
                </template>
            </el-input>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        info: Object,
        prompt: String,
        welcome: String
    },
    data() {
        return {
            newMessage: '',
            dataForAI: null,
            loading: false,
            command: "",
            messages: [
                // Sample messages
                {
                    name: 'Alice',
                    avatar: 'path-to-alice-avatar.jpg',
                    text: 'Hello World!',
                    time: '10:00 AM',
                },
                // Add more messages here
            ],
        };
    },
    mounted() {
        this.dataForAI = this.info
        console.log("what is the info", this.info)
        console.log("what is the welcome", this.welcome)
        this.messages = [{
            name: 'Alice',
            avatar: 'path-to-alice-avatar.jpg',
            text: this.welcome || "hello",
            time: '10:00 AM',
        }]
    },
    methods: {
        async updateBlock() {
            var myHeaders = new Headers();
            try {
                var url = await this.$authInstance.getBaseUrl()
                var token = await this.$authInstance.getToken()
                this.loading = true
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                // const url = `http://localhost:3000/company/${this.}`

                var raw = JSON.stringify({
                    chunk: true,
                    text: this.dataForAI,
                    prePrompt: "This is a JSON with an array of different events with different attributes that we use to answer questions",
                    finalPrompt: `
          ----------------------------------
          All Information above can be considered as CONTEXT to answer this query/instruction from a user: ${this.command}.`
                })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var saveResponseTwo = await fetch(`${url}/proxy_url/message`, requestOptions)
                var result = await saveResponseTwo.json()
                const message = {
                    name: 'Alice', // You would dynamically set this based on the user
                    avatar: 'path-to-your-avatar.jpg', // And this
                    text: result?.content,
                    time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), // Format the time as you like
                };
                this.messages.push(message)
                // this.dialogVisible = true
            } catch (e) {
                this.error = true

            }

        },
        async sendMessage() {
            if (this.newMessage.trim() === '') {
                return;
            }
            this.command = this.newMessage.trim()
            // Here you would normally send the message to your server
            const message = {
                name: 'You', // You would dynamically set this based on the user
                avatar: 'path-to-your-avatar.jpg', // And this
                text: this.newMessage,
                time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), // Format the time as you like
            };
            this.messages.push(message);
            this.newMessage = '';
            await this.updateBlock()
        },
    },
};
</script>
  
<style scoped>
.chat-container {
    display: flex;
    flex-direction: column;
    height: 400px;
    border-radius: 8px;
    background-color: #1e1e1e;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-history {
    flex: 1;
    margin-bottom: 10px;
    overflow: hidden;
}

.scrollbar-wrapper {
    padding: 20px;
}

.message {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.message .content {
    margin-left: 10px;
    color: #fff;
}

.message .content .name {
    font-weight: 500;
    color: #9e9e9e;
}

.message .content .text {
    margin-top: 5px;
    line-height: 1.4;
    color: #dcdcdc;
}

.message .content .time {
    margin-top: 5px;
    font-size: 0.85rem;
    color: #757575;
}

.input-area {
    padding: 10px;
    background: #2c2c2c;
    border-top: 1px solid #444;
}

.input-inner {
    border: 1px solid #555;
    background-color: #333;
    color: #ddd;
    border-radius: 4px;
}

.input-inner::placeholder {
    color: #bbb;
}

.el-button {
    background-color: #4caf50;
    /* or any other color you prefer */
    border-color: #4caf50;
    border-radius: 4px;
}

.el-button>i {
    color: #fff;
}

/* If you have a custom scrollbar: */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 4px;
}

::-webkit-scrollbar-track {
    background: #333;
    border-radius: 4px;
}
</style>
  