<template>
    <node-view-wrapper :style="this.style || node?.attrs?.customStyleString" @mouseover="hover = true" @mouseleave="hover = false" class="custom-button">
        <a><node-view-content /></a>
        <!-- <button @input="editText" :style="this.style || node?.attrs?.customStyleString">
            <span v-if="node?.attrs?.buttonText">{{ text || node?.attrs?.buttonText }}</span>
            <node-view-content @input="editText" v-else />
        </button> -->
        <el-affix v-if="hover" position="bottom" target=".content" :offset="100">
            <el-row>
                <el-col :span="12">
                    <div class="drag-handle" contenteditable="false" draggable="true" data-drag-handle>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="settings" contenteditable="false" draggable="true" data-drag-handle
                    @click="colorPickerDialog = true">
                    </div>
                </el-col>
            </el-row>
        </el-affix>
        <color-picker-dialog v-if="colorPickerDialog" :type="'button'" @glassmorph="setGlassMorph" @newbp="setNewBp"
            @newgradient="setNewColors" @text="setText" @newstyle="setStyle" @close="colorPickerDialog = false" @url="setUrl" :show="colorPickerDialog"
            :attrs="node?.attrs" />
    </node-view-wrapper>
</template>
  
<script>
import { nodeViewProps, NodeViewWrapper, NodeViewContent } from '@tiptap/vue-3'

import ColorPickerDialog from '../ColorPickerDialog.vue';

function cssToVueStyle(cssString) {
    const regex = /([a-zA-Z-]+)\s*:\s*([^;]+)/g;
    const styleObject = {};
    let match;
    while ((match = regex.exec(cssString)) !== null) {
        const propName = match[1].replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
        const propValue = match[2].trim();
        styleObject[propName] = propValue;
    }
    return styleObject;
}

export default {
    components: {
        NodeViewWrapper, NodeViewContent, ColorPickerDialog
    },

    props: nodeViewProps,
    data: () => ({
        hover: false,
        colorPickerDialog: false,
        aiElementDialog: false,
        style: null,
        url: null,
        text: null
    }),
    watch: {
        colorPickerDialog(val) {
            
            if (val) this.editor.setEditable(false)
            else this.editor.setEditable(true)
        }
    },
    async mounted() {
    
    if (this.node.attrs.background) this.gradientColor = this.node.attrs.background
    else this.gradientColor = `linear-gradient(${this.angle}deg, ${this.color1}, ${this.color2})`
    if (this?.node?.attrs?.background?.includes('url')) {
      await this.getData()
    }
    if(this?.node?.attrs?.cssString) await this.setStyle(this?.node?.attrs?.cssString)
    this.url = this.node?.attrs?.url
    this.text = this.node?.attrs.buttonText
    if(!this.text) this.colorPickerDialog = true

  },
    methods: {
        goToUrl() {
            var url = this.url
            var win = window.open(url, '_blank');
        },
        editText() {
            
        },
        testMethod() {
            
        },
        setUrl(val) {
            this.updateAttributes({url: val})
            this.url = val
        },
        setText(val) {
            
            this.text = val
            this.updateAttributes({buttonText: val})
        },
        setNewColors(val) {
            
            const { textColor, background } = val
            this.newColor = background
            this.newTextColor = textColor
            this.updateAttributes({ background: background })
        },
        setNewBp(val) {
            this.bp = val
            
            
            this.updateAttributes({ backgroundPosition: val })
        },
        setNewBackgroundImage(val) {
            
            this.newColor = val.previewUrl
            this.updateAttributes({ background: val.externalImage })
        },
        setNewHeight(val) {
            
            this.newHeight = val
            this.updateAttributes({ height: this.newHeight })
        },
        setNewWidth(val) {
            
            this.newWidth = val
            this.updateAttributes({ width: this.newWidth })
        },
        setStyle(val) {
            
            const style = cssToVueStyle(val)
            this.style = val
            if (val) this.updateAttributes({ customStyleString: val })
            if (val) this.updateAttributes({ customStyle: style })
        },
        setGlassMorph(val) {
            this.glassMorph = val
            if (val) this.updateAttributes({ class: "glass-morph" })
            else this.updateAttributes({ class: "" })
        },
        async destory() {
            this.deleteNode()
            return
        },
        async duplicate() {
            const node = this.node.toJSON()
            
            this.editor.commands.insertContent(node)
            return
        },
        async getData(folderNode) {
            const component = this
            const urlValue = `${findStringInParentheses(component.node.attrs.background)}`.replace(/"/g, "").replace(/'/g, "")
            
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseOne = await fetch(`${url}/external_page?path=static/img`, requestOptions)
            var jsonResponseOne = await saveResponseOne.json()
            
            var currentImage = jsonResponseOne.docs.find(function (item) { return item.path.includes(urlValue) })
            
            this.newColor = `url('${currentImage.download_url}') no-repeat center center / cover`
        },
    },
}
</script>
  
<style lang="scss">
.custom-button {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    display: inline-block;
    width: fit-content;
    height: fit-content;
    background-color: #4f4f4f;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    .drag-handle {
        flex: 0 0 auto;
        position: relative;
        width: 1rem;
        height: 1rem;
        top: 0.3rem;
        margin-right: 0.5rem;
        cursor: grab;
        background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .content {
        white-space: pre-wrap !important;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        padding-top: 0px !important;
    }





    // .settings {
    //     flex: 0 0 auto;
    //     position: relative;
    //     width: 1rem;
    //     height: 1rem;
    //     top: 0.3rem;
    //     margin-right: 0.5rem;
    //     cursor: grab;
    //     background-image: url('data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill-opacity="0.2" d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"></path></svg>');
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     background-position: center;
    //     padding: 0.5rem;
    //     margin-top: 0.5rem;
    //     background-blend-mode: difference;
    // }

    .chat {
        flex: 0 0 auto;
        position: relative;
        width: 1rem;
        height: 1rem;
        top: 0.3rem;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        cursor: grab;
        background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill-opacity="0.2"  d="m174.72 855.68 130.048-43.392 23.424 11.392C382.4 849.984 444.352 864 512 864c223.744 0 384-159.872 384-352 0-192.832-159.104-352-384-352S128 319.168 128 512a341.12 341.12 0 0 0 69.248 204.288l21.632 28.8-44.16 110.528zm-45.248 82.56A32 32 0 0 1 89.6 896l56.512-141.248A405.12 405.12 0 0 1 64 512C64 299.904 235.648 96 512 96s448 203.904 448 416-173.44 416-448 416c-79.68 0-150.848-17.152-211.712-46.72l-170.88 56.96z"></path></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        padding: 0.5rem;
        background-blend-mode: difference;
    }

    .trash {
        flex: 0 0 auto;
        position: relative;
        width: 1rem;
        height: 1rem;
        top: 0.3rem;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        cursor: grab;
        background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z"></path></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        padding: 0.5rem;
        background-blend-mode: difference;
    }

    .copy {
        flex: 0 0 auto;
        position: relative;
        width: 1rem;
        height: 1rem;
        top: 0.3rem;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        cursor: grab;
        background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M768 832a128 128 0 0 1-128 128H192A128 128 0 0 1 64 832V384a128 128 0 0 1 128-128v64a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64h64z"></path><path fill="currentColor" d="M384 128a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64V192a64 64 0 0 0-64-64H384zm0-64h448a128 128 0 0 1 128 128v448a128 128 0 0 1-128 128H384a128 128 0 0 1-128-128V192A128 128 0 0 1 384 64z"></path></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        padding: 0.5rem;
        background-blend-mode: difference;
    }
}
</style>