<template>
	<div
		v-if="!isEditorReady"
		class="absolute top-0 left-0 z-[10000] w-full h-full p-[1.25rem] bg-[#121212] text-[#D4D4D4] flex items-center justify-center"
	>
		<div class="flex flex-col items-center gap-4">
		<div class="w-12 h-12 border-4 border-t-[#D4D4D4] border-opacity-25 rounded-full animate-spin"></div>
		<span>Loading...</span>
		</div>
	</div>

	<div class="bg-[#030711] w-screen h-screen flex flex-col pb-[58px] editor-nav">
		<div class="w-full px-[1.25rem] py-[0.62rem] flex flex-row items-center justify-between"
			style="border-bottom: 1px solid rgba(122, 123, 128, 0.20);">
			<div class="flex flex-row items-center gap-[1.25rem] text-[0.88rem]">
				<a href="/dash">
					<img class="w-[1.69rem] h-[1.63rem]" :src="logoIcon" alt="Logo" />
				</a>
				<div class="flex flex-row items-center gap-[0.31rem]">
					<img class="w-[0.75rem] h-[0.94rem]" :src="docsIcon" alt="Docs" />
					<div>
						Your user-facing docs
					</div>
					<img class="w-[1.5rem] h-[1.5rem]" :src="slashIcon" alt="Slash" />
					<img class="w-[1.25rem] h-[1.25rem]" :src="editIcon" alt="Edit" />
					<div>
						Edit docs
					</div>
				</div>
			</div>

			<div class="flex flex-row items-center gap-[0.62rem]">
				<button class="flex flex-row items-center gap-[0.31rem] hover:text-white">
					<img class="w-[0.94rem] h-[0.94rem]" :src="auditIcon" alt="audit" />
					<div @click="toggleAIDrawer('audit')">
						Audit docs
					</div>
				</button>


				<!-- <button class="flex flex-row items-center gap-[0.31rem] hover:text-white">
					<img class="w-[1rem] h-[0.81rem]" :src="changelogIcon" alt="Changelog" />
					<div @click="toggleAIDrawer('changelog')">
						Create a changelog
					</div>
				</button> -->

				<button class="flex flex-row items-center gap-[0.31rem] hover:text-white">
					<img class="w-[0.78rem] h-[1rem]" :src="contentIcon" alt="content" />
					<div @click="toggleAIDrawer('generate')">
						Generate content
					</div>
				</button>

				<Select
					v-model="selectedEditor"
					:options="editorOptions"
					optionLabel="name"
					class="hover:text-white"
				/>

				<!-- <button class="flex flex-row items-center gap-[0.31rem] hover:text-white">
					<div>
						Rich text editor
					</div>
					<img class="w-[0.61rem] h-[0.32rem]" :src="arrowDownIcon" alt="down arrow" />
				</button> -->

				<button
					class="flex flex-row items-center gap-[0.31rem] rounded-[0.5rem] border border-[#7984EB] px-[0.63rem] py-[0.44rem] hover:text-white"
					@click="toggleGithub">
					<img class="w-[0.82rem] h-[0.82rem]" :src="pushIcon" alt="push" />
					<div>
						Push to GitHub
					</div>
					<img class="w-[0.61rem] h-[0.32rem]" :src="arrowDownIcon" alt="down arrow" />
				</button>
				<Popover append-to=".editor-nav" class="popover-content" ref="pushGitHub" unstyled>
					<div style="height: 300px; border-radius: 0.5rem;"
						class="w-[243px] h-[273px] py-[15px] bg-[#1c1c1c] rounded-lg border border-[#7a7b80]/10 flex-col justify-start items-center gap-[15px] inline-flex">
						<div class="self-stretch h-[69px] px-[5px] flex-col justify-start items-start flex">
							<div class="self-stretch px-2.5 justify-center items-center gap-[50px] inline-flex">
								<div class="grow shrink basis-0 text-[#a2a1a5] text-xs font-medium font-['Inter']">Push
									drafts to GitHub</div>
							</div>
							<div class="self-stretch px-2.5 py-[5px] justify-start items-center gap-2.5 inline-flex">
								<div class="h-4 p-px justify-start items-center gap-[50px] flex"></div>
								<div class="text-[#d3d3d3] text-sm font-semibold font-['Inter']">All drafts</div>
							</div>
							<div class="px-2.5 py-[5px] justify-start items-center gap-2.5 inline-flex">
								<MultiSelect v-if="draftOptions" style="max-width: 100%; width: 100%;"
									optionLabel="label" :maxSelectedLabels="0" v-model="selectedDraftsToPush"
									:options="draftOptions" placeholder="Select drafts to push">
									<template #dropdownicon>
										<i class="pi pi-map" />
									</template>
									<template #option="slotProps">
										<div class="flex items-center gap-[0.5rem]">
											<i v-if="slotProps.option.action === 'delete'" class="pi pi-minus"
												style="color: red"></i>
											<i v-else class="pi pi-plus" style="color: green"></i>
											<span
												style="width: 200px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
												v-tooltip.left="slotProps.option.label" severity="success">{{
													slotProps.option.label }}</span>
										</div>
									</template>
								</MultiSelect>
							</div>
						</div>
						<div class="mt-4 self-stretch h-[0px] border border-[#a2a1a5]/25"></div>
						<div class="self-stretch h-[44px] flex-col justify-start items-start flex">
							<div
								class="self-stretch h-[47px] px-[5px] flex-col justify-start items-start gap-[5px] flex">
								<div class="self-stretch px-2.5 justify-center items-center gap-[50px] inline-flex">
									<div class="grow shrink basis-0 text-[#a2a1a5] text-xs font-medium font-['Inter']">
										Repo</div>
								</div>
								<div
									class="self-stretch px-2.5 py-[5px] justify-start items-center gap-2.5 inline-flex">
									<div class="w-4 h-4 p-px justify-center items-center gap-[50px] flex">
										<img class="w-[1.25rem] h-[1.25rem]" :src="githubIcon" alt="github" />

									</div>
									<div class="text-[#d3d3d3] text-sm font-normal font-['Inter']">Docs</div>
								</div>
							</div>
							<div class="mt-2 h-[10px] px-[5px] flex-col justify-center items-center gap-[5px] flex">
								<div class="self-stretch px-2.5 justify-center items-center gap-[50px] inline-flex">
									<div class="grow shrink basis-0 text-[#a2a1a5] text-xs font-medium font-['Inter']">
										Branch
									</div>
								</div>
							</div>
						</div>
						<div class="flex flex-col items-start justify-start mt-2">
							<BranchPicker v-model="reviewBranch" :options="branches" />
						</div>
						<div class="flex flex-col items-start justify-start">
							<Button @click="pushToGithub"> Push change to GitHub</Button>

						</div>
					</div>
				</Popover>

			</div>

		</div>
		<div class="flex flex-row h-full">
			<div class="w-[14.89rem] bg-[#121212] p-[1.25rem] h-full overflow-y-scroll border-r-[1px] border-r-[rgba(122,123,128,0.20)]">
				<div class="flex flex-col items-end justify-end w-full">
					<BranchPicker v-model="draftBranch" :options="branches" />
					<div class="flex flex-col items-end gap-[0.31rem]">
						<div class="flex justify-between w-full">
							<div class="flex gap-2">
								<!-- <button class="hover:text-white">
									<img class="w-[1.25rem] h-[1.25rem]" :src="addFolderIcon" alt="add folder" />
								</button> -->
								<button @click="toggleNewFileInput" class="hover:text-white">
									<img class="w-[1.25rem] h-[1.25rem]" :src="addFileIcon" alt="add file" />
								</button>
							</div>
						</div>
					</div>

				</div>

				<ul v-if="drafts?.length > 0 || loadedDrafts" class="mt-[1rem] mb-[2rem] flex flex-col gap-[0.5rem]">
					<li v-if="showNewFileInput" class="new-file-input">
						<input v-model="newFileName" @keyup.enter="createNewFile" @keyup.esc="cancelNewFile"
							placeholder="Enter file name" ref="fileNameInput" />
					</li>
					<!-- Update the router-link section with dropdown -->
					<div v-for="draft in drafts" :key="draft" class="flex items-center justify-between">
						<input v-if="renamingDraft == draft" v-model="newRenamedDraftName"
							@keyup.enter="renameFile({ fileName: currentDraft, branchName })"
							@keyup.esc="renamingDraft = null" placeholder="Enter file name" ref="fileNameInput" />
						<router-link v-else @contextmenu="onRightClick($event)" :to="{
							path: '/editor',
							query: {
								file: draft,
								branch: draftBranch,
								repo: `${owner}%2F${repo}`
							}
						}" :class="[
							'text-[0.875rem] hover:text-white cursor-pointer flex-grow p-2',
							route.query.file === draft ? 'active-draft' : ''
						]">
							{{ removeFileExtension(draft) }}
						</router-link>
						<Popover :ref="el => contextMenuRefs[draft] = el" unstyled>
							<div style="background-color: #1C1C1C;" class="flex flex-wrap justify-center gap-4 p-2 card">
								<Button style="color: white;"
									@click="renameDraftFile({ fileName: currentDraft, branchName })" text>
										<i class="pi pi-pencil" />
									Rename
								</Button>
								<Button style="color: #FF7070;"
									@click="deleteDraftFile({ fileName: currentDraft, branchName })" text>
									<i class="pi pi-trash" style="color: #FF7070;" />
									Delete
								</Button>
							</div>
						</Popover>
						<div class="relative">
							<button @click="onRightClick($event, draft)" class="p-1 hover:text-white">
								⋮
							</button>
						</div>
					</div>

				</ul>
				<Skeleton v-else width="100%" height="10rem" borderRadius="16px"></Skeleton>
				<PublishedDrafts v-if="publishedDrafts?.length > 0" :items="publishedDrafts" :repo="repo" :owner="owner" :repoUrl="repoUrl" :branch="draftBranch" />
				<Skeleton v-else width="100%" height="10rem" borderRadius="16px"></Skeleton>
			</div>
			<div class="text-white bg-[#121212] w-full h-full overflow-y-scroll">
				<SelectedTextTools :editor="editor" />
				<InsertBlockTools :editor="editor" />
				<editor-content :editor="editor" class="h-full" />
			</div>
		</div>

	</div>
	<Drawer style="width: 62.5rem" v-model:visible="openAIDrawer" position="right">
		<div class="absolute top-0 left-0 z-50 w-full h-full p-[1.25rem] bg-[#121212] text-[#D4D4D4]">
			<div v-if="title" class="text-white text-[1.25rem] font-semibold leading-[1.6875rem]">
				{{ title }}
			</div>
			<div v-else>Audit docs</div>
			<Audit v-if="actionType == 'audit'" :branch="draftBranch" @draft="handleDraft" />
			<ChangeLog v-else-if="actionType == 'changelog'" />
			<GeneratePage @draft="handleDraft" v-else-if="actionType == 'generate'" />
		</div>
	</Drawer>
</template>

<script setup>
import {
	onBeforeUnmount,
	onMounted,
	ref,
	watch,
	nextTick,
	computed
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Dropdown from 'primevue/dropdown';

import {
	BubbleMenu,
	Editor,
	EditorContent,
	FloatingMenu,
} from '@tiptap/vue-3';
import Code from '@tiptap/extension-code';
import Underline from '@tiptap/extension-underline';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import InsertBlockTools from '@/components/EditorComponents/InsertBlockTools.vue';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import SelectedTextTools from '@/components/EditorComponents/SelectedTextTools.vue';
import BranchPicker from '@/components/EditorComponents/BranchPicker.vue';
import PublishedDrafts from '@/components/EditorComponents/PublishedDrafts.vue';
import Text from '@tiptap/extension-text';
import Link from '@tiptap/extension-link';

import VsCodeImage from '../components/EditorExtensions/MarkdownGithubImage';

import StarterKit from '@tiptap/starter-kit';
import addFileIcon from '@/assets/icon-add-file.svg';
import addFolderIcon from '@/assets/icon-add-folder.svg';
import arrowDownIcon from '@/assets/icon-arrow-down.svg';
import auditIcon from '@/assets/icon-audit.svg';
import changelogIcon from '@/assets/icon-changelog.svg';
import contentIcon from '@/assets/icon-content.svg';
import docsIcon from '@/assets/icon-docs.svg';
import editIcon from '@/assets/icon-edit.svg';
import githubIcon from '@/assets/icon-github.svg';
import branchIcon from '@/assets/icon-branch.svg';
import logoIcon from '@/assets/logo-icon.png';
import pushIcon from '@/assets/icon-push.svg';
import slashIcon from '@/assets/icon-slash.svg';
import aiIcon from '@/assets/icon-ai.svg';
import Audit from '@/components/Audit.vue';
import GeneratePage from '@/components/GenerateDocumentationPage.vue';
import ChangeLog from '@/components/ChangeLog.vue';

import { getFileContent, getFiles, getOwnerAndRepo, updateFileContent, pushFileContent, createFileContent, getCustomMarkdownFiles, deleteFile, renameFileContent, getBranches } from '../plugins/devdocsBackendService.js';
import { markdownToHtml, htmlToMarkdown } from '@/plugins/unifiedParserService.js';
import { debounce } from 'lodash';

const router = useRouter();
const route = useRoute();

const editor = ref(null);
const openAIDrawer = ref(false);
const actionType = ref(null);
const frontmatter = ref(null);
const titleData = {
	'audit': 'Conduct an audit',
	'changelog': 'Create a changelog',
	'generate': 'Generate content',
};

const STORAGE_KEYS = {
	DRAFT_BRANCH: 'draftBranch',
	REVIEW_BRANCH: 'reviewBranch',
	PUBLISHED_BRANCH: 'publishedBranch'
}

// Add functions to handle localStorage
const getBranchFromStorage = (key, defaultValue) => {
	return localStorage.getItem(STORAGE_KEYS[key]) || defaultValue
}

const setBranchInStorage = (key, value) => {
	localStorage.setItem(STORAGE_KEYS[key], value)
}

// Initialize branch values
const draftBranch = ref(getBranchFromStorage('DRAFT_BRANCH', 'dev-docs-drafts'))
const reviewBranch = ref(getBranchFromStorage('REVIEW_BRANCH', 'dev-docs-review'))
const publishedBranch = ref(getBranchFromStorage('PUBLISHED_BRANCH', 'main'))


const activeDropdown = ref(null)
const branches = ref([])
const currentDraft = ref(null)
const draftOptions = ref([])
const drafts = ref([])
const isEditorReady = ref(true);
const loadedDrafts = ref(false);
const menu = ref();
const owner = ref(null);
const publishedDrafts = ref([]);
const publishedItems = ref({})
const pushGitHub = ref();
const repo = ref(null);
const selectedDraftsToPush = ref([]);
const title = ref(null);

const editorOptions = [
	{ name: 'Rich text editor', value: 'rich' },
	{ name: 'Raw editor', value: 'raw' }
];
const selectedEditor = ref(editorOptions[0]);

const toggleAIDrawer = (type) => {
	title.value = titleData[type]
	openAIDrawer.value = !openAIDrawer.value
	actionType.value = type
}

const renamingDraft = ref(null)
const newRenamedDraftName = ref(null)

const removeFileExtension = (filename) => {
	return filename ? filename.replace(/\.[^/.]+$/, '') : filename;
};

const contextMenuRefs = ref({});

const onRightClick = (event, draft) => {
	if (contextMenuRefs.value[draft]) {
		currentDraft.value = draft;
		contextMenuRefs.value[draft].toggle(event);
	}
};

const search = (event) => {
	branches.value = branches.value.filter(branch => branch.includes(event.query))
}

const toggleGithub = (event) => {
	pushGitHub.value.toggle(event);
}

watch(
	() => route,
	async (to, from) => {
		await fetchFileFromUrl();
	},
	{ deep: true } // Need deep watching since route is an object
)

watch(draftBranch, async (newValue) => {
	let files = await getFiles({ compareBranch: newValue, branch: reviewBranch.value })
	let getOwnerAndRepoData = await getOwnerAndRepo()
	owner.value = getOwnerAndRepoData.owner
	repo.value = getOwnerAndRepoData.repo
	console.log('these are the files', files);
	drafts.value = files?.files.existing
	setBranchInStorage('DRAFT_BRANCH', newValue)
})

watch(reviewBranch, async (newValue) => {
	let files = await getFiles({ compareBranch: draftBranch.value, branch: reviewBranch.value })
	let getOwnerAndRepoData = await getOwnerAndRepo()
	owner.value = getOwnerAndRepoData.owner
	repo.value = getOwnerAndRepoData.repo
	console.log('these are the files', files);
	drafts.value = files?.files.existing
	setBranchInStorage('REVIEW_BRANCH', newValue)
})

watch(publishedBranch, async (newValue) => {
	let getOwnerAndRepoData = await getOwnerAndRepo()
	owner.value = getOwnerAndRepoData.owner
	repo.value = getOwnerAndRepoData.repo
	let publishedFiles = await getCustomMarkdownFiles({ owner: owner?.value || getOwnerAndRepoData.owner, repo: repo?.value || getOwnerAndRepoData.repo, branch: "main" })
	publishedDrafts.value = publishedFiles?.files
	setBranchInStorage('PUBLISHED_BRANCH', newValue)
})



const fetchFileFromUrl = async () => {
	const {
		file,
		branch,
		repo,
	} = route.query;

	const owner = repo.split('%2F')[0];
	const repoName = repo.split('%2F')[1];

	const { content } = await getFileContent({
		branch,
		fileName: file,
		owner,
		repo: repoName,
	});
	let parsedContent = await markdownToHtml(content)
	editor.value.commands.setContent(parsedContent.content);
	frontmatter.value = parsedContent?.frontmatter || ""
}

const pushToGithub = async () => {
	for (let selectedDraftToPush of selectedDraftsToPush.value) {
		console.log('selectedDraftToPush', selectedDraftToPush)
		if (selectedDraftToPush.action === 'delete') {
			await deleteDraftFile({ fileName: selectedDraftToPush.label, branchName: reviewBranch.value })
		} else {
			await pushFileContent({
				branch: draftBranch.value,
				targetBranch: reviewBranch.value,
				fileName: selectedDraftToPush.label,
				owner: owner?.value,
				repo: repo?.value,
			})

		}

	}
}

const showNewFileInput = ref(false)
const newFileName = ref('')
const fileNameInput = ref(null)

// const handleDraftOptions = async (drafts, deletedDrafts) => {
// 	console.log('draftsdskfojdsoifjio')
// 	let draftOptions = []
// 	for(let draft of drafts) {
// 		draftOptions.push({ action: "create", label: draft })
// 	}
// 	for(let draft of deletedDrafts) {
// 		draftOptions.push({ action: "delete", label: draft })
// 	}
// 	console.log('draftOptions', draftOptions)
// 	draftOptions.value = draftOptions
// }


onMounted(async () => {
	let files = await getFiles({ compareBranch: draftBranch.value, branch: reviewBranch.value })
	let getOwnerAndRepoData = await getOwnerAndRepo()
	owner.value = getOwnerAndRepoData.owner
	repo.value = getOwnerAndRepoData.repo
	console.log('these are the files', files);
	drafts.value = files?.files.existing
	let deletedDrafts = files?.files.deleted
	let draftOptionsDivider = []
	for (let draft of files?.files.existing) {
		draftOptionsDivider.push({ action: "create", label: draft })
	}
	for (let draft of files?.files.deleted) {
		draftOptionsDivider.push({ action: "delete", label: draft })
	}
	console.log('draftOptions', draftOptionsDivider)
	draftOptions.value = draftOptionsDivider
	loadedDrafts.value = true
	let publishedFiles = await getCustomMarkdownFiles({ owner: owner?.value || getOwnerAndRepoData.owner, repo: repo?.value || getOwnerAndRepoData.repo, branch: "main" })
	publishedDrafts.value = publishedFiles?.files
	publishedItems.value = {
        label: 'Files',
        icon: 'pi pi-file'
    }
	let branchesData = await getBranches()
	branches.value = branchesData?.branches
	const debouncedUpdate = debounce(async (markdown, frontmatterValue) => {
		let fullContent = frontmatterValue
			? `---\n${frontmatterValue}\n---\n\n\n\n${markdown}`
			: markdown;

		await updateFileContent({
			branch: draftBranch.value,
			content: fullContent,
			fileName: route.query.file,
			owner: owner?.value || getOwnerAndRepoData.owner,
			repo: repo?.value || getOwnerAndRepoData.repo,
		})
	}, 1000);

	editor.value = new Editor({
		async onUpdate({ editor }) {
			isEditorReady.value = editor.isEditable;
			let html = await editor.getHTML();
			let markdown = await htmlToMarkdown(html);
			debouncedUpdate(markdown, frontmatter.value);
			// Call debounced update instead of immediate update
			// The content has changed.
		},
		content: '',
		extensions: [
			BubbleMenu,
			BulletList,
			Code,
			Document,
			Link,
			ListItem,
			OrderedList,
			Paragraph,
			StarterKit,
			Text,
			Underline,
			VsCodeImage.configure({ allowBase64: true, inline: true }),
		],
	});

	await fetchFileFromUrl();
})

const handleDraft = async (data = {}) => {
	let files = await getFiles({ compareBranch: draftBranch.value, branch: reviewBranch.value })
	drafts.value = files?.files?.existing
	openAIDrawer.value = false
	//fetchFileFromUrl();
}

const toggleNewFileInput = () => {
	showNewFileInput.value = true
	nextTick(() => {
		fileNameInput.value?.focus()
	})
}

const createNewFile = async () => {
	if (!newFileName.value) return

	const fileName = newFileName.value.endsWith('.md')
		? newFileName.value
		: `${newFileName.value}.md`

	await createFileContent({
		branch: draftBranch.value,
		content: '',
		fileName: fileName,
		owner: owner?.value || getOwnerAndRepoData.owner,
		repo: repo?.value || getOwnerAndRepoData.repo,
	})
	showNewFileInput.value = false
	newFileName.value = ''

	router.replace({
		query: {
			branch: encodeURIComponent(draftBranch.value),
			file: encodeURIComponent(fileName),
			repo: encodeURIComponent(`${owner?.value || getOwnerAndRepoData.owner}/${repo?.value || getOwnerAndRepoData.repo}` || '')
		}
	})
}

const renameFile = async ({ fileName, branchName }) => {
	if (!newRenamedDraftName.value) return

	const newName = newRenamedDraftName.value.endsWith('.md')
		? newRenamedDraftName.value
		: `${newRenamedDraftName.value}.md`

	await renameFileContent({
		branch: branchName || draftBranch.value,
		fileName: fileName,
		owner: owner?.value || getOwnerAndRepoData.owner,
		repo: repo?.value || getOwnerAndRepoData.repo,
		newName
	})
	renamingDraft.value = null
	newRenamedDraftName.value = ''

	router.replace({
		query: {
			branch: encodeURIComponent(draftBranch.value),
			file: encodeURIComponent(fileName),
			repo: encodeURIComponent(`${owner?.value || getOwnerAndRepoData.owner}/${repo?.value || getOwnerAndRepoData.repo}` || '')
		}
	})
}

const deleteDraftFile = async ({ fileName, branchName }) => {
	await deleteFile({
		branch: branchName || draftBranch.value,
		fileName: fileName,
		owner: owner?.value || getOwnerAndRepoData.owner,
		repo: repo?.value || getOwnerAndRepoData.repo,
	})
	//handleDraft()
}

const renameDraftFile = async ({ fileName }) => {
	newRenamedDraftName.value = fileName
	renamingDraft.value = fileName
}

const cancelNewFile = () => {
	showNewFileInput.value = false
	newFileName.value = ''
}

const repoUrl = computed(() => {
	return `https://github.com/${owner.value}/${repo.value}`
})

onBeforeUnmount(() => {
	editor.value?.destroy()
})

</script>

<style scoped>
body {
	max-width: 100vw !important;
}

:deep(.ProseMirror) {
	font-size: 1rem;
	min-height: 100%;
	overflow-wrap: break-word;
	padding: 4rem 8rem;
	width: 100%;

	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.5rem;
	}

	h3 {
		font-size: 1.125rem;
	}

	code {
		font-family: "Fira Code", monospace;
		font-variant-ligatures: normal;
		text-shadow: none;
		background-color: #1e1e1e;
	}

	ul {
		list-style-type: disc;
		padding-left: 2rem;
	}

	ol {
		list-style-type: decimal;
		padding-left: 2rem;
	}

	a {
		color: #7984eb;
		text-decoration: underline;
		cursor: pointer;
	}
}

:deep(.ProseMirror *) {
	max-width: 100%;
	white-space: pre-wrap;
	word-wrap: break-word;
	overflow-wrap: break-word;
	color: white;
	margin-bottom: 1.5rem;
}

.p-multiselect-overlay {
	max-width: 200px;
}

.p-multiselect-list {
	padding: 1rem;
	justify-content: stretch;
	align-items: stretch;
}

.active-draft {
	border-radius: 0.5rem;
	background: #525151;
}

.p-multiselect-panel {
	max-width: none;
	width: auto !important;
}

.p-multiselect-item {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.p-tag-label {
	width: 100px !important;
	overflow: hidden !important;
	white-space: nowrap !important;
	text-overflow: ellipsis !important;
}

.p-select-dropdown {
	position: absolute;
}

/* .gradient-button:before, button:after {
	display: none !important;
} */
</style>

<!-- aireusableblock.vue enhance() updateBlock() -->