<template>
    <!-- :style="{ resize: "both", overflow: "auto !important", display: "inline-block !important", ...createStyle }" -->
    <node-view-wrapper @mouseover="hover = true" @mouseleave="hover = false" v-if="imageSrc != 'local-image'"
        :style="{ position: 'relative', ...createStyle }" ref="imageholder" as="span" class="draggable-item image-holder">
        <edit-styles-bar v-if="hover || currentInteraction" @interaction="currentInteraction = true" @endinteraction="currentInteraction = false" @newstyle="setStyle" :openSettingsDialog="() => { return colorPickerDialog = true}" :hideAi="true" :delete="deleteNode" :copy="() => {editor.commands.insertContent(node.toJSON())}"  />

        <color-picker-dialog :attrs="node?.attrs" v-if="colorPickerDialog" @newheight="setNewHeight" @newwidth="setNewWidth"
            :show="colorPickerDialog" @newimage="setNewBackgroundImage" @glassmorph="setGlassMorph" @newbp="setNewBp"
            @newgradient="setNewColors" @newstyle="setStyle" @close="colorPickerDialog = false" />
        <!-- <el-popover :visible="issue">
            <el-button @click="resolveIssue()">Sync to Github</el-button>
            <template #reference> -->
                <img loading="lazy" v-if="imageSrc" width ref="image" draggable="true" :src="imageSrc"
                    height="auto" />
            <!-- </template>
        </el-popover> -->
    </node-view-wrapper>
</template>

<script>

function cssToVueStyle(cssString) {
    const regex = /([a-zA-Z-]+)\s*:\s*([^;]+)/g;
    const styleObject = {};
    let match;
    while ((match = regex.exec(cssString)) !== null) {
        const propName = match[1].replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
        const propValue = match[2].trim();
        styleObject[propName] = propValue;
    }
    return styleObject;
}

function isDataURL(s) {
    const startsWithRegex = new RegExp('^data:');
    if (startsWithRegex.test(s)) return true
    var regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
    return !!s.match(regex);
}

function generateUniqueId() {
    const now = new Date();
    const id = now.toISOString();
    return id;
}

function convertOctetToPng(dataUrl) {
    // Check if the data URL starts with "data:application/octet"
    if (!dataUrl.startsWith('data:application/octet')) {
        return dataUrl; // Not an octet-stream, return the original URL
    }

    // Extract the base64-encoded binary data from the URL
    const binaryData = atob(dataUrl.split(',')[1]);

    // Convert the binary data to a data URL with MIME type image/png
    const pngDataUrl = `data:image/png;base64,${btoa(binaryData)}`;

    return pngDataUrl;
}



import ColorPickerDialog from '../ColorPickerDialog.vue';
import VueDraggableResizable from 'vue-draggable-resizable'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import EditStylesBar from '../EditStylesBar.vue';
export default {
    components: {
        NodeViewWrapper,
        VueDraggableResizable,
        ColorPickerDialog,
        EditStylesBar
    },
    props: nodeViewProps,
    data: () => ({
        imageSrc: null,
        hover: false,
        isResizing: false,
        issue: false,
        lastMovement: 0,
        aspectRatio: 0,
        width: 600,
        interaction: false,
        height: 0,
        x: 0,
        y: 0,
        angle: '50',
        color1: 'red',
        color2: 'blue',
        gradientColor: '',
        style: '',
        nodeViewStyle: null,
        colorPickerDialog: false,
        addElementDialog: false,
        aiElementDialog: false,
        bp: null,
        newColor: null,
        newTextColor: null,
        newHeight: null,
        glassMorph: false,
        newWidth: null,
        lock: false
    }),
    computed: {
        isLocked() {
            
            return this.editor.storage.draggableItem.disableDrag
        },
        isDraggable() {
            return this.node?.attrs?.isDraggable;
        },
        trueWidth() {
            return this.$refs?.imageholder?.$el.clientWidth || this.width
        },
        async shouldShow() {
            var modes = await JSON.parse(localStorage.getItem("editMode"))
            
            if (modes?.edit) return this.hover && true
            else return false
        },
        createBp() {
            
            if (this.bp) return this.bp
            else return
        },
        createBackgroundString() {

            if (!this.newColor && !this.node.attrs.background) return `#30363d`
            else {
                const background = this.newColor || this.node.attrs.background
                return background
            }
        },
        createStyle() {
            return this?.style || {}
        },
        shouldBeFlex() {
            if (this?.style?.display == "flex") return "flex"
            else return "block"
        },
        createHeight() {
            return this.newHeight
        },
        createElement(val) {
            
            //this.editor.commands.setMark("customCssMark", { class: val.classes, style: val.style })
        },
        createWidth() {
            return this.newWidth
        }
    },

    async mounted() {
        var component = this
        if(this?.node?.attrs?.customStyleString) await this.setStyle(this?.node?.attrs?.customStyleString)
        
        this.imageSrc = this.node?.attrs.src
        var image = this.$refs.image
        this.width = this.node?.attrs?.width || image?.width
        this.height = this.node?.attrs?.height || image?.height

        new ResizeObserver(function () {
            
            
            component.width = component.$refs.imageholder.$el.clientWidth
            component.height = component.$refs.imageholder.$el.clientHeight
            component.updateAttributes({ width: component.$refs.imageholder.$el.clientWidth })
            component.updateAttributes({ height: component.$refs.imageholder.$el.clientHeight })
        }).observe(this.$refs.imageholder.$el)

        
        
        
        if (!this?.node?.attrs?.externalImagePath && this.imageSrc.includes("storage.googleapis")) this.issue = true
        else if (!this.imageSrc.includes("storage.googleapis") && isDataURL(this.imageSrc)) await this.uploadImage()
        else {
            
            this.updateAttributes({ src: this.imageSrc })
            this.updateAttributes({ externalImagePath: this.imageSrc })
        }
    },
    watch: {
        imageSrc(val) {
            
        }
    },
    methods: {
        setLock() {
            this.updateAttributes({ lock: true })
            this.lock = true
        },
        async uploadImage(options = {}) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                var imageSrc = options?.imageUrl || this.node.attrs.src
                imageSrc = convertOctetToPng(imageSrc)
                // var user = this.$authInstance.getUserInfo()
                
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var id = generateUniqueId()
                var request_body = { file: imageSrc, id: id }
                
                var raw = JSON.stringify(request_body);
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponse = await fetch(`${url}/images`, requestOptions)
                
                if (this.node?.attrs?.external || options?.imageUrl != undefined) {
                    
                    await fetch(`${url}/external_images`, requestOptions)
                    this.updateAttributes({ externalImagePath: `/img/${id}.png` })
                }
                var jsonResponse = await saveResponse.json()
                var { imageUrl } = jsonResponse
                this.imageSrc = imageUrl
                
                this.updateAttributes({ src: imageUrl })
            } catch (e) {
                
            }
        },
        setNewColors(val) {
            
            const { textColor, background } = val
            this.newColor = background
            this.newTextColor = textColor
            this.updateAttributes({ background: background })
        },
        setNewBp(val) {
            this.bp = val
            
            
            this.updateAttributes({ backgroundPosition: val })
        },
        setNewBackgroundImage(val) {
            
            this.newColor = val.previewUrl
            this.updateAttributes({ background: val.externalImage })
        },
        setNewHeight(val) {
            
            this.newHeight = val
            this.updateAttributes({ height: this.newHeight })
        },
        setNewWidth(val) {
            
            this.newWidth = val
            this.updateAttributes({ width: this.newWidth })
        },
        setStyle(val) {
            
            const style = cssToVueStyle(val)
            this.style = style
            if (val) this.updateAttributes({ customStyleString: val })
            if (val) this.updateAttributes({ customStyle: style })
            const { display, gap, alignContent, alignItems, flexDirection, justifyContent, flexWrap, flexFlow } = style
            if (val) this.nodeViewStyle = { display, gap, alignContent, alignContent, alignItems, flexDirection, justifyContent, flexWrap, flexWrap }
        },
        setGlassMorph(val) {
            this.glassMorph = val
            if (val) this.updateAttributes({ class: "glass-morph" })
            else this.updateAttributes({ class: "" })
        },
        async destory() {
            this.deleteNode()
            return
        },
        async duplicate() {
            const node = this.node.toJSON()
            
            this.editor.commands.insertContent(node)
            return
        },
        async getData(folderNode) {
            const component = this
            const urlValue = `${findStringInParentheses(component.node.attrs.background)}`.replace(/"/g, "").replace(/'/g, "")
            
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseOne = await fetch(`${url}/external_page?path=static/img`, requestOptions)
            var jsonResponseOne = await saveResponseOne.json()
            
            var currentImage = jsonResponseOne.docs.find(function (item) { return item.path.includes(urlValue) })
            
            this.newColor = `url('${currentImage.download_url}') no-repeat center center / cover`
        },
        async resolveIssue() {
            var token = await this.$authInstance.getToken()
            var imageSrc = this.node.attrs.src
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var raw = JSON.stringify({ url: imageSrc })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponse = await fetch(`${url}/test`, requestOptions)
            
            //this.imageSrc = saveResponse
            var json = await saveResponse.json()
            
            //this.imageSrc = json.imageUrl
            await this.uploadImage({ imageUrl: json.imageUrl })
            this.issue = false

        },
        onResize: function (x, y, width, height) {
            this.x = x
            this.y = y
            this.width = width
            this.height = height
        },
        onDrag: function (x, y) {
            this.x = x
            this.y = y
        },
        resizeAspectRatio(grow) {
            let calcW;
            let calcH;
            if (grow) {
                calcH = this.$refs.resizableImg.height + 1;
            } else {
                calcH = this.$refs.resizableImg.height - 1;
            }
            calcW = calcH * this.aspectRatio;
            this.updateAttributes({ width: calcW, height: calcH });
        }
    }
};
</script>

<style lang="scss">
.image-holder {
    overflow: auto;
}

.draggable-item {
    display: flex;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    background: white;
    box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.05),
        0px 10px 20px rgba(0, 0, 0, 0.1),
    ;

    .el-image {
        min-width: 100%;
        min-height: 100%;
    }

    .more-handle {
        flex: 0 0 auto;
        position: relative;
        width: 1rem;
        height: 1rem;
        top: 0.3rem;
        margin-right: 0.5rem;
        cursor: grab;
        background-image: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .lock {
        flex: 0 0 auto;
        position: relative;
        width: 1rem;
        height: 1rem;
        top: 0.3rem;
        margin-right: 0.5rem;
        cursor: grab;
        background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M224 448a32 32 0 0 0-32 32v384a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V480a32 32 0 0 0-32-32H224zm0-64h576a96 96 0 0 1 96 96v384a96 96 0 0 1-96 96H224a96 96 0 0 1-96-96V480a96 96 0 0 1 96-96z"></path><path fill="currentColor" d="M512 544a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V576a32 32 0 0 1 32-32zm192-160v-64a192 192 0 1 0-384 0v64h384zM512 64a256 256 0 0 1 256 256v128H256V320A256 256 0 0 1 512 64z"></path></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }



    .content {
        flex: 1 1 auto;
    }
}
</style>