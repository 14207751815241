<template>
  <el-card class="box-card">
    <figure class="pt-5">
      <h3 class="text-left">What problems are you trying to solve.</h3>
    </figure>
    <h6 class="pt-5">How does your company and team solve the problems of users</h6>
    <el-input class="pt-2" v-model="editor" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea" :placeholder="editor" />
  </el-card>

</template>
<script>

export default {
  props: {
    users: Array
  },
  data: () => ({
    editor: ""
  }),
  async mounted() {
    var lastItem = this.users[this.users.length - 1]
    var users_segment = ""
    for (const user of this.users) {
      var word = " "
      if (this.users.length == 1) users_segment += user + "."
      else if (user == lastItem) users_segment += word + "and " + user + ""
      else users_segment += word + user + ","
    }
    this.editor = `Our team/org helps ${users_segment} deal with ...`
  }
}

</script>