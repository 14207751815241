<template>
    <node-view-wrapper v-if="node.attrs.text" @mouseover="hover = true" @mouseleave="hover = false" class="ai-block">
        <el-input v-model="command" class="w-50 m-2" placeholder="Ask the AI to generate"  @keyup.enter="updateBlock()">
        <template #prefix>
          <span>🦾🤖</span>
        </template>
      </el-input>
    </node-view-wrapper>
    <node-view-wrapper v-else @mouseover="hover = true" @mouseleave="hover = false" class="ai-block">
        <el-affix v-if="hover" position="bottom" target=".ai-block" :offset="100">
            <el-row>
                <el-col :span="12">
                    <div class="drag-handle" contenteditable="false" draggable="true" data-drag-handle>
                    </div>
                </el-col>
            </el-row>
        </el-affix>

        <div v-if="fullRender != true" class="content">
            <span class="label">AI Block</span>
            <div class="card shadow-xl">
                <div class="card-body">
                    <!-- <el-input v-model="command" placeholder="add command" /> -->
                    <textarea v-model="command" class="textarea textarea-primary"
                        placeholder="ask your question"></textarea>
                    <h3 v-if="loading">Loading 🤖....</h3>
                    <h3 v-if="error" style="color: red;">This was an error</h3>
                    <pre @input="handleInput" ref="codeBlock" contenteditable="true"><code></code></pre>
                    <!-- <div @change="testMethod" v-html="output"></div> -->
                    <div class="card-actions justify-end">
                        <el-button v-if="output && isNotText" @click="reRender()">Re Render</el-button>
                        <el-button v-if="output" @click="enhance()">Enhance generated Div</el-button>
                        <el-button v-if="output" @click="reset()">reset</el-button>
                        <el-button v-if="output" @click="insertContent()">insert</el-button>
                        <el-button v-if="output && isNotText" @click="insertRawHtml()">insert Raw Html</el-button>
                        <el-button @click="updateBlock()">Run Command</el-button>
                    </div>
                </div>
            </div>
        </div>
        <el-button v-if="fullRender != true" @click="sizeToFit">
            Resize
        </el-button>
        <iframe v-if="isNotText" width="100%" height="100%" style="resize: both; overflow: auto;" ref="editor"></iframe>
    </node-view-wrapper>
</template>

<script>
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import { CodeBlock } from '@tiptap/extension-code-block'
import StarterKit from '@tiptap/starter-kit'

import CardStyleEditor from '../CardStyleEditor.vue'
import { Codemirror } from 'vue-codemirror'
import { html } from '@codemirror/lang-html'


var toParseAble = function (htmlString) {

    var html = htmlString
    const regex = /style\s*=\s*(['"])(.*?)\1/g;
    html = html.replace(regex, "customStyleString=\"$2\"");

    
    return html
}

export default {
    components: {
        NodeViewWrapper, CardStyleEditor, Codemirror, EditorContent
    },
    props: nodeViewProps,
    data: () => ({
        command: null,
        saved: null,
        output: null,
        hover: false,
        htmlEditor: null,
        initialized: false,
        fullRender: false,
        loading: false,
        error: false,
        code: ''
    }),
    watch: {
        output(val) {
            var notText = this.isNotText
            

            const codeBlock = this.$refs.codeBlock;
            codeBlock.textContent = val

            if (!notText) return
            const editor = this.$refs.editor;
            const style = `body {
                background: #121220;
            }
            h1, h2, h3, h4, h5, h6, p {
                color: white;
            }
            `
            //const style = Array.from(document.styleSheets).map(function(sheet) { return Array.from(sheet?.rules).flat()}).flat().map(function(item) { return item.cssText}).join()
            const html = `<html><head><style>${style}</style></head><body>${val}</body></html>`;
            editor.contentDocument.open();
            editor.contentDocument.write(html);
            editor.contentDocument.close();

            //     editor.style.height = editor.contentWindow.document.body.scrollHeight + 'px';


            //  // set the width of the iframe as the 
            //  // width of the iframe content
            //  editor.style.width  = editor.contentWindow.document.body.scrollWidth+'px';
        },
        initialized(val) {

        }
    },
    async mounted() {
        


        
        
        if (this.node.attrs.customHtml) {
            
            this.setHtml(this.node.attrs.customHtml)
        }
        
        if (this.$refs.editor) {
            const iframe = this.$refs.editor
            iframe.addEventListener('load', () => {
                
                const contentWindow = iframe.contentWindow;
                const contentDocument = contentWindow.document;
                
                // Set the height of the iframe to the height of the content
                
                
                
                iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`
            });

        }

        var component = this
        this.command = this?.node?.attrs?.command
        this.htmlEditor = new Editor({
            async onUpdate({ editor }) {
                const text = await editor.getText()
                
                component.$emit("style", text)
            },
            editorProps: {
                handleClickOn({ view }) {
                    
                    
                }
            },
            spellcheck: false,
            extensions: [
                Document,
                Text,
                StarterKit,
                Dropcursor,
                CustomListItem,
                Focus.configure({
                    className: 'has-focus',
                    mode: 'all',
                }),
                CodeBlock
                    .extend({
                        addKeyboardShortcuts() {
                            return {
                                // ↓ your new keyboard shortcut
                                'Enter': (editorObject) => {
                                    
                                    const { editor } = editorObject
                                    return false
                                    // editor.commands.chain().focus().setHardBreak().run()
                                },
                            }
                        }
                    })
                    .configure({
                        exitOnArrowDown: false, exitOnTripleEnter: false, HTMLAttributes: {
                            class: 'language-html',
                        }
                    })
            ],
            content: ''
        })
        this.htmlEditor.commands.setCodeBlock()
        // 
        this.initialized = true
        // 
        // 
        // if(this.node.attrs.customHtml) {
        //     
        //     this.setHtml(this.node.attrs.customHtml)
        // }
    },
    methods: {
        testMethod() {
            
        },
        sizeToFit() {

            const iframe = this.$refs.editor
            
            
            iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`
        },
        insertRawHtml() {
            this.fullRender = true
            const iframe = this.$refs.editor
            const html = `<html>${iframe.contentDocument.documentElement.innerHTML}</html>`;
            
            this.updateAttributes({ customHtml: html })
        },
        handleInput(e) {
            
            const codeBlock = this.$refs.codeBlock;
            
            
            this.output = codeBlock.textContent

            //this.content = e.target.innerHTML
        },
        async reRender() {
            const codeBlock = this.$refs.codeBlock;
            this.output = codeBlock.textContent
        },
        async reset() {
            this.error = false
            this.output = null
        },
        async enhance() {
            const codeBlock = this.$refs.codeBlock;
            this.output = codeBlock.textContent
            var historicalMessage = `Here is the existing the code ${this.output}. Can you Modify or add the existing code with these instructions: ${this.command}`
            if (this.node?.attrs.text) historicalMessage = `here is the old message ${this.output}.  Can you keep that as context when working on this next ask: ${this.command}`
            await this.updateBlock({ historicalMessage })
        },
        async insertContent() {
            // var parseAble = toParseAble(this.output)
            // 
            this.editor.commands.insertContent(this.output)
        },
        async captureGeneration(val) {
            
        },
        setHtml(htmlString) {
            const editor = this.$refs.editor
            const html = `${htmlString}`;
            editor.contentDocument.open();
            editor.contentDocument.write(html);
            editor.contentDocument.close();
            this.fullRender = true
        },
        async updateBlock(options = {}) {
            this.error = false
            this.loading = true
            
            // this.updateAttributes({ saved: true })
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                const text = this.node?.attrs?.text
                var raw = JSON.stringify({ message: options.historicalMessage || this.command, text })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/proxy_url/message`, requestOptions)
                var result = await saveResponseTwo.json()
                

                this.output = result?.content
                this.command = ""
                this.loading = false
                if(this.node.attrs.text) {
                    this.editor.commands.insertContent(this.output)
                    this.deleteNode()
                }

            } catch (e) {
                this.error = true
                
            }
        }
    },
    computed: {
        isNotText() {
            return this?.node?.attrs?.text == false || this?.node?.attrs?.text == undefined
        }
    }
}
</script>

<style lang="scss">
.ai-block {
    background: transparent !important;
    border: 3px solid #0D0D0D;
    border-radius: 0.5rem;
    margin: 1rem 0;
    position: relative;
    overflow: auto;
}

.label {
    margin-left: 1rem;
    background-color: #0D0D0D;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.content {
    margin-top: 1.5rem;
    padding: 1rem;
}


</style>