<template>
    <el-card class="box-card">
        <figure class="pt-2">
            <h3 class="text-left">What are the related objects you will need? We already covered users, but think things
                other entities, databases tables, and etc.</h3>
        </figure>
        <figure class="pt-2">
            <h3 class="text-left">Type in the name of your related objects here:</h3>
        </figure>
        <el-select style="min-width: 80%;" v-model="entities" multiple filterable allow-create default-first-option
            :reserve-keyword="false" placeholder="Type Your Related Objects">
            <el-option v-for="item in entities" :key="item" :label="item" :value="item" />
        </el-select>

    </el-card>

    <el-card v-if="fullEntities.length > 0" v-for="(object, index) of fullEntities">


        <div v-if="allModels?.length > 0" class="card-body">
            <h2 class="card-title">{{ object.title }}</h2>
            <!-- <div class="mermaid someOtherClass">
                {{ classGraph }}
            </div> -->
            <div class="row">
                <div class="col-4">belongs to</div>
                <div class="col-8">
                    <!-- <q-select use-input use-chips multiple v-model="object.belongs_to" :options="allModels" /> -->
                    <el-select v-model="object.belongs_to" multiple filterable allow-create default-first-option
                        :reserve-keyword="false" :placeholder="`Models ${object.title} belongs to`">
                        <el-option v-for="item in allModels" :key="item" :label="item" :value="item" />
                    </el-select>
                </div>
            </div>
            <div class="row">
                <div class="col-4">has many</div>
                <div class="col-8">
                    <!-- <q-select use-input use-chips multiple v-model="object.has_many" :options="allModels" /> -->
                    <el-select v-model="object.has_many" multiple filterable allow-create default-first-option
                        :reserve-keyword="false" :placeholder="`Models ${object.title} has many of`">
                        <el-option v-for="item in allModels" :key="item" :label="item" :value="item" />
                    </el-select>
                </div>
            </div>
            <figure class="pt-5">
                <h3 class="text-left">Allowed Actions on {{ object.title }}</h3>
            </figure>
            <el-checkbox-group v-model="object.actions" size="large" :title="`Allowed Actions on ${object.title}`">
                <el-checkbox-button v-for="(action, index) in actions" :key="index" :label="action">
                    {{ action }}
                </el-checkbox-button>
            </el-checkbox-group>
        </div>
    </el-card>
    <el-button v-if="fullEntities.length > 0" type="primary" @click="generateTemplate()" plain>Generate Template</el-button>
    <div v-if="template" v-for="(entity, index) of fullEntities">
        <div style="color: white;" class="mermaid someOtherClass">
            erDiagram
            {{generateTemplateForItem(entity)}}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        users: Array
    },
    async mounted() {
    },
    computed: {
        allModels() {
            var items = [...this.users, ...this.entities]
            
            return items
        }
    },
    methods: {
        async generateTemplate() {
            this.template = true
            await this.$mermaidInstance.mermaid.init({ noteMargin: 10 }, ".someOtherClass");
        },
        generateTemplateForItem(entity) {
            entity.template = ``
            for(const hasMany of entity.has_many) {
                entity.template += `\n${entity.title} ||--o{ ${hasMany} : "has many"`
            }
            for(const belongsTo of entity.belongs_to) {
                entity.template += `\n${entity.title} }o--|| ${belongsTo} : "belongs to"`
            } 


            return entity.template
        }
    },
    watch: {
        entities(val) {
            this.fullEntities = []
            for (const object of val) {
                this.fullEntities.push({ title: object, belongs_to: [], many_to_many: [], has_many: [], actions: [], template: "" })
            }
            
        },
        async fullEntities(val) {

        }
    },
    data: () => ({
        entities: [],
        display: "display: none",
        fullEntities: [],
        template: null,
        actions: ["create", "delete", "post", "put", "read", "get"],
        classGraph: `erDiagram
    CAR ||--o{ NAMED-DRIVER : allows
    CAR {
        string allowedDriver FK "The license of the allowed driver"
        string registrationNumber
        string make
        string model
    }
    PERSON ||--o{ NAMED-DRIVER : is
    PERSON {
        string driversLicense PK "The license #"
        string firstName
        string lastName
        int age
    }`
    }),

}

</script>