<template>
    <node-view-wrapper class="vue-component">
        <span class="label">Context Block</span>
        <div class="content">
            <div class="context-bloc card shadow-xl">
                <div class="card-body">
                    <div class="card-actions justify-end">
                        <div class="dropdown dropdown-end">
                            <label tabindex="0">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                </svg>
                            </label>
                            <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                                <li><a @click="saved = null">Edit</a></li>
                            </ul>
                        </div>
                    </div>
                    <el-row>
                        <el-col :span="24">
                            <h3>File type</h3>
                            <el-select-v2 v-if="options.length > 0" v-model="value" :options="options"
                                placeholder="Please select" style="width: 100%" />
                        </el-col>
                    </el-row>
                    <br>
                    <el-row v-if="value == 'View' || value == 'Subview'">
                        <el-col :span="8">
                            <h3>Users</h3>
                            <el-select-v2 v-model="user" :options="usersOptions" placeholder="Which Users use this view"
                                allow-create style="width: 100%" />
                        </el-col>
                        <el-col :span="8">
                            <h3>Auth Required</h3>
                            <el-select-v2 v-model="auth" :options="authOptions" placeholder="What Auth is required?"
                                allow-create style="width: 100%" />
                        </el-col>
                        <el-col v-if="value == 'View'" :span="8">
                            <h3>Associated Route</h3>
                            <el-input v-model="route" placeholder="What Route is Associated with this view " />
                        </el-col>
                        <el-col v-if="value == 'Subview'" :span="8">
                            <h3>Associated Prent View</h3>
                            <el-input v-model="parentComponentValue"
                                placeholder="What Parent View is Associated with this view " />
                        </el-col>
                    </el-row>
                    <br>
                    <el-row v-if="value != null">
                        <h3>External Depenedencies and Interactions</h3>
                        <el-select-v2 v-model="externalSources" :options="externalOptions"
                            placeholder="Select External Sources" style="width: 100%; vertical-align: middle"
                            allow-create filterable multiple clearable />
                    </el-row>
                    <div v-if="saved != true" class="card-actions justify-end">
                        <button @click="updateBlock()" class="btn btn-primary">update</button>
                    </div>
                </div>
            </div>
        </div>
    </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import { getFileName } from "../../helper_functions/friendlyNamesForPaths"
export default {
    components: {
        NodeViewWrapper,
    },
    props: nodeViewProps,
    data: () => ({
        options: [{ label: "View", value: "View" }, { label: "Subview", value: "Subview" }, { label: "Helpers", value: "Helpers" }, { label: "Class/Classes", value: "Class/Classes" }],
        updatedFiles: [],
        usersOptions: [{ label: "All Users", value: "All Users" }, { label: "Admin Users", value: "Admin Users" }, { label: "Anonymous Users", value: "Anonymous Users" }],
        user: "",
        route: "",
        authOptions: [{ label: "yes", value: "yes" }, { label: "no", value: "no" }, { label: "admin auth", value: "admin auth" }],
        auth: "",
        parentComponentValue: "",
        description: '',
        externalOptions: [{ label: "stripe", value: "stripe" }, { label: "firebase", value: "firebase" }, { label: "mongodb", value: "mongodb" }],
        externalSources: [],
        external_sources: null,
        value: null,
        saved: null
    }),
    watch: {
        externalSources(val) {
            
        }
    },
    async mounted() {
        var component = this
        
        if (this.node.attrs.file_type) this.value = this.node.attrs.file_type
        if (this.node.attrs.requires_auth) this.auth = this.node.attrs.requires_auth
        if (this.node.attrs.user) this.user = this.node.attrs.user
        if (this.node.attrs.route) this.route = this.node.attrs.route
        if (this.node.attrs.parent_component) this.parentComponentValue = this.node.attrs.parent_component
        if (this.node.attrs.external_sources) {
            this.externalSources = this.node.attrs.external_sources
            var otherOptions = this.node.attrs.external_sources.map(function (item) {
                return { label: item, value: item }
            })
            this.externalOptions = [...this.externalOptions, ...otherOptions]
        }

        if (this.node.attrs.saved) this.saved = this.node.attrs.saved
        // await this.$vscodeInstance.vscode.postMessage({
        //     command: 'get_the_files',
        //     file: this.node.attrs.file
        // })

        // window.addEventListener('message', event => {
        //     if (event?.data?.command == "sent_files") {
        //         var files = event?.data?.files
        //         var fileOptions = files.map(function (file) {
        //             var label = component.$vscodeInstance.returnFileName(file.path)
        //             return { "label": label, "value": file.path }
        //         })
        //         component.options = fileOptions
        //     }
        // })
    },
    methods: {
        updateBlock() {
            
            if (this.value) this.updateAttributes({ file_type: this.value })
            if (this.auth) this.updateAttributes({ requires_auth: this.auth })
            if (this.user) this.updateAttributes({ user: this.user })
            if (this.route) this.updateAttributes({ route: this.route })
            if (this.parentComponentValue) this.updateAttributes({ parent_component: this.parentComponentValue })
            if (this.externalSources) this.updateAttributes({ external_sources: this.externalSources })
            this.updateAttributes({ saved: true })
        },
        friendlyName(file) {
            return getFileName(file)
        },
        increase() {
            this.updateAttributes({
                count: this.node.attrs.count + 1,
            })
        },
    },
}
</script>

<style lang="scss">
.vue-component {
    background: #736BF5;
    border: 3px solid #0D0D0D;
    border-radius: 0.5rem;
    margin: 1rem 0;
    position: relative;

    .label {
        margin-left: 1rem;
        background-color: #0D0D0D;
        font-size: 0.6rem;
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
        position: absolute;
        top: 0;
        padding: 0.25rem 0.75rem;
        border-radius: 0 0 0.5rem 0.5rem;
    }
}

.context-bloc {
    background: #736BF5;
}



.content {
    margin-top: 1.5rem;
    padding: 1rem;
}
</style>