<template>
    <el-drawer v-model="aiElementDialog" direction="rtl" title="Add Custom CSS" style="color: white">

        <el-card>
            <span class="label">Shell Block</span>
            <el-button @click="loadHtml">Load</el-button>
            <div class="content">
                <div class="card shadow-xl">
                    <div class="card-body">
                        <el-input v-model="command" placeholder="add command" />
                        <pre contenteditable="true"><code contenteditable="true" ref="dialogCodeBlock"></code></pre>
                        <div v-if="output" v-html="output"></div>
                        <div class="card-actions justify-end">
                            <el-button v-if="output" @click="reRender()">Re Render</el-button>
                            <el-button v-if="output" @click="enhance()">Enhance generated Div</el-button>
                            <el-button v-if="output" @click="reset()">reset</el-button>
                            <el-button v-if="output" @click="insertContent()">insert</el-button>
                            <el-button @click="updateBlock()">Run Command</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="aiElementDialog = false">Close</el-button>
                <el-button type="primary" @click="addElement">submit</el-button>
            </span>
        </template>
    </el-drawer>
</template>

<script>


import Document from '@tiptap/extension-document'
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu, BubbleMenu } from '@tiptap/vue-3'
import aiBlock from '@/components/custom_extensions/aiBlock'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import CodeBlockComponent from './CodeBlock.vue'
import Focus from '@tiptap/extension-focus'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Link from '@tiptap/extension-link'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Gapcursor from '@tiptap/extension-gapcursor'
import ReferenceMark from './custom_extensions/ReferenceMark'
import ResizableImage from './custom_extensions/ImageUpload'
import MermaidBlock from './custom_extensions/MermaidBlock'
import Dropcursor from '@tiptap/extension-dropcursor'
import FormulaComponent from './custom_extensions/FormulaExtension.js'
import FormulaMark from './custom_extensions/FormulaMark.js'
import Commands from './custom_extensions/commands'
import suggestion from './custom_extensions/suggestion'
import { Heading } from '@tiptap/extension-heading'

import Columns from './custom_extensions/Columns'


// load specific languages only
import { lowlight } from 'lowlight'
import ReferenceDrawer from './ReferenceDrawer.vue'
import { Expand } from '@element-plus/icons-vue'
import DocumentsNav from './DocumentsNav.vue'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import { ColumnExtension } from "@gocapsule/column-extension";
import { Menu } from '@element-plus/icons-vue'

import TextAlign from '@tiptap/extension-text-align'
import addElementDialog from './addElementDialog.vue'
import CustomCssMark from './custom_extensions/customCssMark'
import Button from './custom_extensions/button.js'


import CardStyleEditor from './CardStyleEditor.vue'
import { Codemirror } from 'vue-codemirror'
import { html } from '@codemirror/lang-html'
import DraggableItem from './custom_extensions/DraggableItem'
import { onUpdated } from 'vue'
import { load } from '@lyrasearch/lyra'


const CustomHeading = Heading.extend({
    get schema() {
        return {
            attrs: {
                level: {
                    default: 1,
                },
                customStyleString: {
                    default: null
                }
            },
            content: 'inline*',
            group: 'block',
            defining: true,
            draggable: false,
            toDOM: node => [`h${node.attrs.level}`, {
                style: `${node?.attrs?.customStyleString}`
            }, 0],
        }
    }
})


var toParseAble = function (htmlString) {

    var html = htmlString
    const regex = /style\s*=\s*(['"])(.*?)\1/g;
    html = html.replace(regex, "customStyleString=\"$2\"");

    
    return html
}

export default {
    components: {
        CardStyleEditor, Codemirror, EditorContent
    },
    props: {show: Boolean, node: Object},
    data: () => ({
        command: null,
        aiElementDialog: null,
        saved: null,
        output: null,
        htmlEditor: null,
        code: ''
    }),
    watch: {
        output(val) {
            
       
            const dialogCodeBlock = this.$refs.dialogCodeBlock
            if(dialogCodeBlock) dialogCodeBlock.textContent = val
        }
    },
    async mounted() {
        const node = this.node
        
        const docForm = node.toJSON()
        
        var tempEditor = new Editor({content: '', extensions: [
                aiBlock,
                Button,
                Document.extend({
                   addKeyboardShortcuts() {
                        return {
                            // ↓ your new keyboard shortcut
                            'Shift-Mod-p': ({editor}) => {
                                component.editMode = !component.editMode
                                localStorage.setItem("editMode", JSON.stringify({"edit": component.editMode}))
                                // var currentEditMode = await localStorage.getItem("editMode")
                                // await localStorage.getItem("editMode")
                                return
                            },
                            'Shift-Control-p': () => {
                                return
                            },
                        }
                    },
                }),
                Columns,
                Paragraph.extend({
                    addAttributes() {
                        return {
                            customStyleString: {
                                default: '', renderHTML: attributes => {
                                    // … and return an object with HTML attributes.
                                    return {
                                        style: `${attributes.customStyleString}`,
                                    }
                                }
                            }
                        }
                    },
                    addStorage() {
                        return {
                            awesomeness: 100,
                        }
                    },
                    onUpdate() {
                        this.storage.awesomeness += 1
                    }
                }),
                CustomHeading,
                Text,
                Link.extend({
                    addAttributes() {
                        return {
                            customStyleString: {
                                default: '', renderHTML: attributes => {
                                    // … and return an object with HTML attributes.
                                    return {
                                        style: `${attributes.customStyleString}`,
                                    }
                                }
                            }
                        }
                    }
                }),
                ReferenceMark,
                StarterKit.configure({
                    heading: false
                }),
                TaskList,
                Dropcursor,
                CustomCssMark,
                FormulaComponent,
                TextStyle,
                Color,
                DraggableItem,
                Gapcursor,
                ColumnExtension,
                Table.configure({
                    resizable: true,
                }),
                TableRow,
                TableHeader,
                TableCell,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                Commands.configure({
                    suggestion,
                }),
                FormulaMark,
                ReferenceMark,
                MermaidBlock.configure({ saved: "yeah yeah" }),
                ResizableImage.configure({ allowBase64: true, inline: true }),
                TaskItem.configure({
                    nested: true,
                }),
                Focus.configure({
                    className: 'has-focus',
                    mode: 'all',
                }),
                CodeBlockLowlight
                    .extend({
                        addNodeView() {
                            return VueNodeViewRenderer(CodeBlockComponent)
                        },
                    })
                    .configure({ lowlight }),
            ],})
        await tempEditor.commands.insertContent(docForm)
        const html = await tempEditor.getHTML()
        
        var component = this
        this.aiElementDialog = this.show
       this.output = html
       window.addEventListener("load", () => this.loadHtml());
    },
    methods: {
        async reRender() {
            
            
            const dialogCodeBlock = this.$refs.dialogCodeBlock;
            this.output = dialogCodeBlock.textContent
        },
        async reset() {
            this.output = null
        },
        async enhance() {
            const dialogCodeBlock = this.$refs.dialogCodeBlock;
            this.output = dialogCodeBlock.textContent
            var historicalMessage = `Here is the existing the code ${this.output}. Can you Modify or add the existing code with these instructions: ${this.command}`
            await this.updateBlock({ historicalMessage })
        },
        async insertHtml(html) {
            const dialogCodeBlock = this.$refs.dialogCodeBlock;
            dialogCodeBlock.textContent = html
            return
        },
        async loadHtml() {
            const dialogCodeBlock = this.$refs.dialogCodeBlock;
            dialogCodeBlock.textContent = this.output
        },
        async insertContent() {
            var parseAble = toParseAble(this.output)
            
            this.editor.commands.insertContent(`${parseAble}`)
        },
        async captureGeneration(val) {
            
        },
        async updateBlock(options = {}) {
            
            // this.updateAttributes({ saved: true })
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var raw = JSON.stringify({ message: options.historicalMessage || this.command })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/proxy_url/message`, requestOptions)
                var result = await saveResponseTwo.json()
                

                this.output = result?.content
                this.command = ""


            } catch (e) {
                
            }
        }
    },
}
</script>

<style lang="scss">
.shell-block {
    background: #736BF5;
    border: 3px solid #0D0D0D;
    border-radius: 0.5rem;
    margin: 1rem 0;
    position: relative;
}

.label {
    margin-left: 1rem;
    background-color: #0D0D0D;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.content {
    margin-top: 1.5rem;
    padding: 1rem;
}
</style>