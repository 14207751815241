<template>
    <el-drawer ref="drawerRef" v-model="show" :before-close="handleClose" direction="ltr">
        <template #header="{ close, titleId, titleClass }">
            <h2>Your External Docs</h2>
        </template>
        <el-button-group>
        <el-button @click="refreshNav()"><el-icon><Refresh /></el-icon><span style="padding-left: 1em;">Check for Updated Documents</span> </el-button>
        <el-button @click="indexDocs()"><el-icon><Refresh /></el-icon><span style="padding-left: 1em;">Index Docs</span> </el-button>
        </el-button-group>
        <el-tree-v2 :data="treeData" :props="items" :height="1000">
            <template #default="{ node }">
                <div @click="getDocData(node)">
                    <el-icon>
                        <Document />
                    </el-icon>
                    <span>&nbsp</span>
                    <span>{{ node.label }}</span>
                </div>
            </template>
        </el-tree-v2>
    </el-drawer>

</template>


<script>



import { Document, Notebook, Refresh } from '@element-plus/icons-vue';
import { defaultMarkdownParser, schema } from 'prosemirror-markdown';

export default {
    watch: {
        show(val) {
            if (!val) this.$emit("close")
        }
    },
    props: {
        title: String,
        dialog: Boolean
    },
    components: {
        Document, Notebook, Refresh
    },
    data: () => ({
        treeData: [{ id: "lol", label: 'lol', children: [{ id: "lolhu", label: 'lolhu' }] }],
        items: {
            value: 'id',
            label: 'label',
            children: 'children',
        },
        default_nav_types: ["guides", "blog", "sample apps", "concepts"],
        currentFile: null,
        show: null,
    }),
    async mounted() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        
        const component = this
        this.currentFile = this.title
        this.show = this.dialog
        if (localStorage.getItem("tree_data") && !params.section) return this.treeData = JSON.parse(localStorage.getItem("tree_data"))
        else if (localStorage.getItem(`${params.section}_tree_data`)) return this.treeData = JSON.parse(localStorage.getItem(`${params.section}_tree_data`))
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var org = await this.$authInstance.getOrg()
            var token = await this.$authInstance.getToken()
            // var user = this.$authInstance.getUserInfo()
            
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseTwo;
            if (params.section) saveResponseTwo = await fetch(`${url}/external_page?path=docs/${params.section}`, requestOptions)
            else saveResponseTwo = await fetch(`${url}/external_page?section=docs`, requestOptions)
            var jsonResponse = await saveResponseTwo.json()
            
            var newTreeData = []
            for (const item of jsonResponse.docs) {
                var newNodeData = { id: item.name, label: item.name, sha: item?.sha, path: item?.path }
                if (!item.path.includes('.')) {
                    newNodeData.folder = true
                    var childNodes = await component.handleFolderNodes(newNodeData)
                    newNodeData.children = childNodes
                }
                newTreeData.push(newNodeData)

            }
            this.treeData = newTreeData
            if(!params.section) localStorage.setItem('tree_data', JSON.stringify(newTreeData))
            else if(params.section) localStorage.setItem(`${params.section}_tree_data`, JSON.stringify(newTreeData))
        } catch (e) {
            
        }


    },
    methods: {
        async handleClose() {
            this.$emit("close")
        },
        async refreshNav() {
            const component = this
            const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
            });
            try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var org = await this.$authInstance.getOrg()
            var token = await this.$authInstance.getToken()
            // var user = this.$authInstance.getUserInfo()
            
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseTwo;
            if (params.section) saveResponseTwo = await fetch(`${url}/external_page?path=docs/${params.section}`, requestOptions)
            else saveResponseTwo = await fetch(`${url}/external_page?section=docs`, requestOptions)
            var jsonResponse = await saveResponseTwo.json()
            
            var newTreeData = []
            for (const item of jsonResponse.docs) {
                var newNodeData = { id: item.name, label: item.name, sha: item?.sha, path: item?.path }
                if (!item.path.includes('.')) {
                    newNodeData.folder = true
                    var childNodes = await component.handleFolderNodes(newNodeData)
                    newNodeData.children = childNodes
                }
                newTreeData.push(newNodeData)

            }
            this.treeData = newTreeData
            if(!params.section) localStorage.setItem('tree_data', JSON.stringify(newTreeData))
            else if(params.section) localStorage.setItem(`${params.section}_tree_data`, JSON.stringify(newTreeData))
        } catch(e) {
            
        }

        },
        async handleFolderNodes(folderNode) {
            const component = this
            
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseTwo = await fetch(`${url}/external_page?path=${folderNode.path}&sha=${folderNode.sha}`, requestOptions)
            var jsonResponse = await saveResponseTwo.json()
            
            var childNodes = jsonResponse.docs.map(function (item) {
                var newNodeData = { id: item.name, label: item.name, sha: item?.sha, path: item?.path }
                return newNodeData
            })
            return childNodes
        },
        async indexDocs() {
            const component = this
            const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
            });
            try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var org = await this.$authInstance.getOrg()
            var token = await this.$authInstance.getToken()
            // var user = this.$authInstance.getUserInfo()
            
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseTwo;
            saveResponseTwo = await fetch(`${url}/external_page?path=docs/${params.section}`, requestOptions)
            var jsonResponse = await saveResponseTwo.json()
            
            for (const item of jsonResponse.docs) {
                var newNodeData = { id: item.name, label: item.name, sha: item?.sha, path: item?.path }
                
                const data = await component.getDocData({data: newNodeData}, {index: true})
                
                const stringedData = JSON.stringify({content: data, ...newNodeData})
                var requestIndexOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: stringedData
            };
                await fetch(`${url}/external_pages/index`, requestIndexOptions)
            }
            
        } catch(e) {
            
        }

        },
        async handleFolderClick(clickedNode) {
            const component = this
            
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseTwo = await fetch(`${url}/external_page?path=${clickedNode?.data?.path}&sha=${clickedNode?.data?.sha}`, requestOptions)
            var jsonResponse = await saveResponseTwo.json()
            
            var childNodes = jsonResponse.docs.map(function (item) {
                return item
            })
            this.treeData.map(function (item) {
                var existingNode = component.treeData.find(function (item) { return item?.path === clickedNodeNode?.data?.path })
                if (existingNode) return { ...existingNode, children: [] }
            })
        },
        async postToParser(content) {
            const component = this
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({content: content, parse: "html"})
            };
            var url = await this.$authInstance.getBaseUrl()
            const response = await fetch(`${url}/parse`, requestOptions)
            const json = await response.json()
            return json
        },
        async handleMapping(docContent) {
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            var jsonText;
            var textNode;
            if(docContent.includes("Editor")) {
                jsonText = docContent.split('\n')[1].trim().split("data = ")[1]
                textNode = JSON.parse(jsonText)
            } else {
                jsonText = ""
                const htmlContent = await this.postToParser(docContent)
                return htmlContent.content
            }
            if(jsonText.includes("Image")) {
                myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var raw = JSON.stringify(textNode)
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseTwo = await fetch(`${url}/external_doc/remap_image`, requestOptions)
            var jsonResponse = await saveResponseTwo.json()
            
            return jsonResponse
            } else {
                return textNode
            }
    

        },
        async getDocData(clickedNode, options = {}) {
            

            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseTwo = await fetch(`${url}/external_page?path=${clickedNode?.data?.path}&sha=${clickedNode?.data?.sha}`, requestOptions)
            var jsonResponse = await saveResponseTwo.json()
            
            if(jsonResponse?.docs?.markdown) {
                this.$emit("loaded_doc", { title: clickedNode?.data?.label, documentData: jsonResponse?.docs?.markdown})
                return
            }
            if(options.index) return atob(jsonResponse.docs.content, 'base64')
            if (clickedNode.data.folder) return this.handleFolderClick(clickedNode)
            var retrievedDoc = jsonResponse.docs
            var docContent = atob(retrievedDoc.content, 'base64')
            var textNode = await this.handleMapping(docContent)
            //var textNode = JSON.parse(docContent.split('\n')[1].trim().split("data = ")[1])
            
            this.$emit("loaded_doc", { title: clickedNode?.data?.label, documentData: textNode})
        }
    }
}

</script>