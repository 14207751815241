<!-- components/PopularPrompts.vue -->
<template>
    <div class="flex-col justify-start items-start gap-2.5 inline-flex">
        <div class="self-stretch text-white/50 text-sm font-normal font-['Inter'] leading-[21px]">
            {{ title || 'Popular prompts:' }}
        </div>
        <div class="self-stretch flex-col justify-start items-start gap-[0.62rem] flex">
            <div
                v-for="(prompt, index) in items"
                :key="index"
                @click="selectPrompt(prompt)"
                class="px-[0.44rem] py-[0.08rem] bg-[#7984eb]/25 rounded-[0.5rem] border border-[#7984eb] justify-start items-start gap-2.5 inline-flex cursor-pointer hover:bg-[#7984eb]/40">
                <div class="text-[#d3d3d3] text-xs font-normal font-['Inter'] leading-[18px]">
                    {{ prompt }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopularPrompts',
    props: {
        items: {
            type: Array,
            required: true
        },
        appendMode: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        modelValue: {
            type: String,
            default: ''
        }
    },
    methods: {
        selectPrompt(prompt) {
            if (this.appendMode && this.modelValue) {
                this.$emit('update:modelValue', `${this.modelValue}, ${prompt}`);
            } else {
                this.$emit('update:modelValue', prompt);
            }
        }
    }
}
</script>