<template>
    <el-input size="large" @keyup.enter.native="onEnter" @click="this.dialog = true" v-model="search" class="w-80 m-2 search-text"
        placeholder="Search">
        <template #prefix>
            <el-icon class="el-input__icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                        d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z" />
                </svg>
            </el-icon>
        </template>
    </el-input>
    <el-dialog v-model="dialog" title="Search Results">
        <el-input size="large" @keyup.enter.native="onEnter" v-model="search" class="w-80 m-2 search-text"
            placeholder="Search">
            <template #prefix>
                <el-icon class="el-input__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                            d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z" />
                    </svg>
                </el-icon>
            </template>
        </el-input>
        <el-card v-for="(project, index) of results" class="box-card pt-2 pb-2 mb-2">
            <figure v-if="project?.document.file" class="pt-2">
                <router-link @click="dialog = false"
                    :to="{ path: generateIdLink(project?.document), query: { doc: project?.document.file } }">
                    <h3 class="text-left">Context doc for {{ project?.document.file }} in {{ project?.document.repo  }}</h3>
                </router-link>
            </figure>
            <figure v-else class="pt-2">
                <router-link @click="dialog = false"
                    :to="{ path: generateIdLink(project?.document), query: { doc: project?.document.repo + '-' + project?.document.doctype } }">
                    <h3 class="text-left">{{ project?.document.doctype }} document </h3>
                </router-link>
            </figure>
            <figure>
                <h4 class="text-left">Type: {{ project?.document.doctype }}</h4>
            </figure>
        </el-card>

        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialog = false">Cancel</el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>

import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
export default {
    components: {
        Search
    },
    methods: {
        generateIdLink(document) {
            const repo = document.repo
            var encodedStringBtoA = btoa(repo);
            if(document?.file) {
                var encodedFile = btoa(document?.file)
                return `/docs/${encodedStringBtoA}/${encodedFile}`
            } else return `/docs/${encodedStringBtoA}`
        },
        async onEnter() {
            
            await this.fetchResults()
            this.dialog = true
        },
        async getDocs() {
            try {

                if (!this.$search?.db) {
                    const db = await this.$search.initSearchDb()
                    this.$search.db = db
                }
                if (this.$search?.db?.docsCount <= 0 || this.$search?.db == undefined) {
                    var myHeaders = new Headers();
                    var idtoken = await this.$authInstance.getToken()
                    var url = await this.$authInstance.getBaseUrl()
                    myHeaders.append("Authorization", `Bearer ${idtoken}`);

                    var requestOptions = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    var codebaseRes = await fetch(`${url}/docs`, requestOptions)
                    var codebasesJson = await codebaseRes.json()
                    const { data } = codebasesJson
                    await this.$search.seedData(data)
                    
                    
                    this.projects = cutItemsBy4(codebasesJson.data)

                } else {
                    return
                }


            } catch (e) {
                
            }
        },
        async fetchResults() {
            
            await this.getDocs()
            var result = await this.$search.search(this.search)
            
            const { hits } = result
            this.results = hits
            this.search = ""
        }
    },
    setup() {
        const filterValue = ref("")
        const search = ref("")
        const results = ref([])
        const options = ref(["tags", "file", "team"])
        const dialog = ref(false)
        return { options, filterValue, search, dialog, results }
    }
}

</script>

<style lang="scss">
.search-text {
    width: 100% !important;
    background-color: #191D27;
    .el-input__wrapper {
        background-color: #191D27
    }
}
</style>