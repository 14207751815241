<template>
    <node-view-wrapper class="draggable-image-item">
        <img @error="getImage()" :src="imageSrc" />
    </node-view-wrapper>
</template>

<script>

import { Editor, EditorContent } from '@tiptap/vue-3';
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';


export default {
  components: {
    EditorContent, NodeViewWrapper
  },
  props: nodeViewProps,
  data:() => ({imageSrc: null}),
  async mounted() {
    
   this.imageSrc = this.node?.attrs?.src
  },
  watch: {
    imageSrc(val) 
    {
        
        
    }
  },
  methods: {
    async getImage() {

        var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            var raw = JSON.stringify({url: this.node?.attrs?.src, node: true})
            myHeaders.append("Content-Type", "application/json");

            myHeaders.append("Authorization", `Bearer ${token}`)


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            
            var response = await fetch(`${url}/fetch_new_image`, requestOptions)
            const imageJson = await response.json()
            
            this.imageSrc = imageJson?.imageUrl
        
    }
  }
};
</script>