import {
    Editor,
    EditorContent,
    VueNodeViewRenderer,
    FloatingMenu,
    BubbleMenu,
  } from "@tiptap/vue-3";
  import aiBlock from "@/components/custom_extensions/aiBlock";
  import StarterKit from "@tiptap/starter-kit";
  import Document from "@tiptap/extension-document";
  import Paragraph from "@tiptap/extension-paragraph";
  import Text from "@tiptap/extension-text";
  import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
  import CodeBlockComponent from "@/components/CodeBlock.vue";
  import Focus from "@tiptap/extension-focus";
  import TaskItem from "@tiptap/extension-task-item";
  import TaskList from "@tiptap/extension-task-list";
  import Link from "@tiptap/extension-link";
  import Table from "@tiptap/extension-table";
  import TableCell from "@tiptap/extension-table-cell";
  import TableHeader from "@tiptap/extension-table-header";
  import TableRow from "@tiptap/extension-table-row";
  import Gapcursor from "@tiptap/extension-gapcursor";
  import ReferenceMark from "../components/custom_extensions/ReferenceMark.js";
  import ResizableImage from "../components/custom_extensions/ImageUpload";
  import MermaidBlock from "../components/custom_extensions/MermaidBlock";
  import Dropcursor from "@tiptap/extension-dropcursor";
  import FormulaComponent from "../components/custom_extensions/FormulaExtension.js";
  import FormulaMark from "../components/custom_extensions/FormulaMark.js";
  import Commands from "../components/custom_extensions/commands";
  import suggestion from "../components/custom_extensions/suggestion";
  import Columns from "../components/custom_extensions/Columns";
  import { Heading } from "@tiptap/extension-heading";
  import { lowlight } from "lowlight/lib/all";
  import TextStyle from "@tiptap/extension-text-style";
  import { Color } from "@tiptap/extension-color";
  import { ColumnExtension } from "@gocapsule/column-extension";
  import DraggableItem from "../components/custom_extensions/DraggableItem.js";
  import TextAlign from "@tiptap/extension-text-align";
  import CustomCssMark from "../components/custom_extensions/customCssMark";
  import Button from "../components/custom_extensions/button.js";
  import Placeholder from "@tiptap/extension-placeholder";
  import Iframe from "../components/custom_extensions/iframe.js";
  import Style from "../components/custom_extensions/style.js";
  import VsCodeImage from "@/components/custom_extensions/ReadOnlyImage.js";
  
  const reader = new FileReader();
  
  const CustomHeading = Heading.extend({
    get schema() {
      return {
        attrs: {
          level: {
            default: 1,
          },
          customStyleString: {
            default: null,
          },
        },
        content: "inline*",
        group: "block",
        defining: true,
        draggable: false,
        toDOM: (node) => [
          `h${node.attrs.level}`,
          {
            style: `${node?.attrs?.customStyleString}`,
          },
          0,
        ],
      };
    },
  });
  
  const editorConfig = {
    onSelectionUpdate({ editor, transaction }) {
      
      if (transaction?.curSelection?.empty != true) {
        editor.storage.draggableItem.disableDrag = true;
      } else {
        editor.storage.draggableItem.disableDrag = false;
      }
    },
    extensions: [
      aiBlock,
      Button,
      Iframe,
      Style,
      Document.extend({
        addKeyboardShortcuts() {
          return {
            // ↓ your new keyboard shortcut
            "Shift-Mod-p": ({ editor }) => {
              editor.storage.draggableItem.disableDrag =
                !editor.storage.draggableItem.disableDrag;
              // var currentEditMode = await localStorage.getItem("editMode")
              // await localStorage.getItem("editMode")
              return;
            },
            "Shift-Control-p": () => {
              return;
            },
          };
        },
      }),
      Columns,
      CustomHeading,
      Text,
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "heading") {
            return "What’s the title?";
          }
          return "Type / for actions, or cmd H for the full menu";
        },
      }),
      Link.configure({
        HTMLAttributes: {
          style: "",
        },
      }).extend({
        addAttributes() {
          return {
            customStyleString: {
              default: "",
              renderHTML: (attributes) => {
                // … and return an object with HTML attributes.
                return {
                  style: `${attributes.customStyleString}`,
                };
              },
            },
            href: {
              default: "",
            },
            style: {
              default: "",
            },
            class: {
              default: "",
            },
          };
        },
      }),
      ReferenceMark,
      StarterKit.configure({
        heading: false,
      }),
      TaskList,
      Dropcursor,
      CustomCssMark,
      FormulaComponent,
      TextStyle,
      Color,
      DraggableItem,
      Gapcursor,
      ColumnExtension,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Commands.configure({
        suggestion,
      }),
      FormulaMark,
      ReferenceMark,
      MermaidBlock.configure({ saved: "yeah yeah" }),
      VsCodeImage.configure({ allowBase64: true, inline: true }),
      TaskItem.configure({
        nested: true,
      }),
      Focus.configure({
        className: "has-focus",
        mode: "all",
      }),
      CodeBlockLowlight.extend({
        addNodeView() {
          return VueNodeViewRenderer(CodeBlockComponent);
        },
        addAttributes() {
          return {
              ...this.parent?.(), 
              url: {default: "" } 
          };
        },
      }).configure({ lowlight }),
    ],
    content: `<br><br><h1 class='font-ibm'>Lets goooo!</h1>`,
  };
  
  export default editorConfig;
  