<template>
    <button
        @click="toggleInput"
        :class="[
            'hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] flex flex-row items-center gap-[0.62rem] px-[0.62rem] py-[0.31rem]',
            showInput ? 'bg-white/20' : '',
        ]"
    >
        <img
            class="w-[1rem] h-[1rem]"
            :src="purpleAiIcon"
            alt="ai"
        />
        <div>
            Ask Dev-Docs AI anything
        </div>
    </button>

    <div
        v-if="showInput"
        v-on-click-outside="closeInput"
        class="absolute top-full left-0 mt-[1px] p-[0.63rem] flex flex-row gap-[0.64rem] rounded-[0.5rem] bg-[#1C1C1C]"
        style="box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);"
    >
        <div
            class="flex items-center self-start justify-center bg-white rounded-full p-[0.19rem]"
        >
            <img
                class="w-[1rem] h-[1rem]"
                :src="logoIcon"
                alt="logo"
            />
        </div>
        <Textarea
            autoResize
            cols="40"
            placeholder="Ask Dev-Docs AI anything"
            ref="aiTextarea"
            rows="1"
            v-model="userInput"
            class="!h-auto"
            @keydown.enter.prevent="handleEnterPress"
            @focus="handleTextareaFocus"
            @blur="handleTextareaBlur"
        />
        <button
            :disabled="buttonIsDisabled"
            @click="replaceContentWithAi(userInput)"
            :class="[
                'self-end',
            ]"
        >
            <img
                class="w-[1rem] h-[1rem]"
                :src="buttonIcon"
                alt="submit"
            />
        </button>
    </div>

</template>

<script setup>
import Textarea from 'primevue/textarea';
import { ref, computed } from 'vue';
import { vOnClickOutside } from '@vueuse/components'

import { getAiResponse } from '@/plugins/devdocsBackendService.js';

import logoIcon from '@/assets/icon-logo.png';
import purpleAiIcon from '@/assets/icon-ai-purple.svg';

import purpleRoundUpArrowIcon from '@/assets/icon-arrow-up-round-purple.svg';
import roundUpArrowIcon from '@/assets/icon-arrow-up-round.svg';

const props = defineProps({
    editor: {
        type: Object,
        required: true,
    },
});

const showInput = ref(false);
const aiTextarea = ref(null);
const userInput = ref('');
const storedSelection = ref(null);

const toggleInput = () => showInput.value = !showInput.value;
const closeInput = () => showInput.value = false;

const buttonIsDisabled = computed(() => !userInput.value);
const buttonIcon = computed(() => buttonIsDisabled.value ? roundUpArrowIcon : purpleRoundUpArrowIcon);

const replaceContentWithAi = async (aiPrompt) => {
    await props.editor.setEditable(false);
    try {
        const { view, state } = props.editor;
        const { from, to } = view.state.selection;
        const existingContent = state.doc.textBetween(from, to, '');

        const aiContent = await getAiResponse({
            aiPrompt,
            existingContent,
        });

        await props.editor.chain().focus().deleteRange({
            from: from,
            to: to,
        }).insertContent(
            aiContent,
            {
                at: from,
            },
        ).run();

    } catch (error) {
        console.error(error);
    } finally {
        props.editor.setEditable(true);
    }
};

const handleEnterPress = (event) => {
    if (event.shiftKey) {
        // event.preventDefault();
        // // same as press enter without shift
        return;
    }
    replaceContentWithAi(userInput.value);
};

const handleTextareaFocus = () => {
    const { view, state } = props.editor;
    const { from, to } = view.state.selection;
    const text = state.doc.textBetween(from, to, '');
    storedSelection.value = {
        from,
        to,
        text,
    };
}

const handleTextareaBlur = () => {
    if (storedSelection.value) {
        props.editor.commands.setTextSelection({
            from: storedSelection.value.from,
            to: storedSelection.value.to,
        });
        storedSelection.value = null;
    }
}
</script>