import rehypeStringify from "rehype-stringify";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";
import rehypeParse from 'rehype-parse'
import rehypeRemark from 'rehype-remark'
import remarkStringify from 'remark-stringify'

const divideContent = (content) => {
  const frontmatterRegex = /---\s*\n([\s\S]*?)\n---/;
  const match = content.match(frontmatterRegex);
  
  if (!match) {
    return {
      frontmatter: '',
      markdown: content.trim()
    };
  }

  const frontmatter = match[1];
  const markdown = content.slice(match[0].length).trim();
  
  return {
    frontmatter,
    markdown
  };
};

export async function markdownToHtml(fileContent) {
    try {
      let {markdown, frontmatter} = divideContent(fileContent);
      console.log('markdown', markdown)
      console.log('frontmatter', frontmatter)
      const file = await unified()
        .use(remarkParse) // Parse markdown content to a syntax tree
        .use(remarkRehype) // Turn markdown syntax tree to HTML syntax tree, ignoring embedded HTML
        .use(rehypeStringify) // Serialize HTML syntax tree
        .process(markdown);
      return {content: String(file), frontmatter};
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  export async function htmlToMarkdown(htmlContent) {
    try {
        const file = await unified()
            .use(rehypeParse)  // Parse HTML content to a syntax tree
            .use(rehypeRemark) // Turn HTML syntax tree to markdown syntax tree
            .use(remarkStringify) // Serialize markdown syntax tree
            .process(htmlContent);
        return String(file);
    } catch (error) {
        console.error(error);
        return "";
    }
}