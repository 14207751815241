import { authInfo } from "../plugins/authn.js";
import { markdownToHtml } from "../plugins/unifiedParserService.js";

const extractResponse = (input) => {
  const regex = /<response>([\s\S]*?)<\/response>/;
  const match = input.match(regex);

  if (match && match[1]) {
    return match[1].trim().trimStart().trimEnd();
  }
  return null;
};
export const getAiResponse = async ({ aiPrompt, existingContent }) => {
  if (!aiPrompt) {
    throw new Error("aiPrompt is required");
  }

  const [token, url] = await Promise.all([
    authInfo.getToken(),
    authInfo.getBaseUrl(),
  ]);

  if ([token, url].some((x) => !x)) {
    throw new Error("Token or URL not found");
  }

  const prompt = existingContent
    ? `Please make modifications to using the content below as a reference or starting point:

        <old-content>
        ${existingContent}
        </old-content>

        Now that you have the old content, please follow these instructions to modify:
        ${aiPrompt}`
    : aiPrompt;

  const message = `${prompt}, additionally, please make sure you return only the response and in a markdown format and in <response> </response> tags`;

  const body = JSON.stringify({ message });

  const response = await fetch(`${url}/proxy_url/message`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body,
  });

  const responseJson = await response.json();
  let extractedResponse = extractResponse(responseJson?.content);
  console.log("extractedResponse", extractedResponse);
  let formattedResponse = await markdownToHtml(extractedResponse);
  return formattedResponse.content;
};

export const getSyncedDocsRepo = async () => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getBaseUrl(),
    authInfo.getOrg(),
  ]);
  try {
    const response = await fetch(`${url}/external_githubs?decrypt=yes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let jsonResponse = await response.json();
    return `https://github.com/${jsonResponse.docs[0].content.repo}`;
  } catch (error) {
    console.log("error", error);
  }
  return `https://github.com/team-dev-docs/${org}-dev-docs`;
};

export const getOwnerAndRepo = async () => {
  let url = await getSyncedDocsRepo();
  console.log("url", url);
  let [owner, repo] = url.split("https://github.com/")[1].split("/");

  return { owner, repo };
};

export const getFileContent = async ({ branch, fileName, owner, repo }) => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getUrl(),
    authInfo.getOrg(),
  ]);

  if ([token, url, org].some((x) => !x)) {
    throw new Error("Token, URL or Org not found");
  }

  const [
    encodedBranch,
    encodedFileName,
    encodedOrg,
    encodedOwner,
    encodedRepo,
  ] = [branch, fileName, org, owner, repo].map((urlComponent) =>
    encodeURIComponent(urlComponent)
  );

  const response = await fetch(
    `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches/${encodedBranch}/files/${encodedFileName}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return await response.json();
};

export const updateFileContent = async ({
  branch,
  fileName,
  owner,
  repo,
  content,
}) => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getUrl(),
    authInfo.getOrg(),
  ]);

  if ([token, url, org].some((x) => !x)) {
    throw new Error("Token, URL or Org not found");
  }

  const [
    encodedBranch,
    encodedFileName,
    encodedOrg,
    encodedOwner,
    encodedRepo,
  ] = [branch, fileName, org, owner, repo].map((urlComponent) =>
    encodeURIComponent(urlComponent)
  );

  const response = await fetch(
    `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches/${encodedBranch}/files/${encodedFileName}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ content }),
    }
  );

  return await response.json();
};

export const createFileContent = async ({
  branch,
  fileName,
  owner,
  repo,
  content,
}) => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getUrl(),
    authInfo.getOrg(),
  ]);

  if ([token, url, org].some((x) => !x)) {
    throw new Error("Token, URL or Org not found");
  }

  const [
    encodedBranch,
    encodedFileName,
    encodedOrg,
    encodedOwner,
    encodedRepo,
  ] = [branch, fileName, org, owner, repo].map((urlComponent) =>
    encodeURIComponent(urlComponent)
  );

  const response = await fetch(
    `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches/${encodedBranch}/files/${encodedFileName}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ content }),
    }
  );

  return await response.json();
};

export const renameFileContent = async ({
  branch,
  fileName,
  owner,
  repo,
  content,
  newName
}) => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getUrl(),
    authInfo.getOrg(),
  ]);

  if ([token, url, org].some((x) => !x)) {
    throw new Error("Token, URL or Org not found");
  }

  const [
    encodedBranch,
    encodedFileName,
    encodedOrg,
    encodedOwner,
    encodedRepo,
  ] = [branch, fileName, org, owner, repo].map((urlComponent) =>
    encodeURIComponent(urlComponent)
  );

  const response = await fetch(
    `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches/${encodedBranch}/files/${encodedFileName}/rename`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ newName }),
    }
  );

  return await response.json();
};

export const deleteFile = async ({
  branch,
  fileName,
  owner,
  repo,
}) => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getUrl(),
    authInfo.getOrg(),
  ]);

  if ([token, url, org].some((x) => !x)) {
    throw new Error("Token, URL or Org not found");
  }

  const [
    encodedBranch,
    encodedFileName,
    encodedOrg,
    encodedOwner,
    encodedRepo,
  ] = [branch, fileName, org, owner, repo].map((urlComponent) =>
    encodeURIComponent(urlComponent)
  );

  const response = await fetch(
    `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches/${encodedBranch}/files/${encodedFileName}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return await response.json();
};

export const getImageFileContent = async ({
  branch,
  fileName,
  owner,
  repo,
}) => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getUrl(),
    authInfo.getOrg(),
  ]);

  if ([token, url, org].some((x) => !x)) {
    throw new Error("Token, URL or Org not found");
  }

  const [
    encodedBranch,
    encodedFileName,
    encodedOrg,
    encodedOwner,
    encodedRepo,
  ] = [branch, fileName, org, owner, repo].map((urlComponent) =>
    encodeURIComponent(urlComponent)
  );

  const response = await fetch(
    `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches/${encodedBranch}/files/${encodedFileName}?image=true`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return await response.json();
};

export const getFiles = async ({ branch, compareBranch }) => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getUrl(),
    authInfo.getOrg(),
  ]);

  if ([token, url, org].some((x) => !x)) {
    throw new Error("Token, URL or Org not found");
  }

  let { owner, repo } = await getOwnerAndRepo();

  const [
    encodedBranch,
    encodedOrg,
    encodedOwner,
    encodedRepo,
    encodedCompareBranch,
  ] = [branch, org, owner, repo, compareBranch].map((urlComponent) =>
    encodeURIComponent(urlComponent)
  );

  const response = await fetch(
    `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches/${encodedBranch}/files?comparedBranch=${encodedCompareBranch}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return await response.json();
};

export const getBranches = async () => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getUrl(),
    authInfo.getOrg(),
  ]);

  if ([token, url, org].some((x) => !x)) {
    throw new Error("Token, URL or Org not found");
  }

  let { owner, repo } = await getOwnerAndRepo();

  const [
    encodedOrg,
    encodedOwner,
    encodedRepo,
  ] = [org, owner, repo].map((urlComponent) =>
    encodeURIComponent(urlComponent)
  );

  const response = await fetch(
    `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return await response.json();
};

export const getCustomFiles = async ({ branch, owner, repo }) => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getUrl(),
    authInfo.getOrg(),
  ]);

  if ([token, url, org].some((x) => !x)) {
    throw new Error("Token, URL or Org not found");
  }

  const [encodedBranch, encodedOrg, encodedOwner, encodedRepo] = [
    branch,
    org,
    owner,
    repo,
  ].map((urlComponent) => encodeURIComponent(urlComponent));

  const response = await fetch(
    `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches/${encodedBranch}/files`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return await response.json();
};

export const getCustomMarkdownFiles = async ({ branch, owner, repo }) => {
  try {
    const [token, url, org] = await Promise.all([
      authInfo.getToken(),
      authInfo.getUrl(),
      authInfo.getOrg(),
    ]);

    if ([token, url, org].some((x) => !x)) {
      throw new Error("Token, URL or Org not found");
    }

    const [encodedBranch, encodedOrg, encodedOwner, encodedRepo] = [
      branch,
      org,
      owner,
      repo,
    ].map((urlComponent) => encodeURIComponent(urlComponent));

    const response = await fetch(
      `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches/${encodedBranch}/files?extensions=md, mdx`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return await response.json();
  } catch (error) {
    console.log("error", error);
  }
};

export const getImageFiles = async ({ branch }) => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getUrl(),
    authInfo.getOrg(),
  ]);

  if ([token, url, org].some((x) => !x)) {
    throw new Error("Token, URL or Org not found");
  }

  let { owner, repo } = await getOwnerAndRepo();

  const [encodedBranch, encodedOrg, encodedOwner, encodedRepo] = [
    branch,
    org,
    owner,
    repo,
  ].map((urlComponent) => encodeURIComponent(urlComponent));

  const response = await fetch(
    `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches/${encodedBranch}/files?extensions=png`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return await response.json();
};

export const pushFileContent = async ({
  branch,
  targetBranch,
  fileName,
  owner,
  repo,
}) => {
  const [token, url, org] = await Promise.all([
    authInfo.getToken(),
    authInfo.getUrl(),
    authInfo.getOrg(),
  ]);

  if ([token, url, org].some((x) => !x)) {
    throw new Error("Token, URL or Org not found");
  }

  const [
    encodedBranch,
    encodedFileName,
    encodedOrg,
    encodedOwner,
    encodedRepo,
  ] = [branch, fileName, org, owner, repo].map((urlComponent) =>
    encodeURIComponent(urlComponent)
  );

  const response = await fetch(
    `${url}/companies/${encodedOrg}/owners/${encodedOwner}/repos/${encodedRepo}/branches/${encodedBranch}/files/${encodedFileName}/push`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ targetBranch }),
    }
  );

  return await response.json();
};
