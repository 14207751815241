import Image from '@tiptap/extension-image'
import { VueNodeViewRenderer, Node, mergeAttributes } from '@tiptap/vue-3'
import MermaidComponent from './Mermaid.vue'

export default Node.create({
  name: 'mermaidBlock',
  group: 'block',
  atom: true,
  draggable: true,
  addAttributes() {
    return {
      mermaidDiagram: {
        default: null,
      },
      width: {
        default: null,
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          }
        },
      },
      height: {
        default: null,
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          }
        },
      },
      saved: {
        default: null,
      },
    }
  },
  parseHTML() {
    return [
      {
        tag: 'mermaid-block',
      },
    ]
  },
  isDraggable: {
    default: true,
    renderHTML: (attributes) => {
      return {};
    }
  },
  renderHTML({ HTMLAttributes }) {
    return ['mermaid-block', mergeAttributes(HTMLAttributes)]
  },
  addNodeView() {
    return VueNodeViewRenderer(MermaidComponent)
  },
})
