<template>
    <div>
        <h1 class="content-header">Context for {{ friendlyNamesForFile(file) }}</h1>
        <el-row>
            <el-col :span="24">
                <el-tag v-if="tags" v-for="item in tags" :key="item" :type="item" class="mx-1" effect="dark" round>
                    {{ item }}
                </el-tag>
            </el-col>
        </el-row>
        <read-only :content="content" />
    </div>

</template>

<script>
import ReadOnly from "./ReadOnly.vue"
import { getFileName, getRepoName } from "../helper_functions/friendlyNamesForPaths.js"

export default {
    components: {
        ReadOnly
    },
    props: {
        tags: Array,
        file: String,
        content: Object
    },
    methods: {
        friendlyNamesForFile(file) {
            if (!file) return
            return getFileName(file)
        }
    }
}

</script>

<style>
</style>