<template>
</template>
<script>

export default {
    data: () => ({
        currentSection: null
    }),
    async mounted() {
        if (this.$route.name == "published section") await this.goToPublishedDocuments()
        else await this.goToUnPublishedDocuments()
    },
    methods: {
        async goToUnPublishedDocuments() {
            var section = this.$route.params.section
            const component = this
            this.currentSection = this.$route.params.section
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var url = await this.$authInstance.getBaseUrl()
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            var responseData = await fetch(`${url}/external_docs?searchKey=section&searchValue=${section}`, requestOptions)
            
            var resJson = await responseData.json()
            var docs = resJson.data
            this.$router.push(`/external_docs/${section}/${docs[0].title}`)
        },
        async goToPublishedDocuments() {
            var section = this.$route.params.section
            var org = this.$route.params.org
            const component = this
            this.currentSection = this.$route.params.section
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var url = await this.$authInstance.getBaseUrl()
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };


            var responseData = await fetch(`${url}/published_docs?searchKey=section&searchValue=${section}`, requestOptions)
            
            var resJson = await responseData.json()
            var docs = resJson.data
            this.$router.push(`/documentation/${org}/${section}/${docs[0].title}`)

        }
    }
}

</script>