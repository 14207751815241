<template>
  <el-row justify="center" class="home">
    <el-col class="grid place-items-center" justify="center" :span="12">
      <started-form v-if="typeformShouldBeClosed" />
      <typeform-widget v-else @form-completed="onFormCompleted" formId="yg4gMwSM" />
    </el-col>
    <el-col :span="12">
      <div class="dropdown dropdown-end absolute top-0 right-0">
        <label tabindex="0" class="btn btn-ghost btn-circle avatar online">
          <div class="w-8 rounded-full ring avatar-gradient ring-primary ring-offset-base-100 ring-offset-2 ">

          </div>
        </label>
        <ul tabindex="0" class="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
          <li>
            <router-link class="router-link" :to="{ path: '/account' }">
              <a>Account</a>
            </router-link>
          </li>
          <li><a @click="logout()">Logout</a></li>
        </ul>
      </div>
      <div
        style="height: 100vh; background-size: cover; background-image: url('https://cdn.glitch.global/40d5f2e1-d6f1-4b4e-b603-44a0049b2e40/DALL%C2%B7E%202023-01-15%2000.21.49.png?v=1673763772494');">
      </div>
    </el-col>
  </el-row>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import StartedForm from '@/components/StartedForm.vue'
import TypeformWidget from '@/components/TypeformWidget.vue'
export default {
  name: 'HomeView',
  components: {
    StartedForm,
    TypeformWidget
  },
  data: () => ({
    typeformShouldBeClosed: false
  }),
  async mounted() {
    let orgs = await this.$authInstance.getOrgs()
   
    if(orgs?.length > 0) {
      console.log(typeof org)
      console.log("is this true")
      this.typeformShouldBeClosed = true
    }
  },
  methods: {
     async logout() {
      await this.$authInstance.logout()
    },
    onFormCompleted(event) {
      console.log('Form Completed!', event);
      this.typeformShouldBeClosed = true
      // Handle form completion (e.g., fetch responses, show a thank you message, etc.)
    }
  }
}
</script>

<style>
html {
 max-height: 10px !important;
}
</style>


