<template>
    <el-sub-menu id="sub-doc-menu" style="max-height: 70vh; min-width: 100%; overflow-y: scroll; overflow-x: scroll;" index="1">
        <template #title>
            <el-icon>
            </el-icon>
            <h3>Docs</h3>
        </template>
        <el-menu-item-group>
            <el-menu-item @click="scrollToGeneral()" index="1-1">
                <h3>General Doc</h3>
            </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
            <el-sub-menu>
                <template #title>
                    <h3>Context Docs</h3>
                </template>
                <el-menu-item v-if="fileDocs" v-for="(doc, index) of fileDocs" class="section-menu-item"
                    :index="'1-1-' + String(index)">
                    <el-tooltip class="box-item" effect="dark" :content="generateLink(doc)" placement="right-start">
                        <router-link :to="{ path: generateLink(doc) }">
                            <h4>{{ friendlyFileName(doc.file) }}</h4>
                        </router-link>

                    </el-tooltip>
                </el-menu-item>
                <el-menu-item-group v-if="organizedDocs" v-for="(docItem, docIndex) of organizedDocs"
                    class="section-menu-item">
                    <el-sub-menu class="context-sub-menu" :index="'2-1-' + String(docIndex)" :popper-offset="0">
                        <template #title>
                            <h3><el-icon><folder /></el-icon> {{ docItem.name }}</h3>
                        </template>
                        <el-menu-item v-if="docItem?.files" v-for="(doc, subIndex) of [docItem, ...docItem?.files]"
                            class="section-menu-item" :index="'2-1-' + String(docIndex) + '-' + String(subIndex)">
                            <el-tooltip class="box-item" effect="dark" :content="generateLink(doc)" placement="right-start">
                                <router-link :to="{ path: generateLink(doc) }">
                                    <h4><span v-if="doc.folder">
                                        <el-icon><folder /></el-icon>
                                    </span>{{ friendlyFileName(doc.file) }}</h4>
                                </router-link>

                            </el-tooltip>
                        </el-menu-item>

                    </el-sub-menu>
                </el-menu-item-group>
            </el-sub-menu>

        </el-menu-item-group>
        <el-menu-item-group>
            <el-sub-menu>
                <template #title>
                    <h3>Misc docs</h3>
                </template>
                <el-menu-item v-if="miscDocs" v-for="(doc, index) of miscDocs" class="section-menu-item"
                    :index="'1-1-5' + String(index)">
                    <el-tooltip class="box-item" effect="dark" :content="generateLink(doc)" placement="right-start">
                        <router-link :to="{ path: generateLink(doc) }">
                            <h4>{{ friendlyFileName(doc.file) }}</h4>
                        </router-link>

                    </el-tooltip>
                </el-menu-item>
            </el-sub-menu>
        </el-menu-item-group>
    </el-sub-menu>
</template>

<script>
import ContextSubMenuVue from './ContextSubMenu.vue';
import { getFileName } from "../helper_functions/friendlyNamesForPaths"
import { ArrowLeft, ArrowRight, Folder } from '@element-plus/icons-vue';

export default {
    data: () => ({
        contexts: null, fileDocs: null, organizedDocs: null, miscDocs: null
    }),
    components: {
        ArrowLeft, ArrowRight, Folder
    },
    methods: {
        scrollToReference() {
            var id = this.$subMenu.reference()
            document.getElementById(id).scrollIntoView({
                behavior: "smooth"
            });
        },
        toNumberString(index) {
            return `${index}`
        },
        scrollToGeneral() {
            var id = this.$subMenu.general()
            document.getElementById(id).scrollIntoView({
                behavior: "smooth"
            });
        },
        friendlyFileName(file) {
            return getFileName(file)
        },
        generateLink(doc) {

            var repo = this.$route?.params?.id
            if (this.$route.name == "internal doc") repo = this.$route?.params?.repo

            const file = btoa(doc?.file)
            const newPath = `/docs/${repo}/${file}`
            return newPath
        },
        checkForContent() {
            var query = this.$route.query.doc

            this.scrollToFile(query)

        },
        scrollToFile(id) {
            var idString = `${id}`
            document.getElementById(idString).scrollIntoView({
                behavior: "smooth"
            });
        },
        async getAllDocs() {

            var repo = atob(this.$route?.params?.id)
            if (this.$route.name == "internal doc") repo = atob(this.$route?.params?.repo)
            this.repo = repo


            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()

            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var url = await this.$authInstance.getBaseUrl()
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            var responseData = await fetch(`${url}/docs?searchKey=repo&searchValue=${repo}`, requestOptions)

            var resJson = await responseData.json()
            const { data } = resJson

            this.fileDocs = data
            const organized = await this.$subMenu.sortContext(data)
            this.fileDocs = this.$subMenu.folderlessContexts
            this.organizedDocs = organized
            this.miscDocs = this.$subMenu.misc


            // if (data[0]) {
            //     this.content = data[0]?.content
            // }
        }
    },
    async mounted() {
        const component = this
        await this.getAllDocs()
        //this.contexts = this.$subMenu.contexts
        // window.addEventListener('build', function (e) {
        //
        //     component.contexts = component.$subMenu.contexts
        //     component.fileDocs = component.$subMenu.folderlessContexts
        //
        //     component.checkForContent()
        // }, false);
    }
}
</script>