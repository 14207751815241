<template>


    <div class="color-picker-container">
        <ColorPicker v-model="colorHEX" inputId="cp-hex" :format="format" class="color-picker" />
        <label v-if="title" for="title">{{title}}</label>
        <InputText class="w-full" id="cp-hex" v-model="colorHEX" />
    </div>


</template>
<script>
import InputText from 'primevue/inputtext'

export default {
    props: {
        show: Boolean, format: String, title: String
    },
    watch: {
        showColorPopup(val) {
            if (!val) this.$emit("close")
        },
        colorHEX(val) {
            this.$emit("color", val)
        },
        colorPickers(val) {
            if (this.gradientColors.length >= val) this.colorPickers.splice(val, 1)
            else {
                this.gradientColors.push({ color: '#ff4500' })
            }
            return
        }
    },
    data: () => ({
        showColorPopup: false, colorPickers: 0, predefineColors: [
            '#ff4500',
            '#ff8c00',
            '#ffd700',
            '#90ee90',
            '#00ced1',
            '#1e90ff',
            '#c71585',
            'rgba(255, 69, 0, 0.68)',
            'rgb(255, 120, 0)',
            'hsv(51, 100, 98)',
            'hsva(120, 40, 94, 0.5)',
            'hsl(181, 100%, 37%)',
            'hsla(209, 100%, 56%, 0.73)',
            '#c7158577',
        ],
        gradient: false,
        colorPickers: 0,
        gradientColors: [],
        gradientAngle: 30.17,
        finalGradientColor: "",
        singleColor: '#ff4500',
        finalColorToSend: null,
        color: null,
        colorHEX: '6466f1'
    }),
    mounted() {
        this.showColorPopup = this.show
    },
    computed: {
        finalGradientColor() {
            const gradientColors = this.gradientColors.map(function (item) { return item.color })
            const gradientStrings = gradientColors.join(", ")

            this.finalGradientToSend = `linear-gradient(${this.gradientAngle}deg, ${gradientStrings})`
            return `linear-gradient(${this.gradientAngle}deg, ${gradientStrings})`
        },
    },
    methods: {
        submitColor() {
            if (this.gradient) this.$emit("color", this.finalGradientToSend)
            else this.$emit("color", this.singleColor)
        }
    }
}
</script>

<style>
.p-colorpicker-preview {
    width: 4rem !important;
    height: 4rem !important;
    border: 0 none !important;
    border-radius: 50% !important;
    margin-left: 1rem;
}

.color-picker-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    max-width: 6.25rem;
}

.color-picker-container .color-picker {
    margin-right: 1rem;
}
</style>