<template>  
    <el-drawer
      v-model="drawer"
      title="I am the title"
      direction="btt" 
      :before-close="handleClose"
    >
      <span>Hi, there!</span>
    </el-drawer>
  </template>

  <script>
    export default {
        props: {
            open: Boolean,
            reference: String
        },
        methods: {
            handleClose() {
                this.$emit("reset")
            }
        },
        data:() => ({
            drawer: null,
        }),
        mounted() {
            this.drawer = this.open
        },
        unmounted() {
            this.$emit("reset")
        }
    }
  </script>