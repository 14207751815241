<template>
    <bubble-menu
        :editor="editor"
        :tippy-options="{}"
        v-if="editor"
        class="w-[24rem]"
    >
        <div class="flex flex-row items-center bubble-menu gap-[0.31rem] rounded-[0.5rem] bg-[#1C1C1C] p-[0.31rem]">

            <button
                @click="toggleAITools"
                class="flex flex-row items-center bubble-menu-item hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] gap-[0.31rem]"
            >
                <img
                    class="w-[0.88rem] h-[0.88rem]"
                    :src="aiIcon"
                    alt="ai"
                />
                <div>
                    AI tools
                </div>
            </button>

            <div
                v-if="showAITools"
                v-on-click-outside="closeAITools"
                class="absolute top-full left-0 mt-[1px] px-[0.31rem] py-[0.94rem] flex flex-col gap-[0.31rem] rounded-[0.5rem] bg-[#1C1C1C]"
                style="box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);"
            >
                <div
                    class="text-[#A2A1A5] text-[0.75rem] px-[0.62rem]"
                >
                    Edit
                </div>

                <button
                    @click="replaceContentWithAi('please rewrite this content to be longer and more detailed')"
                    class="flex flex-row items-center px-[0.62rem] py-[0.31rem] gap-[0.62rem] hover:bg-white/10 rounded-[0.5rem] w-full"
                >
                    <img
                        class="w-[0.88rem] h-[0.88rem] inline-block"
                        :src="makeLongerIcon"
                        alt="make longer"
                    />
                    <div>
                        Make Longer
                    </div>
                </button>

                <button
                    @click="replaceContentWithAi('please rewrite this content to be shorter and more concise')"
                    class="flex flex-row items-center px-[0.62rem] py-[0.31rem] gap-[0.62rem] hover:bg-white/10 rounded-[0.5rem] w-full"
                >
                    <img
                        class="w-[0.88rem] h-[0.88rem] inline-block"
                        :src="makeShorterIcon"
                        alt="make longer"
                    />
                    <div>
                        Make shorter
                    </div>
                </button>

                <button
                    @click="toggleToneOptions"
                    class="flex flex-row items-center px-[0.62rem] py-[0.31rem] gap-[0.62rem] hover:bg-white/10 rounded-[0.5rem] w-full justify-between"
                >
                    <div
                        class="flex flex-row items-center gap-[0.62rem]"
                    >
                        <img
                            class="w-[0.88rem] h-[0.88rem] inline-block"
                            :src="changeToneIcon"
                            alt="change tone"
                        />
                        <div>
                            Change tone
                        </div>
                    </div>
                    <img
                        class="w-[0.88rem] h-[0.88rem] inline-block"
                        :src="rightArrowIcon"
                        alt="right arrow"
                    />
                </button>

                <div
                    v-if="showToneOptions"
                    v-on-click-outside="closeToneOptions"
                    class="absolute left-full ml-[-1px] px-[0.31rem] py-[0.94rem] flex flex-col gap-[0.31rem] rounded-[0.5rem] bg-[#1C1C1C]"
                    style="box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);"
                >
                    <button
                        v-for="tone in TONES"
                        :key="tone"
                        @click="replaceContentWithAi(`please rewrite this content to have a ${tone} tone`)"
                        class="flex flex-row items-center px-[0.62rem] py-[0.31rem] gap-[0.62rem] hover:bg-white/10 rounded-[0.5rem] w-full text-left whitespace-nowrap"
                    >
                        {{ tone }}
                    </button>
                </div>

                <button
                    @click="() => console.log('todo')"
                    class="flex flex-row items-center px-[0.62rem] py-[0.31rem] gap-[0.62rem] hover:bg-white/10 rounded-[0.5rem] w-full"
                >
                    <img
                        class="w-[0.88rem] h-[0.88rem] inline-block"
                        :src="editSelectionIcon"
                        alt="edit selection"
                    />
                    <div>
                        Edit selection
                    </div>
                </button>

                <button
                    @click="replaceContentWithAi('please fix any spelling and grammar mistakes in this content')"
                    class="flex flex-row items-center px-[0.62rem] py-[0.31rem] gap-[0.62rem] hover:bg-white/10 rounded-[0.5rem] w-full"
                >
                    <img
                        class="w-[0.88rem] h-[0.88rem] inline-block"
                        :src="fixSpellingIcon"
                        alt="fix spelling"
                    />
                    <div>
                        Fix spelling & grammar
                    </div>
                </button>

                <div
                    class="w-full h-[0.06rem] bg-[#7A7B80] px-[0.62rem] my-[0.31rem]"
                />

                <div
                    class="text-[#A2A1A5] text-[0.75rem] px-[0.62rem]"
                >
                    Brainstorm, ask, chat
                </div>

                <AskAiButton :editor="editor" />
            </div>

            <div
                class="w-[0.06rem] h-[1.25rem] bg-[#7A7B80]"
            />

            <button
                @click="toggleTextOptions"
                class="flex flex-row items-center bubble-menu-item hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] gap-[0.31rem]"
            >
                <div>
                    Text
                </div>
                <img
                    class="w-[0.88rem] h-[0.88rem]"
                    :src="arrowDownIcon"
                    alt="arrow down"
                />
            </button>

            <TextDropdown
                :editor="editor"
                @close="isTextDropdownVisible = false"
                class="absolute top-full left-[5rem] mt-[1px] px-[0.31rem] py-[0.94rem] flex flex-col gap-[0.31rem] rounded-[0.5rem] bg-[#1C1C1C]"
                v-if="isTextDropdownVisible"
                v-on-click-outside="closeTextOptions"
            />

            <button
                @click="editor.chain().focus().toggleBold().run()"
                :class="[
                    'bubble-menu-item hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] font-bold',
                    editor.isActive('bold') ? 'bg-white/20' : '',
                ]"
            >
                B
            </button>

            <button
                @click="editor.chain().focus().toggleItalic().run()"
                :class="[
                    'bubble-menu-item hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] italic',
                    editor.isActive('italic') ? 'bg-white/20' : '',
                ]"
            >
                I
            </button>

            <button
                @click="editor.chain().focus().toggleUnderline().run()"
                :class="[
                    'bubble-menu-item hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] underline',
                    editor.isActive('underline') ? 'bg-white/20' : '',
                ]"
            >
                U
            </button>

            <button
                @click="editor.chain().focus().toggleStrike().run()"
                :class="[
                    'bubble-menu-item hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] line-through',
                    editor.isActive('strike') ? 'bg-white/20' : '',
                ]"
            >
                S
            </button>

            <button
                @click="editor.chain().focus().toggleCode().run()"
                :class="[
                    'bubble-menu-item hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] font-mono',
                    editor.isActive('code') ? 'bg-white/20' : '',
                ]"
            >
                &lt;/&gt;
            </button>

            <button
                @click="toggleLinkOptions"
                class="flex flex-row items-center bubble-menu-item hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] gap-[0.31rem]"
            >
                <img
                    class="w-[0.88rem] h-[0.88rem]"
                    :src="linkIcon"
                    alt="link"
                >
                <img
                    class="w-[0.88rem] h-[0.88rem]"
                    :src="arrowDownIcon"
                    alt="arrow down"
                />
            </button>

            <LinkDropdown
                :editor="editor"
                @close="isLinkDropdownVisible = false"
                class="absolute top-full left-[17rem] mt-[1px] px-[0.31rem] py-[0.94rem] flex flex-col gap-[0.31rem] rounded-[0.5rem] bg-[#1C1C1C] w-[16rem]"
                v-if="isLinkDropdownVisible"
                v-on-click-outside="closeLinkOptions"
            />

            <button
                @click="editor.chain().focus().clearNodes().unsetAllMarks().run()"
                :class="[
                    'bubble-menu-item hover:bg-white/10 p-[0.31rem] rounded-[0.5rem]'
                ]"
            >
                Tx
            </button>
        </div>
    </bubble-menu>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components'
import { BubbleMenu } from '@tiptap/vue-3';
import { ref } from 'vue';

import TextDropdown from '@/components/EditorComponents/TextDropdown.vue';
import AskAiButton from '@/components/EditorComponents/AskAiButton.vue';
import LinkDropdown from '@/components/EditorComponents/LinkDropdown.vue';

import { getAiResponse } from '@/plugins/devdocsBackendService.js';

import aiIcon from '@/assets/icon-ai.svg';
import arrowDownIcon from '@/assets/icon-arrow-down.svg';
import linkIcon from '@/assets/icon-link.svg';
import makeLongerIcon from '@/assets/icon-make-longer.svg';
import makeShorterIcon from '@/assets/icon-make-shorter.svg';
import changeToneIcon from '@/assets/icon-change-tone.svg';
import editSelectionIcon from '@/assets/icon-edit-selection.svg';
import fixSpellingIcon from '@/assets/icon-fix-spelling.svg';
import askDevdocsIcon from '@/assets/icon-ask-devdocs.svg';
import rightArrowIcon from '@/assets/icon-arrow-right.svg';

const props = defineProps({
    editor: {
        type: Object,
        required: true
    }
});

const showAITools = ref(false);
const showToneOptions = ref(false);
const isTextDropdownVisible = ref(false);
const isLinkDropdownVisible = ref(false);

const TONES = [
    'Academic',
    'Business',
    'Casual',
    'Conversational',
    'Humorous',
    'Informative',
    'Memeify',
    'Objective',
    'Poetic',
];

const toggleAITools = () => {
    showAITools.value = !showAITools.value;
};

const toggleTextOptions = () => {
    isTextDropdownVisible.value = !isTextDropdownVisible.value;
};

const toggleToneOptions = () => {
    showToneOptions.value = !showToneOptions.value;
};

const toggleLinkOptions = () => {
    isLinkDropdownVisible.value = !isLinkDropdownVisible.value;
};

const closeAITools = () => {
    showAITools.value = false;
};

const closeTextOptions = () => {
    isTextDropdownVisible.value = false;
};

const closeToneOptions = () => {
    showToneOptions.value = false;
};

const closeLinkOptions = () => {
    isLinkDropdownVisible.value = false;
};


const replaceContentWithAi = async (aiPrompt) => {
    await props.editor.setEditable(false)
    try {
        const { view, state } = props.editor;
        const { from, to } = view.state.selection;
        const existingContent = state.doc.textBetween(from, to, '');

        const aiContent = await getAiResponse({
            aiPrompt,
            existingContent,
        });

        await props.editor.chain().focus().deleteRange({
            from: from,
            to: to,
        }).insertContent(
            aiContent,
            {
                at: from,
            },
        ).run();

    } catch (error) {
        console.error(error);
    } finally {
        props.editor.setEditable(true)
    }
};

</script>
