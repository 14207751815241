<template>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane class="p-11" label="Style" name="style">
            <el-dropdown>
                <el-button type="primary" class="el-dropdown-link">
                    Css Style Helpers
                    <el-icon class="el-icon--right">
                        <arrow-down />
                    </el-icon>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item>
                            <el-button @click="turnToFlexBox">Turn to Flex Container</el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="turnToFlexChild">Turn to Flex Child</el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="floatLeft">Position Left</el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="floatCenter">Position Center</el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <el-button @click="floatRight">Position Right</el-button>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <br>
            <style-editor v-if="cssString" :css="cssString" @style="setStyle" />
        </el-tab-pane>
        <el-tab-pane class="p-11" label="Background Image" name="background">
            <div class="mt-2 pt-2 pb-2">
                <h3 class="mb-2">Upload Image</h3>
                <el-upload class="avatar-uploader" :action="apiUrl" :show-file-list="false" :on-success="uploadData"
                    :on-preview="handlePictureCardPreview" :headers="{ 'Authorization': 'Bearer ' + token }">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <images-dialog v-else @postedimage="insertImage" @localimage="insertLocalImage" />
                    <!-- <el-icon v-else class="avatar-uploader-icon"><i class="el-icon-plus"></i></el-icon> -->
                </el-upload>
            </div>
            <el-button @click="showImageSelectorDialog = true">Use Exisiting Image</el-button>
            <el-form ref="bpRef" :model="bpForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="background position" prop="bp">
                    <el-input v-model="bpForm.bp" type="text" autocomplete="off" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitBpForm(bpRef)">Submit</el-button>
                    <el-button @click="resetForm(bpRef)">Reset</el-button>
                </el-form-item>
            </el-form>

        </el-tab-pane>
        <el-tab-pane v-if="type == 'button'" class="p-11" label="Button" name="button">
            <el-form label-position="top">
                <el-form-item label="Button Text">
                    <el-input v-model="buttonText" />
                </el-form-item>
                <el-form-item label="url">
                    <el-input v-model="url" />
                </el-form-item>

            </el-form>
        </el-tab-pane>
    </el-tabs>

    <image-selector-dialog v-if="showImageSelectorDialog" :show="showImageSelectorDialog" @select="setExistingImage"
        @close="showImageSelectorDialog = false" />
</template>

<script>

import StyleEditor from './CardStyleEditor.vue'
import { ArrowDown } from '@element-plus/icons-vue'
import ImageSelectorDialog from './imageSelectorDialog.vue'
import ImagesDialog from './ImagesDialog.vue'

export default {
    components: {
        StyleEditor, ArrowDown, ImageSelectorDialog, ImagesDialog
    },
    data: () => ({

        colorDialog: false,
        textColor: "white",
        url: null,
        showImageSelectorDialog: false,
        buttonText: null,
        activeName: "style",
        bpForm: {
            bp: ''
        },
        style: null,
        cssString: null,
        render: true,
        ageValue: '',
        imageUrl: null,
        measureMentFormatOptions: ["vh", "em", "px", "%"],
        formRef: null,
        bpRef: null,
        token: null,
        apiUrl: null,
    }),
    props: {
        show: Boolean, attrs: Object, type: String, editor: Object
    },
    watch: {
        colorPickers(val) {
            if (this.gradientColors.length >= val) this.colorPickers.splice(val, 1)
            else {
                this.gradientColors.push({ color: '#ff4500' })
            }
            return
        }, 
        buttonText(val) {
            this.$emit("text", val)
        },
        url(val) {
            this.$emit("url", val)
        },
        imageUrl(val) {
            const styleString = this.cssString
            const newString = this.replaceBackground(styleString, val)
            this.cssString = newString
        }
    },
    async mounted() {
        const component = this
        this.url = this?.attrs?.url
        this.buttonText = this?.attrs?.buttonText
        this.activeName = "style"
        this.colorDialog = this.show
        this.formRef = this.$refs.formRef;
        this.bpRef = this.$refs.bpRef

        var baseUrl = this.$vscodeInstance.getBaseUrl()
        this.token = this.$vscodeInstance.token
        this.apiUrl = `${baseUrl}/external_images`

        this.cssString = this.attrs?.style || `background: #30363d`
        // if (this.attrs?.style) this.cssString = this.attrs?.style

        window.addEventListener('message', event => {
            if (event?.data?.command == "return_local_image_url_picker") {
                const splitFragment = `${component.$vscodeInstance.relativeFolder}/static`
                const localImageUrl = event?.data?.uri?.path.split(splitFragment)[1]
                component.imageUrl = localImageUrl
            } else if (event?.data?.command == "load_local_image_url_picker") {
                if (this.node.attrs.localImageId == event?.data?.uri.node) {
                    component.imageSrc = event?.data?.uri?.dataPath
                }

            }
        })
    },
    unmounted() {
        this.colorDialog = false
    },
    computed: {
        finalGradientColor() {
            const gradientColors = this.gradientColors.map(function (item) { return item.color })
            const gradientStrings = gradientColors.join(", ")

            this.finalGradientToSend = `linear-gradient(${this.gradientAngle}deg, ${gradientStrings})`
            return `linear-gradient(${this.gradientAngle}deg, ${gradientStrings})`
        },
        finalSingleColor() {

            this.finalColorToSend = this.singleColor
            return this.singleColor
        }
    },
    methods: {
        resetForm(formEl) {
            if (!formEl) return;
            formEl.resetFields();
        },
        async insertLocalImage(val) {

            this.imagesDialog = false
            this.$vscodeInstance.vscode.postMessage({
                command: 'local_image_save_picker',
                image_file: val
            })
        },
        setExistingImage(val) {

            this.imageUrl = val?.download_url
            const previewUrl = `url('${val?.download_url}') no-repeat center center / cover`
            const realUrl = `url('${val.path}') no-repeat center center / cover`
            const images = { externalImage: realUrl, previewUrl: previewUrl }
            this.$emit("newimage", images)
        },
        replaceBackground(cssString, newBackgroundValue) {


            const regex = /(;?\s*background(-image)?\s*:\s*[^;]*;?)/g;
            const hasBackground = cssString.match(regex);
            let newString;
            if (hasBackground) {
                newString = cssString.replace(regex, `background: url('${newBackgroundValue}') no-repeat center center / cover;`);
            } else {
                newString = cssString.concat(`background: url('${newBackgroundValue}') no-repeat center center / cover;`)
            }

            return newString
        },
        handlePictureCardPreview(uploadFile) {

        },
        async uploadData(response, uploadFile) {


            var newLogoImage = response.content.path.split("static")[1]
            const component = this
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            this.imageUrl = response?.content?.download_url

            // const previewUrl = `url("${this.imageUrl}") no-repeat center 100 no-repeat center center / cover`
            // const realUrl = `url("${newLogoImage}") no-repeat center 100 no-repeat center center / cover`
            const previewUrl = `url('${this.imageUrl}') no-repeat center center / cover`
            const realUrl = `url('${newLogoImage}') no-repeat center center / cover`
            const images = { externalImage: realUrl, previewUrl: previewUrl }
            this.$emit("newimage", images)
        },
        handleClick() {

        },
        submitBpForm(form) {

            this.$emit("newbp", this.bpForm.bp)
        },
        turnToFlexBox() {
            // var oldCss = this.cssString
            // this.cssString = null
            this.cssString = `${this.cssString}; \ndisplay: flex;`
        },
        turnToFlexChild() {
            this.cssString = `${this.cssString}; \nflex: 1 1 30%;`
        },
        floatCenter() {
            this.cssString = `${this.cssString}; \nmargin: 0 auto; display: block;`
        },
        floatLeft() {
            this.cssString = `${this.cssString}; \nfloat: left;`
        },
        floatRight() {
            this.cssString = `${this.cssString}; \nfloat: right;`
        },
        setStyle(val) {
            this.style = val
            this.$emit("newstyle", val)
        }
  
    }
}
</script>

<style lang="scss">
.color-dialog {
    color: white !important;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: white !important;
    }
}

.preview {
    min-width: 400px;
    min-age: 400px;
}



.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}
</style>