var theme = function () {
    this.preset = null
    this.getTheme = function() {
        if(this.preset) return this.preset
        var theme = localStorage.getItem("theme")
        return theme 
    } 
    this.setTheme= function(theme) {
        if(!theme) return
        localStorage.setItem("theme", theme)
        //window.location.reload()
    }
}

export default theme