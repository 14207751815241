<template>
    <div style="display: flex; padding: 0.4375rem 0.625rem; align-items: center; gap: 0.3125rem; align-self: stretch;">
        <button
            style="display: flex; padding: 0.4375rem 0.625rem; align-items: center; gap: 0.3125rem; align-self: stretch; border-radius: 0.5rem; border: 1px solid #A2A1A5; background: rgba(162, 161, 165, 0.20);"
            @click="toggle">
            <div class="w-4 h-4 p-px justify-center items-center gap-[50px] flex">
                <img class="w-[1.25rem] h-[1.25rem]" :src="branchIcon" alt="branch" />
            </div>
            <span>{{ modelValue }}</span>
        </button>
    </div>
    <Popover ref="op" unstyled>
        <div style="border-radius: 0.5rem; border: 1px solid rgba(122, 123, 128, 0.10); background: #1C1C1C;"
            class="w-[200px] h-[218px] py-[15px] bg-[#1c1c1c] rounded-lg border border-[#7a7b80]/10 flex-col justify-start items-center gap-[15px] inline-flex">
            <div class="self-stretch h-[47px] px-[5px] flex-col justify-start items-start gap-[5px] flex">
                <div class="self-stretch px-2.5 justify-center items-center gap-[50px] inline-flex">
                    <div class="grow shrink basis-0 text-[#a2a1a5] text-xs font-medium font-['Inter']">Current branch
                    </div>
                </div>
                <div class="self-stretch px-2.5 py-[5px] justify-start items-center gap-2.5 inline-flex">
                    <div class="h-4 p-px flex-col justify-center items-center gap-[50px] inline-flex">
                        <i class="pi pi-check" style="color: slateblue"></i>
                    </div>
                    <div class="text-[#d3d3d3] text-sm font-normal font-['Inter']">{{ modelValue }}</div>
                </div>
            </div>
            <div class="self-stretch h-[0px] border border-[#a2a1a5]/25"></div>
            <div class="self-stretch h-[111px] px-[5px] flex-col justify-start items-start gap-[5px] flex">
                <div class="self-stretch px-2.5 justify-center items-center gap-[50px] inline-flex">
                    <div class="grow shrink basis-0 text-[#a2a1a5] text-xs font-medium font-['Inter']">Other branches
                    </div>
                </div>
                <div style="max-height: 120px; overflow-y: auto">
                    <div style="width: 100%;" v-for="branch in options" :key="branch"
                        class="self-stretch px-2.5 py-[5px] justify-start items-center gap-2.5 inline-flex branch-option"
                        @click="selectBranch(branch)">
                        <div class="w-4 h-4 p-px justify-center items-center gap-[50px] flex">
                            <img class="w-[1.25rem] h-[1.25rem]" :src="branchIcon" alt="branch" />
                        </div>
                        <div class="text-[#d3d3d3] text-sm font-normal font-['Inter']">{{ branch }}</div>
                    </div>

                </div>

                <button
                    style="border-radius: 0.5rem; border: 1px solid rgba(255, 255, 255, 0.25); background: rgba(255, 255, 255, 0.10);"
                    class="self-stretch px-2.5 py-[5px] bg-white/10 rounded-lg border border-white/25 justify-start items-center gap-2.5 inline-flex">
                    <div class="p-px flex-col justify-center items-center gap-[50px] inline-flex">
                        <i class="pi pi-plus" style="color: slateblue"></i>
                    </div>
                    <div class="text-[#d3d3d3] text-sm font-normal font-['Inter']">New branch</div>
                </button>
            </div>
        </div>
    </Popover>
</template>

<script setup>
import { ref } from "vue";
import branchIcon from '@/assets/icon-branch.svg';

const op = ref();

const toggle = (event) => {
    op.value.toggle(event);
}


defineProps({
    modelValue: {
        type: String,
        required: true
    },
    options: {
        type: Array,
        default: () => []
    }
});

const emit = defineEmits(['update:modelValue']);

const selectBranch = (branch) => {
    emit('update:modelValue', branch);
    op.value.hide();
};
</script>

<style scoped>
.branch-option {
    cursor: pointer;
    padding: 5px;
}

.branch-option:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
</style>