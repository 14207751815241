import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import Component from './aiBlock.vue'

export default Node.create({
  name: 'aiBlock',

  group: 'block',
  draggable: true,
  atom: true,

  addAttributes() {
    return {
      command: {
        default: null,
      },
      text: {
        default: false
      },
      saved: {
        default: null
      },
      customHtml: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'ai-block',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['ai-block', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(Component)
  },
})