<template>
    <div ref="footer" class="footer-container">
        <div class="justify-end pt-4 mb-2 card-actions">
            <Button class="special-btn" @click="addTable()" outlined>Add new footer section</Button>
            <Button class="special-btn" @click="sendData()">Save</Button>
        </div>

        <div class="mb-4 " v-for="(tableData, index) of links" :key="index">



            <div class="mt-2 mb-2">
                <InputText class="titleInput" v-model="tableData.title" placeholder="Type here" />
            </div>

            <div class="justify-end pt-4 mb-2 card-actions">
                <Button @click="addRow(tableData.items)" outlined>Add row</Button>
                <Button severity="danger" @click="removeFooterSection(links, index)">Delete group</Button>
            </div>


            <DataTable :value="tableData.items">
                <Column field="label" header="Label">
                    <template #body="{ data, node, column, field, index }">
                        <InputText v-model="data.label" placeholder="Please input" />
                    </template>
                </Column>
                <Column field="href" header="href">
                    <template #body="{ data, node, column, field, index }">
                        <InputText v-model="data.href" placeholder="Please input" />
                    </template>
                </Column>
                <Column field="position" header="Position">
                    <template #body="{ data, node, column, field, index }">
                        <SelectButton v-model="data.position" :options="positions" aria-labelledby="basic" />

                    </template>
                </Column>
                <Column field="className" header="Class Name">
                    <template #body="{ data, node, column, field, index }">
                        <InputText v-model="data.className" placeholder="Please input" />
                    </template>
                </Column>
                <Column header="Operations" style="width: 120px">
                    <template #body="{ data, node, column, field, index }">
                        <Button link type="primary" size="small" @click.prevent="deleteRow(data)">
                            Remove
                        </Button>
                    </template>
                </Column>
            </DataTable>




        </div>
    </div>
</template>

<script>

import { ref } from 'vue';


export default {
    data() {
        return {
            search: ref(''),
            links: [],
            positions: ["left", "right"],
            tableData: [
                {
                    type: 'default',
                    label: 'Home',
                    html: '',
                    to: '/home',
                    href: '',
                    prependBaseUrlToHref: 'false',
                    position: 'left',
                    activeBasePath: '/home',
                    activeBaseRegex: '',
                    className: '',
                },
                {
                    type: 'default',
                    label: 'About',
                    html: '',
                    to: '/about',
                    href: '',
                    prependBaseUrlToHref: 'false',
                    position: 'left',
                    activeBasePath: '/about',
                    activeBaseRegex: '',
                    className: '',
                },
                {
                    type: 'default',
                    label: 'Contact Us',
                    html: '',
                    to: '/contact',
                    href: '',
                    prependBaseUrlToHref: 'false',
                    position: 'right',
                    activeBasePath: '/contact',
                    activeBaseRegex: '',
                    className: '',
                },
            ],
        };
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        async sendData() {
            const component = this;
            try {
                var org = await this.$authInstance.getOrg();
                var token = await this.$authInstance.getToken();
                // var user = this.$authInstance.getUserInfo()

                var myHeaders = new Headers();
                myHeaders.append('Content-Type', 'application/json');
                if (token) {
                    myHeaders.append('Authorization', `Bearer ${token}`);
                }

                const cleanedLinks = [];
                for (const tableData of this.links) {
                    const cleanedTableData = [];

                    for (let tableRow of tableData?.items) {
                        var keysToDelete = Object.keys(tableRow).filter(function (key) {
                            return tableRow[key] == '';
                        });
                        for (const keyToDelete of keysToDelete) {
                            delete tableRow[keyToDelete];
                        }
                        let regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
                        var isFullHref = regex.test(tableRow.href);
                        if (!isFullHref) tableRow.prependBaseUrlToHref = true;
                        else tableRow.prependBaseUrlToHref = false;
                        cleanedTableData.push(tableRow);
                    }
                    cleanedLinks.push({ items: cleanedTableData, title: tableData.title });
                }
                var data = {
                    content: { links: [...cleanedLinks] },
                };
                var raw = JSON.stringify({ ...data, path: 'footerItems.json' });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                };
                var url = await this.$authInstance.getBaseUrl();
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/theme_json`, requestOptions);
            } catch (e) { }
        },
        async getData(folderNode) {
            const component = this;

            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken();
            myHeaders.append('Content-Type', 'application/json');
            if (token) {
                myHeaders.append('Authorization', `Bearer ${token}`);
            }
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            };
            var url = await this.$authInstance.getBaseUrl();
            var saveResponseTwo = await fetch(`${url}/external_page?path=footerItems.json`, requestOptions);
            var jsonResponse = await saveResponseTwo.json();

            var docContent = atob(jsonResponse?.docs?.content, 'base64');

            var itemsJson = JSON.parse(docContent);
            this.links = itemsJson.links;
        },
        addRow(items) {
            var newRow = {
                label: 'About',
                href: '/about',
                prependBaseUrlToHref: 'false',
                position: 'left',
                className: '',
            };
            items.push(newRow);
        },
        deleteRow(tableData, rowData) {
            const index = tableData.indexOf(rowData);
            tableData.splice(index, 1);
        },
        removeFooterSection(links, index) {
            links.splice(index, 1);
        },
        addTable() {
            this.links.unshift({ title: 'new footer section', items: [] });
        }
    }
};
</script>

<style>
.special-btn {
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, darkblue, darkorchid) 1;
}

.titleInput {
    border: none !important;
    font-size: 18px !important;
    background-color: #1C1C1C !important;
    color: #A2A1A5 !important;
}
</style>
