<template>
    <figure class="px-10 pt-10 pb-10">
        <h2>Users</h2>
        <h3 class="mt-4 subpage-heading">Manage your team</h3>
    </figure>

    <div class="px-10 users-container" style="display: flex; justify-content: flex-end;">
        <Button @click="dialogFormVisible = true">Invite team members</Button>
        <DataTable :value="users" tableStyle="min-width: 50rem; width: 100%">
        <Column field="name" header="Name"></Column>
        <Column field="email" header="Email"></Column>
        <Column field="codebases" header="Codebases"></Column>
        <Column header="Actions">
            <template #body="{ data, node, column, field, index }">
                <!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                    @click="handleDelete(index, data)"></Button> -->
                <Button icon="pi pi-ellipsis-v" @click="onItemClick($event, data)" text />
                <Popover class="p-card" :ref="data.id" unstyled>
                    <div style="justify-content: flex-end; display: flex; flex-direction: column;">
                        <Button v-if="data.id != currentUserId" severity="danger" @click="handleDelete(index, data)" text>Delete</Button>
                    </div>
                </Popover>
            </template>
        </Column>
    </DataTable>
    </div>



            <Dialog v-model:visible="dialogFormVisible" header="Invite members to your workspace">

                <h3>Invite your team</h3>
                <form class="mt-4" ref="form" :model="form">
                    <FloatLabel class="mt-8">
                        <label for="name">Name</label>
                        <InputText v-model="form.name" id="name" class="w-full max-w-xs" />
                    </FloatLabel>

                    <FloatLabel class="mt-8">
                        <label for="login">GitHub username</label>
                        <InputText v-model="form.login" id="login" class="w-full max-w-xs" />
                    </FloatLabel>

                    <FloatLabel class="mt-8">
                        <label for="email">Email</label>
                        <InputText v-model="form.email" id="email" class="w-full max-w-xs" />
                    </FloatLabel>
                </form>
                <template #footer>
                        <Button class="w-full" @click="addUser" type="primary">Save</Button>
                </template>
            </Dialog>
    <Toast />

</template>

<script>

// for every foruth element
import { ref, computed } from 'vue'

import { async } from '@firebase/util'


var parseJwt = function (token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}



export default {
    data: () => ({
        users: null,
        form: {
            name: '',
            login: '',
            email: ''
        }
    }),

    setup() {
        const dialogFormVisible = ref(false)
        const search = ref('')
        const filterTableData = computed(() =>
            tableData.filter(
                (data) =>
                    !search.value ||
                    data.name.toLowerCase().includes(search.value.toLowerCase())
            )
        )

        let currentUserId = computed(async() => {
            let userId = await this.getUserId()
            console.log("what is the user id", userId)
            return userId
        })

        const tableData = [
            {
                name: 'Tom',
                teams: ["yeah"],
                codebases: ["cool api", "rails"]
            }]

        const user = null
        const selectedIndex = null
        const items = [
            {
                label: 'Update',
                command: () => {

                }
            },
            {
                label: 'Delete',
                command: () => {

                }
            },
            {
                label: 'Vue Website',
                command: () => {
                    window.location.href = 'https://vuejs.org/';
                }
            },
            { label: 'Upload', to: '/fileupload' }
        ];

        return {
            tableData,
            dialogFormVisible,
            filterTableData,
            currentUserId,
            search,
            items,
            user,
            selectedIndex
        }
    },
    async mounted() {
        var idtoken = await this.$authInstance.getToken()

        await this.getUsers()
    },
    methods: {
        getItem() {
            try {

            } catch (e) {

            }
        },
        onItemClick(event, item) {
            this.$refs[item.id].toggle(event);
        },
        async addUser() {

            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var orgs = await this.$authInstance.getOrgs()
            var org = orgs[0]
            myHeaders.append("Authorization", `Bearer ${idtoken}`);
            myHeaders.append("Content-Type", "application/json");
            this.form.login = this.form.login.trim()
            var raw = JSON.stringify(this.form);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            await fetch(`${url}/users`, requestOptions)
            await this.getUsers()
            this.dialogFormVisible = false

        },
        async handleDelete(index, row) {
            console.log(row)
            console.log(row.id)
            let loggedInUser = await this.getUserId()
            if(row.id != loggedInUser) {
                await this.removeUser(row.id)
                await this.getUsers()
            } else {
                this.toastMessage("Cannot delete Yourself", "You cannot delete your own account")
            }

            // await this.removeUser(row.id)
            // await this.getUsers()
        },
        async handleEdit(index, row) {

        },
        toastMessage(title, detail) {
            this.$toast.add({ severity: 'danger', summary: title, detail: detail, life: 300000 });

        },
        setUser(index, user) {
            this.selectedIndex = index
            this.user = user
        },
        async getUsers() {
            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var baseUrl = await this.$authInstance.getBaseUrl()
            myHeaders.append("Authorization", `Bearer ${idtoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            var userRes = await fetch(`${baseUrl}/users`, requestOptions)
            var userJson = await userRes.json()

            this.users = userJson.data
        },
        async getUserId() {
            var idtoken = await this.$authInstance.getToken()
            let parsedToken = parseJwt(idtoken)
            console.log(parsedToken, parsedToken)
            return parsedToken.user_id
        },
        async removeUser(id) {

            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var baseUrl = await this.$authInstance.getBaseUrl()
            myHeaders.append("Authorization", `Bearer ${idtoken}`);

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };

            var userRes = await fetch(`${baseUrl}/users?model_id=${id}`, requestOptions)
        }
    }
}
</script>

<style>
.p-splitbutton-button {
    display: none !important;
}

.p-toast-message {
    background-color: #030711 !important;
    border-radius: 1.25rem;
    border: 0.5px solid rgba(162, 161, 165, 0.75);
}

.users-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2em;
    padding: 1em;
}

.users-container .p-datatable {
    width: 100% !important;
}

.p-dialog-mask {
    /* -webkit-filter: blur(3px);
  filter: blur(3px); */
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(5px);

}

</style>