import { create, insert, search } from "@lyrasearch/lyra";


var sampleCollection = [{
  title: "The prestige",
  director: "Christopher Nolan",
  plot:
    "Two friends and fellow magicians become bitter enemies after a sudden tragedy. As they devote themselves to this rivalry, they make sacrifices that bring them fame but with terrible consequences.",
  year: 2006,
  isFavorite: true,
},
{
  title: "Big Fish",
  director: "Tim Burton",
  plot:
    "Will Bloom returns home to care for his dying father, who had a penchant for telling unbelievable stories. After he passes away, Will tries to find out if his tales were really true.",
  year: 2004,
  isFavorite: true,
},
{
  title: "Harry Potter and the Philosopher's Stone",
  director: "Chris Columbus",
  plot:
    "Harry Potter, an eleven-year-old orphan, discovers that he is a wizard and is invited to study at Hogwarts. Even as he escapes a dreary life and enters a world of magic, he finds trouble awaiting him.",
  year: 2001,
  isFavorite: false,
}]




var lyra = function () {
    this.db = null
    this.initSearchDb = async function() {
        const newDB = await create({
            schema: {
              commit: "string",
              doctype: "string",
              user: "string",
              branch: "string",
              team: "string",
              team: "string",
              contentString: "string",
              content: "object",
              repo: "string"
            },
          });
        return newDB
    },
    this.seedData = async function(dataCollection) {
        if(!this.db) return null
        for(const data of dataCollection) {
            if(data?.content) data.contentString = JSON.stringify(data.content)
            await insert(this.db, data)
        }
        return
    },
    this.search = async function(term) {
        if(!this.db) return
        return search(this.db, {term: term, properties: "*"})
    },
    this.sampleCollection = sampleCollection
}

export default lyra