<template>
    <div class="api-key-container">
        <Card class="mt-10" style="width: 25rem !important;">
            <template #header>
                <h3>Dev-Docs API Key</h3>
            </template>
            <template #subtitle>
                <span v-if="newApiKey">Copy this API key value! We will not show it again</span>
                <span v-else>You have one API Key in use</span>
            </template>
            <template #content>
                <Textarea class="w-full" v-if="newApiKey" v-model="newApiKey" rows="5" cols="30" />
                <Textarea class="w-full" v-else-if="ApiKeys?.length > 0"
                    style="color: transparent;text-shadow: 0 0 8px white" v-model="notAPIKey" rows="5" cols="30" />
            </template>
            <template #footer>
                <Button class="w-full" v-if="ApiKeys" @click="addApiKey()">Replace and create new API Key</Button>
                <Button class="w-full" v-else @click="addApiKey()">Add API Key</Button>
            </template>
        </Card>
    </div>
</template>

<script>

// for every foruth element






export default {
    data: () => ({
        ApiKeys: [],
        newApiKey: null,
        notAPIKey: "This is not your API Key, you have to generate a new one :D"
    }),
    async mounted() {


        await this.getApiKeys()
    },
    methods: {
        getItem() {
            try {

            } catch (e) {

            }
        },
        async addApiKey() {

            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var orgs = await this.$authInstance.getOrgs()
            var userinfo = await this.$authInstance.userinfo()
            myHeaders.append("Authorization", `Bearer ${idtoken}`);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify(userinfo);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var apiKeyResponse = await fetch(`${url}/api_keys`, requestOptions)
            var apiKeyJson = await apiKeyResponse.json()

            this.newApiKey = apiKeyJson?.message?.apikey
        },
        async handleDelete(index, row) {

            await this.removeApiKey(row.id)
        },
        async handleEdit(index, row) {

        },
        async getApiKeys() {
            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var baseUrl = await this.$authInstance.getBaseUrl()
            myHeaders.append("Authorization", `Bearer ${idtoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            var ApiKeyRes = await fetch(`${baseUrl}/api_keys`, requestOptions)
            var ApiKeyJson = await ApiKeyRes.json()
            console.log(ApiKeyJson)
            this.ApiKeys = ApiKeyJson
        },
        async removeApiKey(id) {
            var myHeaders = new Headers();
            var idtoken = await this.$authInstance.getToken()
            var baseUrl = await this.$authInstance.getBaseUrl()
            myHeaders.append("Authorization", `Bearer ${idtoken}`);

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };

            var ApiKeyRes = await fetch(`${baseUrl}/ApiKeys?model_id=${id}`, requestOptions)
        }
    }
}
</script>

<style lang="scss">
.api-key-container {
    .p-card-body {
        padding: 0px !important;
    }

    .p-card {
        /* padding-left: 1rem !important;
    padding-right: 1rem !important; */
        padding: 1.25rem !important
    }
}
</style>
