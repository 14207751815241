<template>
    <el-drawer v-model="addElementDialog" direction="rtl" title="Add Custom CSS" style="color: white">

        <el-card>
            <el-row>
                <el-col :span="24">
                    <el-card>
                        <style-editor :collected="collected"  @style="setStyle" />
                    </el-card>
                    <el-card>
                    </el-card>
                    <el-card>
                        <h3>Add Classes</h3>
                        <div class="card-actions justify-end pt-4">
                            <el-button @click="addClass">Add Class</el-button>
                        </div>
                        <el-form ref="classesForm" :model="classesForm" label-width="120px" class="demo-dynamic">
                            <el-form-item v-for="(classItem, index) in classesForm.classes" :key="classItem.key"
                                :label="'Class ' + index">
                                <el-input v-model="classItem.value" />
                                <el-button class="mt-2" @click.prevent="removeClass(classItem)">Delete</el-button>
                            </el-form-item>
                        </el-form>

                    </el-card>
                </el-col>
            </el-row>
        </el-card>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="addElementDialog = false">Close</el-button>
                <el-button type="primary" @click="addElement">submit</el-button>
            </span>
        </template>
    </el-drawer>
</template>

<script>
import StyleEditor from './CardStyleEditor.vue'
import AiBlock from './aiReuseableBlock.vue'
export default {
    components: {
        StyleEditor
    },
    data: () => ({

        addElementDialog: false,
        gradientColors: [],
        gradientAngle: 30.17,
        finalGradientColor: "",
        textColor: "white",
        finalGradientToSend: null,
        finalColorToSend: null,
        activeName: "Color",
        singleColor: '#ff4500',
        glassMorph: false,
        heightValidateForm: {
            height: '',
            format: ''
        },
        classesForm: {
            classes: []
        },
        graidentAngleOptions: [],
        gradient: false,
        colorPickers: 0,
        render: true,
        ageValue: '',
        imageUrl: null,
        elementType: "",
        elementOptions: ["h1", "h2", "h3", "h4", "h5", "h6", "p", "a"],
        style: "",
        formRef: null,
        token: null,
        apiUrl: null,
        predefineColors: [
            '#ff4500',
            '#ff8c00',
            '#ffd700',
            '#90ee90',
            '#00ced1',
            '#1e90ff',
            '#c71585',
            'rgba(255, 69, 0, 0.68)',
            'rgb(255, 120, 0)',
            'hsv(51, 100, 98)',
            'hsva(120, 40, 94, 0.5)',
            'hsl(181, 100%, 37%)',
            'hsla(209, 100%, 56%, 0.73)',
            '#c7158577',
        ]
    }),
    props: {
        show: Boolean, collected: Array
    },
    watch: {
        colorPickers(val) {
            if (this.gradientColors.length >= val) this.colorPickers.splice(val, 1)
            else {
                this.gradientColors.push({ color: '#ff4500' })
            }
            return
        }, singleColor(val) {
            
        },
        glassMorph(val) {
            this.$emit("glassmorph", val)
        }
    },
    async mounted() {
        this.addElementDialog = this.show
        this.formRef = this.$refs.formRef;
        var baseUrl = await this.$authInstance.getBaseUrl()
        this.token = await this.$authInstance.getToken()
        this.apiUrl = `${baseUrl}/external_images`
    },
    unmounted() {
        this.addElementDialog = false
    },
    computed: {
        finalGradientColor() {
            const gradientColors = this.gradientColors.map(function (item) { return item.color })
            const gradientStrings = gradientColors.join(", ")
            
            this.finalGradientToSend = `linear-gradient(${this.gradientAngle}deg, ${gradientStrings})`
            return `linear-gradient(${this.gradientAngle}deg, ${gradientStrings})`
        },
        finalSingleColor() {

            this.finalColorToSend = this.singleColor
            return this.singleColor
        }
    },
    methods: {
        resetForm(formEl) {
            if (!formEl) return;
            formEl.resetFields();
        },
        addClass() {
            this.classesForm.classes.push({value: "your-new-css-class"})
        },
        removeClass(item) {
            const index = this.classesForm.classes.indexOf(item)
            if (index !== -1) {
                this.classesForm.classes.splice(index, 1)
            }
        },
        setStyle(val) {
            
            
            this.style = val
            const classes = this.classesForm.classes.map(function(item){ return item.value})
            const elementObject = {style: this.style, classes: classes.join(" "), elementType: this.elementType}
            
            this.$emit("createelement", elementObject)

        },
        addElement() {
            const classes = this.classesForm.classes.map(function(item){ return item.value})
            const elementObject = {style: this.style, classes: classes.join(" "), elementType: this.elementType}
            
            this.$emit("createelement", elementObject)
        }
    }
}
</script>

<style lang="scss">
.color-dialog {
    color: white !important;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: white !important;
    }
}

.preview {
    min-width: 400px;
    min-age: 400px;
}


</style>