<template>
    <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px; background-color: #2C2738c !important;">
        <el-radio-button :label="false">expand</el-radio-button>
        <el-radio-button :label="true">collapse</el-radio-button>
    </el-radio-group> -->
    <el-menu background-color="red" text-color="black" default-active="2" class="el-menu-vertical-demo"
        style="margin-bottom: 20px; border-style: none; !important; padding-top: 4em;" :collapse="isCollapse"
        @open="handleOpen" @close="handleClose">
        <el-menu-item @click="backHome()" style="padding-top: 1em; margin-bottom: 5em;" index="0">
            <img src="https://cdn.glitch.global/d5d82a90-afa2-4154-85a6-c9fd552cb218/dev-docs%20logo.png" />
        </el-menu-item>
        <el-menu-item class="section-menu-item" index="1">
            <template #title>Purpose</template>
        </el-menu-item>
        <el-menu-item class="section-menu-item" index="2">
            <template #title>Context Docs</template>
        </el-menu-item>
        <el-menu-item class="section-menu-item" index="3">
            <template #title>Reference Docs</template>
        </el-menu-item>
        <el-menu-item class="section-menu-item" index="4">
            <template #title>How to Guides</template>
        </el-menu-item>
        <el-menu-item class="section-menu-item" index="5">
            <template #title>Tutorials</template>
        </el-menu-item>
    </el-menu>
</template>

<script>

import { ref } from 'vue'
import {
    Document,
    Menu as IconMenu,
    Location,
    Setting,
} from '@element-plus/icons-vue'

export default {
    components: {
        Document, IconMenu, Location, Setting
    },
    methods: {
        backHome() {
            this.$emit("backhome")
        }
    },
    setup() {
        const isCollapse = ref(false)
        const handleOpen = (key, keyPath) => {
            
        }
        const handleClose = (key, keyPath) => {
            
        }
        return {
            isCollapse, handleOpen, handleClose
        }

    }
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.section-menu-item {
    font-family: "Inter", sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 22px !important;
}

.el-menu-item:hover {
    background-color: rgba(0,0,0,0.001) !important;
    background: rgba(0,0,0,0.001) !important;
}
</style>
