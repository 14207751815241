<template>
    <style-editor :css="attrs.style" @style="setStyle" />
</template>

<script>

import StyleEditor from './CardStyleEditor.vue'
export default {
    props: {
        attrs: Object
    },
    components: {
        StyleEditor
    },
    methods: {
        setStyle(val) {
            this.$emit("style", val)
        }
    }
}
</script>

